import { forwardRef, FunctionComponent, HTMLProps, useState } from "react"
import { Badge, Button, Dropdown } from "react-bootstrap"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import useOnclickOutside from "react-cool-onclickoutside"
import HierarchicalSelect, {
  HierarchicalSelectProps,
  Option,
} from "../../components/HierarchicalSelect/HierarchicalSelect"
import Icon from "../../components/Icon/Icon"
import { LabeledValue } from "../../types/utils"

export type Tag = LabeledValue<string>
export type Tags = Tag[]

interface GedTagsProps {
  hierarchicalSelect?: HierarchicalSelectProps
  tags?: Tags
  onChanged?: (newTags: Tags) => void
  canEdit?: boolean
}

type ButtonProps = HTMLProps<HTMLButtonElement>

const CustomToggle = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick }, ref) => (
    <Button
      className="toggle-button"
      variant={"link"}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick?.(e)
      }}
    >
      <Icon icon="add_circle_outline" />
    </Button>
  )
)

const GedTags: FunctionComponent<GedTagsProps> = ({
  tags,
  hierarchicalSelect,
  onChanged,
  canEdit,
}) => {
  const [values, setValues] = useState<Tags>(tags || [])
  const [selection, setSelection] = useState<Option>()
  const [showMenu, setShowMenu] = useState(false)
  const onAddClicked = () => {
    if (selection) {
      const newTags = [...values, selection]
      setValues(newTags)
      onChanged?.(newTags)
      setShowMenu(false)
    }
  }

  const onRemoveClicked = (tagToRemove: Tag) => {
    const newTags = values.filter((v) => v != tagToRemove)
    setValues(newTags)
    onChanged?.(newTags)
  }
  const ref = useOnclickOutside(() => {
    setShowMenu(false)
  })

  const getBadgeTag = () => {
    const getDeleteButton = (t: any) => {
      if (canEdit) {
        return (
          <span
            className="remove-tag-button"
            onClick={() => onRemoveClicked(t)}
          >
            <Icon icon="close" />
          </span>
        )
      }
      return null
    }

    return values.map((t, key) => (
      <Badge pill className="me-1" bg="primary" key={key} title={t.value}>
        {t.label}
        {getDeleteButton(t)}
      </Badge>
    ))
  }

  return (
    <div className="ged-tags">
      <div className="ged-tags-list">{getBadgeTag()}</div>
      {hierarchicalSelect && canEdit ? (
        <Dropdown className="ged-tags-add-button" show={showMenu} align={"end"}>
          <Dropdown.Toggle
            variant="link"
            as={CustomToggle}
            onClick={() => setShowMenu(!showMenu)}
            id="dropdown-custom-components"
          />
          <DropdownMenu ref={ref}>
            <div className="ged-tags-menu">
              <HierarchicalSelect
                {...hierarchicalSelect}
                onValueChanged={setSelection}
              />
              <Button className="ms-auto" onClick={onAddClicked}>
                Ajouter
              </Button>
            </div>
          </DropdownMenu>
        </Dropdown>
      ) : null}
    </div>
  )
}

export default GedTags

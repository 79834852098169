import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { Folder } from "../../utils/api"
import { DOWNLOAD_FOLDER_ACTION } from "./commons"

export interface GedDownloadProps {
  projectId: string
  folder?: Folder
  handleDialogOpen: (action: string) => void
}

export const GedDownload: FunctionComponent<GedDownloadProps> = ({
  projectId,
  folder,
  handleDialogOpen,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  if (!projectId) return null

  const strings = {
    download: "Télécharger",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
  }

  const on_download = () => {
    folder = folder as Folder
    handleDialogOpen(DOWNLOAD_FOLDER_ACTION)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ m: "0.3em" }}>
        <Tooltip
          disableHoverListener={folder?.permissions?.canDownload}
          title={
            folder?.permissions?.canDownload ? false : strings.unauthorized
          }
          arrow
          placement="bottom"
        >
          <span>
            <Button
              startIcon={<CloudDownloadIcon />}
              ref={anchorRef}
              variant="outlined"
              id="composition-button"
              aria-haspopup="true"
              onClick={on_download}
              disabled={!folder?.permissions?.canDownload}
            >
              {strings.download}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}

import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useEffect, useState } from "react"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { Report } from "../../types/report"
import {
  getGedAPI,
  getMainAPI,
  GetProjectUsersResponseUser,
  Permission,
  Resource,
} from "../../utils/api"
import { PermissionRow } from "../PermissionRow/PermisisonRow"
import { dialogTitleStyle, getDialogError, getDialogLoader } from "./commons"

export interface DialogBoxPermissionsProp {
  projectId: string
  currentUser: CognitoUserExt
  resource: Resource | Report
  handleClose: () => void
  isReports?: boolean
}

export const DialogBoxPermissions: FunctionComponent<
  DialogBoxPermissionsProp
> = ({ projectId, currentUser, resource, handleClose, isReports = false }) => {
  const [projectUsers, setProjectUsers] =
    useState<GetProjectUsersResponseUser[]>()
  const [resourcePermissions, setResourcePermissions] = useState<Permission[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState(true)
  const [error, setError] = useState(false)
  const styles = {
    size: { minWidth: "40em" },
    largeSize: { width: "47em" },
    dialogTitle: dialogTitleStyle,
  }

  const strings = {
    dialogTitle: (name: string) => {
      return "Permissions : " + name
    },
    mail: "Email",
    role: "Role",
    owner: "Owner",
    editor: "Editor",
    viewer: "Viewer",
    buttonCancel: "OK",
  }

  const isPrincipalPresent = (
    permissions: Permission[],
    user: GetProjectUsersResponseUser
  ): boolean => {
    let b: boolean = false
    permissions.forEach((perm) => {
      if (perm.principal.id == user.email) {
        b = true
      }
    })
    return b
  }

  const isUserMember = (
    users: GetProjectUsersResponseUser[],
    email?: string
  ) => {
    let b: boolean = false
    users.forEach((u) => {
      if (u.email == email) {
        b = true
      }
    })
    return b
  }

  useEffect(() => {
    if (isNeedUpdate) {
      setIsLoading(true)
      setError(false)
      getMainAPI()
        .getProjectUsers(projectId)
        .then((response) => {
          if (isReports) {
            getMainAPI()
              .getReportPermissions(projectId, resource.id as string)
              .then((permissions) => {
                const resPerm = permissions.filter(
                  (permission) =>
                    permission.principal.id != currentUser.attributes?.email &&
                    isUserMember(response.users, permission.principal.id)
                )
                setResourcePermissions(resPerm)
                setProjectUsers(
                  response.users.filter(
                    (user) =>
                      user.id != currentUser.getUsername() &&
                      !isPrincipalPresent(resPerm, user)
                  )
                )
                setIsLoading(false)
                setIsNeedUpdate(false)
              })
              .catch(() => {
                setIsLoading(false)
                setError(true)
              })
          } else {
            getGedAPI()
              .getGedResourcePermissions(projectId, resource?.id as string)
              .then((permissions) => {
                const resPerm = permissions.filter(
                  (permission) =>
                    permission.principal.id != currentUser.attributes?.email &&
                    isUserMember(response.users, permission.principal.id)
                )
                setResourcePermissions(resPerm)
                setProjectUsers(
                  response.users.filter(
                    (user) =>
                      user.id != currentUser.getUsername() &&
                      !isPrincipalPresent(resPerm, user)
                  )
                )
                setIsLoading(false)
                setIsNeedUpdate(false)
              })
              .catch(() => {
                setIsLoading(false)
                setError(true)
              })
          }
        })
        .catch(() => {
          setIsLoading(false)
          setError(true)
        })
    }
  }, [projectId, resource, isNeedUpdate, currentUser, isReports])

  const getPermissionsRow = () => {
    if (!resourcePermissions?.length) return null
    return (
      <DialogContent dividers sx={{ height: "35em" }}>
        {isLoading
          ? getDialogLoader()
          : resourcePermissions?.map((permission) => {
              return (
                <PermissionRow
                  id={permission.id}
                  key={permission.id}
                  resource={resource}
                  projectId={projectId}
                  permission={permission}
                  defaultUser={permission.principal}
                  defaultRole={permission.role}
                  disableMail
                  saveButton
                  deleteButton
                  setIsNeedUpdate={setIsNeedUpdate}
                />
              )
            })}
      </DialogContent>
    )
  }

  const getDialogBox = () => {
    const title = resource.name ? resource.name : (resource.title as string)
    return (
      <div style={styles.largeSize}>
        <DialogTitle sx={styles.dialogTitle}>
          {strings.dialogTitle(title)}
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent dividers>
            {isLoading ? (
              getDialogLoader()
            ) : (
              <PermissionRow
                projectId={projectId}
                users={projectUsers}
                addButton
                resource={resource}
                setIsNeedUpdate={setIsNeedUpdate}
              />
            )}
          </DialogContent>
          {getPermissionsRow()}
          <DialogActions>
            <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          </DialogActions>
        </List>
      </div>
    )
  }

  if (error) return getDialogError(handleClose)

  return getDialogBox()
}

import { Box } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react"
import { useParams } from "react-router-dom"
import Error from "../../components/Error/Error"
import Loader from "../../components/Loader/Loader"
import { ReportToolbar } from "../../components/ReportOptions/ReportToolbar"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { Report } from "../../types/report"
import { getMainAPI, Hierarchy, Project } from "../../utils/api"
import ReportConsultationView from "../ReportConsultationView/ReportConsultationView"
import ReportEditionView from "../ReportEditionView/ReportEditionView"

interface ReportConsultationViewParams {
  reportId: string
}

export interface ReportConsultationIndiceProps {
  project: Project
  hierarchy?: Hierarchy
  user: CognitoUserExt
}

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const ReportConsultationIndice: FunctionComponent<
  ReportConsultationIndiceProps
> = ({ project, hierarchy, user }) => {
  const { reportId } = useParams<ReportConsultationViewParams>()
  const [reports, setReports] = useState<Report[]>()
  const [isError, setIsError] = useState<boolean>(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const [dialogAction, setDialogAction] = useState<string | undefined>(
    undefined
  )

  const styles = {
    title: { display: "flex", justifyContent: "center" },
  }

  const strings = {
    title: (reports: Report[]) => {
      const mode = reports[selectedTab].isSubmitted ? "Consultation" : "Edition"
      return `${mode} : ${reports[selectedTab].title} - Indice ${reports[selectedTab].indice}`
    },
  }

  const setDialogActionState = (
    action: string | undefined,
    needUpdate: boolean | undefined
  ) => {
    setDialogAction(action)
    if (needUpdate) setIsNeedUpdate(needUpdate as boolean)
  }

  useEffect(() => {
    if (isNeedUpdate) {
      getMainAPI()
        .getReportIndices(project.id, reportId)
        .then((reports) => {
          reports.reports.forEach((rep) => {
            rep.indicesReports = reports.reports
          })
          if (
            reports.reports[0].role != "editor" &&
            reports.reports[0].role != "owner"
          ) {
            reports.reports = reports.reports.filter((rep) => rep.isSubmitted)
          }
          setSelectedTab(0)
          setReports(reports.reports)
          setSelectedTab(reports.reports.length - 1)
          setIsNeedUpdate(false)
        })
        .catch((err) => {
          console.error(err)
          setIsError(true)
        })
    }
  }, [project.id, reportId, isNeedUpdate])

  if (!reports) {
    return <Loader fullscreen />
  }

  if (isError) return <Error />

  let counterTabs = -1
  let counterPanel = -1

  const isVisible = (currentIndex: number): boolean => {
    if (selectedTab == currentIndex && !isNeedUpdate) return true
    return false
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.title}>
        <h3>{strings.title(reports)}</h3>
      </Box>
      <ReportToolbar
        project={project}
        user={user}
        indices={reports}
        report={
          reports.length > selectedTab
            ? reports[selectedTab]
            : reports[selectedTab - 1]
        }
        dialogAction={dialogAction}
        setDialogActionState={setDialogActionState}
      />
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {reports.map((rep) => {
          counterTabs++
          return (
            <Tab
              key={`tab-${rep.id}-${rep.indice}`}
              label={`${rep.indice}`}
              {...a11yProps(counterTabs)}
            />
          )
        })}
      </Tabs>

      {reports.map((rep) => {
        counterPanel++
        if (rep.isSubmitted) {
          return (
            <TabPanel
              key={`panel-${rep.id}-${rep.indice}`}
              value={selectedTab}
              index={counterPanel}
            >
              {/* <Box
                sx={{ maxHeight: `${(height / 100) * 80}px`, overflow: "auto" }}
              > */}
              <ReportConsultationView
                visible={isVisible(counterPanel)}
                key={`view-${rep.id}-${rep.indice}`}
                project={project}
                report={rep}
              />
              {/* </Box> */}
            </TabPanel>
          )
        }
        return (
          <TabPanel
            key={`panel-${rep.id}-${rep.indice}`}
            value={selectedTab}
            index={counterPanel}
          >
            {/* <Box
              sx={{ maxHeight: `${(height / 100) * 80}px`, overflow: "auto", marginLeft: '0' }}
            > */}
            <ReportEditionView
              hierarchy={hierarchy}
              visible={isVisible(counterPanel)}
              key={`edit-${rep.id}-${rep.indice}`}
              project={project}
              report={rep}
              setIsNeedUpdate={setIsNeedUpdate}
            />
            {/* </Box> */}
          </TabPanel>
        )
      })}
    </Box>
  )
}

import { forwardRef, FunctionComponent, useEffect, useState } from "react"
import { Badge, Button, ButtonProps, Dropdown } from "react-bootstrap"
import { usePageVisibility } from "react-page-visibility"
import { Event, getMainAPI } from "../../utils/api"
import Icon from "../Icon/Icon"

const CustomToggle = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick }, ref) => (
    <Button
      className="toggle-button"
      variant={"link"}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick?.(e)
      }}
    >
      <Icon icon="inbox" />
    </Button>
  )
)

const TopbarInbox: FunctionComponent = () => {
  const [ongoingDownload, setOngoingDownload] = useState<Event[]>()
  const [doneDownload, setDoneDownload] = useState<Event[]>()
  const visibility = usePageVisibility()
  const [time, setTime] = useState(Date.now())
  const delay = 10000

  const strings = {
    title: "Inbox",
    plannedReports: (length: number) => {
      return length > 1 ? "Rapports planifiés:" : "Rapport planifié:"
    },
    noMessage: "Vous n'avez aucun rapport planifié.",
    ongoingDownloadTitle: (length: number) => {
      return length >= 1
        ? "Vos téléchargements en cours de préparation:"
        : "Pas de téléchargements en cours."
    },
    doneDownloadTitle: (length: number) => {
      return length >= 1
        ? "Liens de téléchargement:"
        : "Pas de lien de téléchargement disponible."
    },
  }

  useEffect(() => {
    if (!visibility) {
      return
    }
    // Update the state every $delay ms, to fetch new reports
    let interval = setInterval(() => setTime(Date.now()), delay)
    // DEPRECATED : REPORTS ARE NOT BOUNDED TO USER ASSIGNED ANYMORE
    // getMainAPI()
    //   .getMyReports()
    //   .then((response) => setReports(response.reports))

    getMainAPI()
      .getEvents()
      .then((response) => {
        const downloads = response.events.filter(
          (notif) => notif.name == "export_folder"
        )
        setOngoingDownload(downloads.filter((dl) => !dl.payload.done))
        let downloadToClean = downloads.filter(
          (dl) =>
            dl.payload.done &&
            dl.payload.response &&
            new Date(dl.payload.response.expiresAt).getTime() < Date.now()
        )
        const downloadsReady = downloads.filter(
          (dl) =>
            dl.payload.done &&
            dl.payload.response &&
            new Date(dl.payload.response.expiresAt).getTime() > Date.now()
        )
        setDoneDownload(downloadsReady)
        // Clear download in backend if expired
        downloadToClean.forEach((dl) => {
          getMainAPI().closeEvent(dl.id)
        })
      })
      .catch((e) => {
        console.error(e)
      })

    return () => {
      clearInterval(interval)
    }
  }, [visibility, time])
  let length = 0
  if (doneDownload) length += doneDownload.length

  return (
    <Dropdown align="end" className="inbox">
      <Dropdown.Toggle as={CustomToggle} variant="link" id="dropdown-basic">
        {strings.title}
      </Dropdown.Toggle>

      {length ? (
        <Badge pill bg="danger">
          {length}
        </Badge>
      ) : null}

      <Dropdown.Menu>
        {/* DEPRECATED : REPORTS ARE NOT BOUNDED TO USER ASSIGNED ANYMORE
        {length ? (
          <Dropdown.Header>{strings.plannedReports(length)}</Dropdown.Header>
        ) : (
          <Dropdown.Header>{strings.noMessage}</Dropdown.Header>
        )}
        {reports.map((report, key) => {
          return (
            <LinkContainer
              key={key}
              to={`/project/${report.projectId}/report/${report.id}/edit`}
            >
              <Dropdown.Item>{`${report.projectId} - ${report.title}`}</Dropdown.Item>
            </LinkContainer>
          )
        })}
        <hr /> */}
        <Dropdown.Header>
          {strings.ongoingDownloadTitle(
            ongoingDownload ? ongoingDownload.length : 0
          )}
        </Dropdown.Header>
        {ongoingDownload?.map((ongoing) => {
          return (
            <div key={"div-ongoing-" + ongoing.id}>
              <Dropdown.Item key={ongoing.id}>
                ({ongoing.payload.metadata.projectId}) :{" "}
                {ongoing.payload.metadata.resourceId}
              </Dropdown.Item>
            </div>
          )
        })}
        <hr />
        <Dropdown.Header>
          {strings.doneDownloadTitle(doneDownload ? doneDownload.length : 0)}
        </Dropdown.Header>
        {doneDownload?.map((doneDl) => {
          return (
            <div key={"div-done-" + doneDl.id}>
              <Dropdown.Item
                target={"_blank"}
                href={doneDl.payload.response.url}
                key={doneDl.id}
              >
                ({doneDl.payload.metadata.projectId}) :{" "}
                {doneDl.payload.metadata.resourceId}
              </Dropdown.Item>
            </div>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TopbarInbox

import {
  FormEventHandler,
  FunctionComponent,
  useEffect,
  useReducer,
  useState,
} from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Redirect, useParams } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import { getMainAPI, Hierarchy, Project, PutReportBody } from "../../utils/api"

import Form from "react-bootstrap/Form"
import { DraftReport } from "../../types/report"
import ReportEditionConclusion from "./ReportEditionConclusion"
import ReportEditionDropZone from "./ReportEditionDropZone"
import ReportEditionHeader from "./ReportEditionHeader"
import ReportEditionPartiesNonVisitees from "./ReportEditionPartiesNonVisitees"
import ReportEditionPartiesVisitees from "./ReportEditionPartiesVisitees"
import {
  reportReducer,
  ReportReducerDispatch,
} from "./ReportEditionReportReducer"
import {
  selectedReducer,
  SelectedSetsReducerDispatch,
} from "./ReportEditionSetReducers"
import "./ReportEditionView.scss"

interface ReportEditionViewProps {
  project: Project
  report: DraftReport
  visible: boolean
  setIsNeedUpdate?: (b: boolean) => void
  hierarchy?: Hierarchy
}

interface ReportEditionViewParams {
  reportId: string
}

const AUTO_SAVE_DELAY_MS = 5000
let autoSaveTimeoutId: ReturnType<typeof setTimeout> | undefined

const ReportEditionView: FunctionComponent<ReportEditionViewProps> = ({
  project,
  report,
  setIsNeedUpdate,
  hierarchy,
  visible,
}) => {
  const { reportId } = useParams<ReportEditionViewParams>()
  const [validated, setValidated] = useState(false)
  const [stateReport, dispatchStateReport] = useReducer(
    reportReducer,
    undefined
  )
  const [selectedSets, dispatchSelectedSets] = useReducer(selectedReducer, {
    so: new Set<string>(),
    composants: new Set<string>(),
    elements: new Set<string>(),
  })

  const updateReport = (newReport: DraftReport) => {
    dispatchStateReport({
      type: "replaceReport",
      data: newReport,
    })
  }

  const sendReport = (body: PutReportBody) => {
    getMainAPI()
      .putReport(project.id, reportId, body)
      .then((response) => {
        updateReport(response as DraftReport)
        if (setIsNeedUpdate) {
          setIsNeedUpdate(true)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const submitReport: FormEventHandler<HTMLFormElement> = (event) => {
    // AVOID Default SUBMIT form browser behaviors
    event.preventDefault()
    event.stopPropagation()

    if (!stateReport) {
      console.warn("[submitReport] report is invalid", {
        report: stateReport,
      })
      return
    }

    const form = event.currentTarget

    if (!form.checkValidity()) {
      setValidated(true)
      return
    }

    sendReport({
      form: stateReport.form,
      indice: stateReport.indice,
      type: stateReport.type,
      isSubmitted: true,
    })
  }

  useEffect(() => {
    updateReport(report)
  }, [report])

  useEffect(() => {
    if (!stateReport || !stateReport.isModified) {
      return
    }

    if (autoSaveTimeoutId) {
      clearTimeout(autoSaveTimeoutId)
    }

    autoSaveTimeoutId = setTimeout(() => {
      const body = {
        form: stateReport.form,
        indice: stateReport.indice,
        type: stateReport.type,
        isSubmitted: false,
      }
      getMainAPI()
        .putReport(project.id, reportId, body)
        .catch((error) => {
          console.error(error)
        })
    }, AUTO_SAVE_DELAY_MS)

    reportReducer(stateReport, {
      type: "replaceIsModified",
      data: false,
    })
  }, [project.id, stateReport, reportId])

  if (!stateReport || !visible) {
    return <Loader fullscreen />
  }

  if (stateReport.isSubmitted) {
    return (
      <Redirect push to={`/project/${project.id}/report/${stateReport.id}`} />
    )
  }

  const headerPrefix = `${project.name} - ${stateReport.title} - Édition formulaire`

  document.title = headerPrefix + " - StructuralTwin"

  const mustDisabledButton = (): boolean => {
    if (
      stateReport.form.startDate &&
      stateReport.form.endDate &&
      stateReport.form.startDate > stateReport.form.endDate
    )
      return true
    if (
      !stateReport.form.noteIQOA &&
      (stateReport.type == 2 || stateReport.type == 3 || stateReport.type == 4)
    )
      return true
    else if (
      !stateReport.form.partiesVisitees &&
      !stateReport.form.partiesNonVisitees
    )
      return true
    if (!stateReport.form.partiesVisitees) return true
    let nbElements = 0
    stateReport.form.partiesVisitees.forEach((sousOuvrage) => {
      sousOuvrage.composants.forEach((composant) => {
        nbElements += composant.elements.length
      })
    })
    if (nbElements == 0) return true
    return false
  }

  return (
    <>
      {/* <div className={"report-edition-form-header"}>
        <h5 className="mb-0">{headerPrefix}</h5>
      </div> */}
      <div className={"report-edition-form"}>
        <ReportReducerDispatch.Provider
          value={{ report: stateReport, dispatchReport: dispatchStateReport }}
        >
          <SelectedSetsReducerDispatch.Provider
            value={{ selectedSets, dispatchSelectedSets }}
          >
            <Form noValidate validated={validated} onSubmit={submitReport}>
              <ReportEditionHeader project={project} />
              <ReportEditionDropZone project={project} />
              <ReportEditionPartiesNonVisitees hierarchy={hierarchy} />
              <ReportEditionPartiesVisitees
                project={project}
                hierarchy={hierarchy}
              />
              <ReportEditionConclusion />

              <Container className={"footer-buttons"}>
                <Row>
                  <Col xs={{ span: 2, offset: 10 }}>
                    <Button
                      disabled={mustDisabledButton()}
                      variant={"primary"}
                      className={"right-button"}
                      type={"submit"}
                    >
                      Enregistrer et Soumettre
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </SelectedSetsReducerDispatch.Provider>
        </ReportReducerDispatch.Provider>
      </div>
    </>
  )
}

export default ReportEditionView

import { FunctionComponent, useContext } from "react"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import { CustomToggle } from "../ReportEditionView/ReportEditionDropZone"
import { ReportContext } from "./ReportConsultationView"

interface ReportConsultationConclusionProps {}

const ReportConsultationConclusion: FunctionComponent<
  ReportConsultationConclusionProps
> = () => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            Conclusion
            {report.type <= 4 ? (
              <BadgeIQOA note={report.form.noteIQOA} />
            ) : null}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Container fluid>
              <Row>
                <Col>
                  {report.form.commentaireNoteIQOA ? (
                    <div className={"text-user"}>
                      {report.form.commentaireNoteIQOA}
                    </div>
                  ) : (
                    <i> Aucune donnée renseignée.</i>
                  )}
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export default ReportConsultationConclusion

import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import TextField from "@mui/material/TextField"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { Folder, getGedAPI, Resource } from "../../utils/api"
import {
  dialogTitleStyle,
  getCodeText,
  getDialogError,
  getDialogLoader,
} from "./commons"

export interface DialogBoxDeleteProps {
  projectId: string
  kind: string
  folder: Folder
  resource: Resource
  handleClose: () => void
  setFile?: (resource: Resource) => void
}

export const DialogBoxDelete: FunctionComponent<DialogBoxDeleteProps> = ({
  projectId,
  kind,
  folder,
  resource,
  handleClose,
  setFile,
}) => {
  const [input, setInput] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    warningBox: {
      backgroundColor: "#fcf1ef",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
    dialogContextText: { marginLeft: "0.5rem", fontWeight: "bold" },
    dialogContextTextWarning: {
      fontWeight: "bold",
      marginLeft: "0.2rem",
      marginTop: "0.5rem",
    },
  }

  const strings = {
    dialogTitle: "Etes-vous vraiment sûr ?",
    buttonCancel: "Annuler",
    buttonDelete: "Supprimer",
    content:
      "Vous êtes sur le point de supprimer le " +
      (kind == "folder" ? "dossier" : "fichier"),
    helperText: "Vous devez écrire le nom de la ressource à l'identique.",
    undoWarning: "Cette action est irréversible.",
    textLabel: "Ecrivez pour confirmer",
  }

  const isActionValid = (): boolean => {
    if (input == resource.name.replace(/\s\s+/g, " ")) return true
    return false
  }

  const onTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(event.target.value as string)
  }

  const getNoUndoWarning = () => {
    return (
      <DialogContentText sx={styles.dialogContextTextWarning}>
        <WarningAmberIcon />
        {strings.undoWarning}
      </DialogContentText>
    )
  }

  const getTextLabel = () => {
    return (
      <p>
        {strings.textLabel}: {getCodeText(resource.name.replace(/\s\s+/g, " "))}
      </p>
    )
  }

  const onDelete = () => {
    const errorAction = () => {
      setIsLoading(false)
      setError(true)
    }

    const successAction = () => {
      if (setFile) {
        getGedAPI()
          .getFiles(projectId, folder.path, true)
          .then((file) => {
            setFile(file)
            setIsLoading(false)
            handleClose()
          })
          .catch(() => {
            errorAction()
          })
      }
    }

    setIsLoading(true)
    setError(false)
    getGedAPI()
      .deleteResource(projectId, resource)
      .then(() => {
        return successAction()
      })
      .catch(() => {
        errorAction()
      })
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <Box sx={styles.warningBox}>
          <DialogContentText sx={styles.dialogContextText}>
            {strings.content}:{" "}
            {getCodeText(resource.name.replace(/\s\s+/g, " "))}
          </DialogContentText>
          {getNoUndoWarning()}
        </Box>
        <DialogContent sx={styles.dialogContent}>
          <TextField
            error={input.length > 0 ? !isActionValid() : false}
            autoFocus
            margin="dense"
            id="name"
            label={getTextLabel()}
            type="text"
            fullWidth
            variant="standard"
            onChange={onTextChange}
            helperText={isActionValid() ? "" : strings.helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button
            disabled={!isActionValid()}
            color={"error"}
            onClick={onDelete}
          >
            {strings.buttonDelete}
          </Button>
        </DialogActions>
      </List>
    </div>
  )
}

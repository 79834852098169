import { useAuthenticator } from "@aws-amplify/ui-react"
import { FunctionComponent, ReactNode } from "react"
import Topbar from "../../components/Topbar/Topbar"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import "./Layout.scss"

export interface LayoutProps {
  children: ReactNode
  signOut: ReturnType<typeof useAuthenticator>["signOut"]
  user: CognitoUserExt
}

const Layout = ({ children, signOut, user }: LayoutProps) => (
  <div className="layout">
    <Topbar signOut={signOut} user={user} />
    <LayoutContent>{children}</LayoutContent>
  </div>
)

const LayoutContent: FunctionComponent = ({ children }) => (
  <div className="layout__content">{children}</div>
)

export default Layout

import GroupAddIcon from "@mui/icons-material/GroupAdd"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { Folder } from "../../utils/api"
import { SHARE_FOLDER_ACTION } from "./commons"

export interface GedPermissionsProps {
  projectId: string
  folder?: Folder
  handleDialogOpen: (action: string) => void
}

export const GedPermissions: FunctionComponent<GedPermissionsProps> = ({
  projectId,
  folder,
  handleDialogOpen,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  if (!projectId) return null

  const strings = {
    permissions: "Permissions",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
  }

  const on_click = () => {
    folder = folder as Folder
    handleDialogOpen(SHARE_FOLDER_ACTION)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ m: "0.3em" }}>
        <Tooltip
          disableHoverListener={folder?.permissions?.canShare}
          title={folder?.permissions?.canShare ? false : strings.unauthorized}
          arrow
          placement="bottom"
        >
          <span>
            <Button
              startIcon={<GroupAddIcon />}
              ref={anchorRef}
              variant="outlined"
              id="composition-button"
              aria-haspopup="true"
              onClick={on_click}
              disabled={!folder?.permissions?.canShare}
            >
              {strings.permissions}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}

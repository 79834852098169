import { cloneDeep } from "lodash"
import { createContext, Dispatch } from "react"
import {
  DraftReport,
  PartieNonVisitee,
  PartieVisitee,
  PartieVisiteeSousOuvrageComposant,
  PartieVisiteeSousOuvrageComposantElement,
  PartieVisiteeSousOuvrageComposantElementCommentaire,
} from "../../types/report"
import { CalculateSousOuvragesAndOuvrageNotes } from "../../utils/noteIQOA"
import { DroppedFiles } from "./ReportEditionDropZone"

interface AddPartieNonVisiteeAction {
  type: "addPartieNonVisitee"
  data: PartieNonVisitee
}

interface AddPartieVisiteeAction {
  type: "addPartieVisitee"
  data: PartieVisitee
}

interface AddPartieVisiteeComposantAction {
  type: "addPartieVisiteeComposant"
  partieVisiteeIndex: number
  data: PartieVisiteeSousOuvrageComposant
}

interface AddPartieVisiteeElementAction {
  type: "addPartieVisiteeElement"
  partieVisiteeIndex: number
  composantIndex: number
  data: PartieVisiteeSousOuvrageComposantElement
}

interface AddPartieVisiteeElementCommentaireAction {
  type: "addPartieVisiteeElementCommentaire"
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  data: PartieVisiteeSousOuvrageComposantElementCommentaire
}

interface RemovePartieNonVisiteeAction {
  type: "removePartieNonVisitee"
  partieNonVisiteeIndex: number
}

interface RemovePartieVisiteeAction {
  type: "removePartieVisitee"
  partieVisiteeIndex: number
}

interface RemovePartieVisiteeComposantAction {
  type: "removePartieVisiteeComposant"
  partieVisiteeIndex: number
  partieVisiteeComposantIndex: number
}

interface RemovePartieVisiteeElementAction {
  type: "removePartieVisiteeElement"
  partieVisiteeIndex: number
  partieVisiteeComposantIndex: number
  partieVisiteeElementIndex: number
}

interface RemovePartieVisiteeElementCommentaireAction {
  type: "removePartieVisiteeElementCommentaire"
  partieVisiteeIndex: number
  partieVisiteeComposantIndex: number
  partieVisiteeElementIndex: number
  elementCommentaireIndex: number
}

interface ReplacePartieNonVisiteeAction {
  type: "replacePartieNonVisitee"
  data: PartieNonVisitee
  index: number
}

interface ReplaceReportAction {
  type: "replaceReport"
  data: DraftReport
}

interface ReplaceRootAttributeAction {
  type: "replaceRootAttribute"
  data: any
  key: string
}

interface ReplacePartieVisiteeAction {
  type: "replacePartieVisitee"
  data: PartieVisitee
  partieVisiteeIndex: number
}

interface ReplacePartieVisiteeComposantAction {
  type: "replacePartieVisiteeComposant"
  partieVisiteeIndex: number
  composantIndex: number
  data: PartieVisiteeSousOuvrageComposant
}

interface ReplacePartieVisiteeElementAction {
  type: "replacePartieVisiteeElement"
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  data: PartieVisiteeSousOuvrageComposantElement
}

interface ReplacePartieVisiteeElementCommentaireFileAction {
  type: "replacePartieVisiteeElementCommentaireFile"
  subType: "merge" | "set" | "remove"
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  elementCommentaireIndex: number
  files?: DroppedFiles
  index?: number
}

interface ReplacePartieVisiteeElementCommentaireAction {
  type: "replacePartieVisiteeElementCommentaire"
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  elementCommentaireIndex: number
  data: PartieVisiteeSousOuvrageComposantElementCommentaire
}

interface ReplaceIsModifiedAction {
  type: "replaceIsModified"
  data: boolean
}

export type ReportAction =
  | AddPartieNonVisiteeAction
  | AddPartieVisiteeAction
  | AddPartieVisiteeComposantAction
  | AddPartieVisiteeElementAction
  | AddPartieVisiteeElementCommentaireAction
  | RemovePartieNonVisiteeAction
  | RemovePartieVisiteeAction
  | RemovePartieVisiteeComposantAction
  | RemovePartieVisiteeElementAction
  | RemovePartieVisiteeElementCommentaireAction
  | ReplacePartieNonVisiteeAction
  | ReplaceReportAction
  | ReplaceRootAttributeAction
  | ReplacePartieVisiteeAction
  | ReplacePartieVisiteeComposantAction
  | ReplacePartieVisiteeElementAction
  | ReplacePartieVisiteeElementCommentaireFileAction
  | ReplacePartieVisiteeElementCommentaireAction
  | ReplaceIsModifiedAction

export const ReportReducerDispatch = createContext<{
  report: DraftReport | undefined
  dispatchReport: Dispatch<ReportAction>
}>({
  report: undefined,
  dispatchReport: () => null,
})

export const reportReducer = (
  report: DraftReport | undefined,
  action: ReportAction
): DraftReport | undefined => {
  // updateReport does not need a Report to exist
  if (action.type === "replaceReport") {
    return action.data
  }

  if (!report) {
    return undefined
  }

  report = CalculateSousOuvragesAndOuvrageNotes(report)

  switch (action.type) {
    case "addPartieNonVisitee":
      return addPartieNonVisitee(report, action)
    case "addPartieVisitee":
      return addPartieVisitee(report, action)
    case "addPartieVisiteeComposant":
      return addPartieVisiteeComposant(report, action)
    case "addPartieVisiteeElement":
      return addPartieVisiteeElement(report, action)
    case "addPartieVisiteeElementCommentaire":
      return addPartieVisiteeElementCommentaire(report, action)
    case "removePartieNonVisitee":
      return removePartieNonVisitee(report, action)
    case "removePartieVisitee":
      return removePartieVisitee(report, action)
    case "removePartieVisiteeComposant":
      return removePartieVisiteeComposant(report, action)
    case "removePartieVisiteeElement":
      return removePartieVisiteeElement(report, action)
    case "removePartieVisiteeElementCommentaire":
      return removePartieVisiteeElementCommentaire(report, action)
    case "replacePartieNonVisitee":
      return replacePartieNonVisitee(report, action)
    case "replaceRootAttribute":
      return replaceRootAttribute(report, action)
    case "replacePartieVisitee":
      return replacePartieVisitee(report, action)
    case "replacePartieVisiteeComposant":
      return replacePartieVisiteeComposant(report, action)
    case "replacePartieVisiteeElement":
      return replacePartieVisiteeElement(report, action)
    case "replacePartieVisiteeElementCommentaire":
      return replacePartieVisiteeElementCommentaire(report, action)
    case "replacePartieVisiteeElementCommentaireFile": {
      return replacePartieVisiteeElementCommentaireFile(report, action)
    }
    case "replaceIsModified":
      return replaceIsModified(report, action)
  }

  console.error("[reportReducer] Unknown action ", action)
  return report
}

const replacePartieVisitee = (
  report: DraftReport,
  action: ReplacePartieVisiteeAction
) => {
  const newReport = cloneDeep(report)

  if (newReport.form?.partiesVisitees?.[action.partieVisiteeIndex] == null) {
    console.error("[replacePartieVisitee] Cannot find value", {
      report,
      action,
    })
    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex] = action.data
  newReport.isModified = true

  return newReport
}

const replacePartieVisiteeComposant = (
  report: DraftReport,
  action: ReplacePartieVisiteeComposantAction
) => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ] == null
  ) {
    console.error("[replacePartieVisiteeComposant] Cannot find value", {
      report,
      action,
    })
    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.composantIndex
  ] = action.data

  newReport.isModified = true

  return newReport
}

const replacePartieVisiteeElement = (
  report: DraftReport,
  action: ReplacePartieVisiteeElementAction
) => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ]?.elements?.[action.elementIndex] == null
  ) {
    console.error("[replacePartieVisiteeElement] Cannot find value", {
      report,
      action,
    })
    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.composantIndex
  ].elements[action.elementIndex] = action.data

  newReport.isModified = true

  return newReport
}

const replacePartieVisiteeElementCommentaireFile = (
  report: DraftReport,
  action: ReplacePartieVisiteeElementCommentaireFileAction
): DraftReport => {
  const newReport = cloneDeep(report)

  const cleanBlobInForm = (report: DraftReport): DraftReport => {
    const newReport = cloneDeep(report)

    newReport.form.partiesVisitees?.forEach((so) => {
      so.composants.forEach((compo) => {
        compo.elements.forEach((elem) => {
          elem.commentaires.forEach((comment) => {
            comment.attachedFiles = comment.attachedFiles?.map((file) => {
              return {
                name: file.name,
              }
            })
          })
        })
      })
    })

    return newReport
  }

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ]?.elements?.[action.elementIndex]?.commentaires?.[
      action.elementCommentaireIndex
    ] == null
  ) {
    console.error(
      "[replacePartieVisiteeElementCommentaire] Cannot find value",
      { report, action }
    )
    return cleanBlobInForm(newReport)
  }

  if (action.files) {
    if (action.subType == "merge") {
      const currentFiles =
        newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
          action.composantIndex
        ].elements[action.elementIndex].commentaires[
          action.elementCommentaireIndex
        ].attachedFiles
      if (currentFiles != undefined) {
        newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
          action.composantIndex
        ].elements[action.elementIndex].commentaires[
          action.elementCommentaireIndex
        ].attachedFiles = [...currentFiles, ...action.files]
        return cleanBlobInForm(newReport)
      } else {
        newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
          action.composantIndex
        ].elements[action.elementIndex].commentaires[
          action.elementCommentaireIndex
        ].attachedFiles = [...action.files]
        return cleanBlobInForm(newReport)
      }
    } else if (action.subType == "set") {
      newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
        action.composantIndex
      ].elements[action.elementIndex].commentaires[
        action.elementCommentaireIndex
      ].attachedFiles = action.files
      return cleanBlobInForm(newReport)
    }
  } else {
    if (
      action.subType == "remove" &&
      action.index != undefined &&
      action.index >= 0
    ) {
      let newDroppedFiles = [
        ...(newReport.form.partiesVisitees[action.partieVisiteeIndex]
          .composants[action.composantIndex].elements[action.elementIndex]
          .commentaires[action.elementCommentaireIndex]
          .attachedFiles as DroppedFiles),
      ]
      newDroppedFiles.splice(action.index, 1)
      newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
        action.composantIndex
      ].elements[action.elementIndex].commentaires[
        action.elementCommentaireIndex
      ].attachedFiles = newDroppedFiles
      return cleanBlobInForm(newReport)
    }
  }

  return cleanBlobInForm(newReport)
}

const replacePartieVisiteeElementCommentaire = (
  report: DraftReport,
  action: ReplacePartieVisiteeElementCommentaireAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ]?.elements?.[action.elementIndex]?.commentaires?.[
      action.elementCommentaireIndex
    ] == null
  ) {
    console.error(
      "[replacePartieVisiteeElementCommentaire] Cannot find value",
      { report, action }
    )
    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.composantIndex
  ].elements[action.elementIndex].commentaires[action.elementCommentaireIndex] =
    action.data

  newReport.isModified = true

  return newReport
}

const replaceIsModified = (
  report: DraftReport,
  action: ReplaceIsModifiedAction
): DraftReport => {
  return {
    isModified: action["data"],
    ...report,
  }
}

const replaceRootAttribute = (
  report: DraftReport,
  action: ReplaceRootAttributeAction
): DraftReport => {
  return {
    isModified: true,
    ...report,
    form: {
      ...report.form,
      [action.key]: action.data,
    },
  }
}

const addPartieNonVisitee = (
  report: DraftReport,
  action: AddPartieNonVisiteeAction
): DraftReport => {
  const newReport = cloneDeep(report)
  const partiesNonVisitees = newReport.form.partiesNonVisitees || []

  newReport.form.partiesNonVisitees = [...partiesNonVisitees, action.data]
  newReport.isModified = true

  return newReport
}

const addPartieVisitee = (
  report: DraftReport,
  action: AddPartieVisiteeAction
): DraftReport => {
  const newReport = cloneDeep(report)
  const partiesVisitees = newReport.form.partiesVisitees || []

  newReport.form.partiesVisitees = [...partiesVisitees, action.data]
  newReport.isModified = true

  return newReport
}

const addPartieVisiteeComposant = (
  report: DraftReport,
  action: AddPartieVisiteeComposantAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (newReport.form?.partiesVisitees?.[action.partieVisiteeIndex] == null) {
    console.error("[addPartieVisiteeComposant] Cannot find base value", {
      report,
      action,
    })
    return newReport
  }

  const composants =
    newReport.form.partiesVisitees[action.partieVisiteeIndex].composants || []

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants = [
    ...composants,
    action.data,
  ]

  newReport.isModified = true

  return newReport
}

const addPartieVisiteeElement = (
  report: DraftReport,
  action: AddPartieVisiteeElementAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ] == null
  ) {
    console.error("[addPartieVisiteeComposant] Cannot find base value", {
      report,
      action,
    })
    return newReport
  }

  const elements =
    newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
      action.composantIndex
    ].elements || []

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.composantIndex
  ].elements = [...elements, action.data]

  newReport.isModified = true

  return newReport
}

const addPartieVisiteeElementCommentaire = (
  report: DraftReport,
  action: AddPartieVisiteeElementCommentaireAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.composantIndex
    ]?.elements?.[action.elementIndex] == null
  ) {
    console.error(
      "[addPartieVisiteeElementCommentaire] Cannot find base value",
      { report, action }
    )
    return newReport
  }

  const commentaires =
    newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
      action.composantIndex
    ].elements[action.elementIndex].commentaires || []

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.composantIndex
  ].elements[action.elementIndex].commentaires = [...commentaires, action.data]

  newReport.isModified = true

  return newReport
}

const removePartieNonVisitee = (
  report: DraftReport,
  action: RemovePartieNonVisiteeAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesNonVisitees?.[action.partieNonVisiteeIndex] == null
  ) {
    console.error("[removePartieNonVisitee] Cannot find base value", {
      report,
      action,
    })
    return newReport
  }

  newReport.form.partiesNonVisitees.splice(action.partieNonVisiteeIndex, 1)

  newReport.isModified = true

  return newReport
}

const removePartieVisitee = (
  report: DraftReport,
  action: RemovePartieVisiteeAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (newReport.form?.partiesVisitees?.[action.partieVisiteeIndex] == null) {
    console.error("[removePartieVisitee] Cannot find base value", {
      report,
      action,
    })
    return newReport
  }

  newReport.form.partiesVisitees.splice(action.partieVisiteeIndex, 1)

  newReport.isModified = true

  return newReport
}

const removePartieVisiteeComposant = (
  report: DraftReport,
  action: RemovePartieVisiteeComposantAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.partieVisiteeComposantIndex
    ] == null
  ) {
    console.error(
      "[removePartieVisiteeComposantAction] Cannot find base value",
      {
        report,
        action,
      }
    )

    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants.splice(
    action.partieVisiteeComposantIndex,
    1
  )

  newReport.isModified = true

  return newReport
}

const removePartieVisiteeElement = (
  report: DraftReport,
  action: RemovePartieVisiteeElementAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.partieVisiteeComposantIndex
    ]?.elements[action.partieVisiteeElementIndex] == null
  ) {
    console.error("[removePartieVisiteeElementAction] Cannot find base value", {
      report,
      action,
    })

    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.partieVisiteeComposantIndex
  ].elements.splice(action.partieVisiteeElementIndex, 1)

  newReport.isModified = true

  return newReport
}

const removePartieVisiteeElementCommentaire = (
  report: DraftReport,
  action: RemovePartieVisiteeElementCommentaireAction
): DraftReport => {
  const newReport = cloneDeep(report)

  if (
    newReport.form?.partiesVisitees?.[action.partieVisiteeIndex]?.composants?.[
      action.partieVisiteeComposantIndex
    ]?.elements[action.partieVisiteeElementIndex]?.commentaires?.[
      action.elementCommentaireIndex
    ] == null
  ) {
    console.error(
      "[removePartieVisiteeElementCommentaireAction] Cannot find base value"
    )

    return newReport
  }

  newReport.form.partiesVisitees[action.partieVisiteeIndex].composants[
    action.partieVisiteeComposantIndex
  ].elements[action.partieVisiteeElementIndex].commentaires.splice(
    action.elementCommentaireIndex,
    1
  )

  newReport.isModified = true

  return newReport
}

const replacePartieNonVisitee = (
  report: DraftReport,
  action: ReplacePartieNonVisiteeAction
): DraftReport => {
  if (report.form.partiesNonVisitees == null) {
    console.error(
      "[updatePartieNonVisitee] report.form.partiesNonVisitees is null or undefined",
      {
        report,
        action,
      }
    )
    return report
  }
  if (
    action.index < 0 ||
    action.index >= report.form.partiesNonVisitees.length
  ) {
    console.error("[updatePartieNonVisitee] action.index is incorrect", {
      report,
      action,
    })
    return report
  }

  // Reminder: states are immutable.

  // Make a shallow copy of report
  const newReport = { ...report }
  // Make a shallow copy of report form (since the first copy is not a deep copy)
  const newReportForm = { ...report.form }
  // Edit partiesNonVisitees (note: since partiesNonVisitees has been checked, it cannot be undefined)
  newReportForm.partiesNonVisitees![action.index] = action.data
  // Put edited form back to report's shallow copy
  newReport.form = newReportForm

  newReport.isModified = true

  return newReport
}

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Folder, getGedAPI, Resource } from "../../utils/api"
import {
  dialogTitleStyle,
  getDialogError,
  getDialogLoader,
  getReadablePath,
} from "./commons"
import { DialogBoxFolderView } from "./DialogBoxFolderView"

export interface DialogBoxMoveProps {
  projectId: string
  kind: string
  folder: Folder
  resource: Resource
  handleClose: () => void
  setFile?: (resource: Resource) => void
}

export const DialogBoxMove: FunctionComponent<DialogBoxMoveProps> = ({
  projectId,
  kind,
  folder,
  resource,
  handleClose,
  setFile,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [destination, setDestination] = useState<Folder | undefined>(undefined)
  const [pwd, setPwd] = useState<string>("/")

  const styles = {
    size: { minWidth: "40em" },
    largeSize: { minWidth: "60em", height: "auto" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
    destinationTitle: { fontWeight: "bold" },
    destination: { backgroundColor: "#edeff2", textAlign: "center" },
    moveBox: { margin: "1em", border: "1px solid grey" },
  }

  const strings = {
    dialogTitle:
      kind == "folder" ? "Déplacer le dossier" : "Déplacer le fichier",
    buttonCancel: "Annuler",
    buttonMove: "Déplacer",
    destination: "Destination",
  }

  // FYI: Here i'm using useCallback to fetch the current folder.
  // Changing the value of the state pwd with setPwd will trigger this callback.
  const getFolder = useCallback(() => {
    setIsLoading(true)
    setError(false)
    getGedAPI()
      .getFiles(projectId, pwd, true)
      .then((file) => {
        setDestination(file as Folder)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }, [projectId, pwd])

  useEffect(() => {
    getFolder()
  }, [getFolder])

  const isChildrenOf = (parent: Folder): boolean => {
    if (!destination) return false
    if (
      destination.path.startsWith(parent.path) &&
      destination.path.charAt(parent.path.length) == "/"
    )
      return true
    return false
  }

  const isActionValid = (): boolean => {
    const isNameAlreadyTaken = (
      sourceName: string,
      content: Resource[]
    ): boolean => {
      for (let i = 0; i < content.length; i++) {
        if (sourceName == content[i].name) return true
      }
      return false
    }

    let ret: boolean = true
    if (!destination) return false
    if (error || isLoading) return false
    if (destination.path == resource.path) return false
    if (destination && destination.content) {
      if (isNameAlreadyTaken(resource.name, destination.content)) return false
    }
    if (resource.kind == "Folder") {
      ret = !isChildrenOf(resource)
    }
    if (destination && destination.content && destination.content.length > 0) {
      destination.content.forEach((file) => {
        if (file.path == resource.path) ret = false
        return
      })
    }
    return ret
  }

  const onBackClick = () => {
    if (!destination) return null
    const targetPath = destination.path.split("/")
    setPwd(targetPath.slice(0, targetPath.length - 1).join("/"))
  }

  const onFolderChange = (path: string) => {
    setPwd(path)
  }

  const onMove = () => {
    const errorAction = () => {
      setIsLoading(false)
      setError(true)
    }

    const successAction = () => {
      if (setFile) {
        getGedAPI()
          .getFiles(projectId, folder.path, true)
          .then((file) => {
            setFile(file)
            setIsLoading(false)
            handleClose()
          })
          .catch(() => {
            errorAction()
          })
      }
    }

    setIsLoading(true)
    setError(false)
    getGedAPI()
      .moveResource(projectId, resource, destination as Folder)
      .then((response) => {
        if (response.status == "success") return successAction()
        return errorAction()
      })
      .catch(() => {
        errorAction()
      })
  }

  const getDialogMoveContent = () => {
    return (
      <DialogBoxFolderView
        projectId={projectId}
        folder={destination}
        onFolderChange={onFolderChange}
        onBackClick={onBackClick}
      />
    )
  }

  const getDialogBox = () => {
    return (
      <div style={styles.largeSize}>
        <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
        <List sx={{ pt: 0 }}>
          <DialogContent sx={styles.dialogContent}>
            <Box sx={styles.destinationTitle}>{strings.destination}:</Box>
            <Box sx={styles.destination}>
              {getReadablePath(destination ? destination.path : "/")}
            </Box>
            {isLoading ? getDialogLoader() : getDialogMoveContent()}
          </DialogContent>
          <DialogActions sx={{ position: "relative", bottom: 0, right: 0 }}>
            <Button onClick={handleClose}>{strings.buttonCancel}</Button>
            <Button disabled={!isActionValid()} onClick={onMove}>
              {strings.buttonMove}
            </Button>
          </DialogActions>
        </List>
      </div>
    )
  }

  if (error) return getDialogError(handleClose)

  if (isLoading) return getDialogLoader()

  return getDialogBox()
}

import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useState } from "react"
import { Report } from "../../types/report"
import { getMainAPI } from "../../utils/api"

import { dialogTitleStyle, getDialogError, getDialogLoader } from "./commons"

export interface DialogBoxDraftProps {
  projectId: string
  report: Report
  handleClose: () => void
}

export const DialogBoxDraft: FunctionComponent<DialogBoxDraftProps> = ({
  projectId,
  report,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
  }

  const strings = {
    dialogTitle: `Passer un indice en édition`,
    confirm: `Voulez-vous vraiment passer l'indice ${report.indice} du rapport ${report.title} en édition ?`,
    warns: `Cette action invalidera cet indice et entraînera la suppression de l'ensemble des données liées à cet indice.`,
    buttonCancel: "Annuler",
    buttonCreate: "Confirmer",
  }

  const onDraft = () => {
    setIsLoading(true)
    getMainAPI()
      .draftReport(projectId, report.id)
      .then(() => {
        setIsLoading(false)
        handleClose()
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
        setError(true)
      })
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          {strings.confirm}
          <br />
          {strings.warns}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button onClick={onDraft}>{strings.buttonCreate}</Button>
        </DialogActions>
      </List>
    </div>
  )
}

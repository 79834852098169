import { FunctionComponent } from "react"
import { NavLink } from "react-router-dom"
import Icon from "../Icon/Icon"

export interface SideBarItem {
  name: string
  link: string
  icon: string
  active?: boolean
  disabled?: boolean
}

export interface SidebarProps {
  items: SideBarItem[]
}

const Sidebar: FunctionComponent<SidebarProps> = ({ items }) => (
  <ol className="nav flex-column side-bar">
    {items.map((item, key) => (
      <li key={key} className="nav-item position-relative">
        <NavLink
          isActive={() => !!item.active}
          to={item.link}
          className="nav-link stretched-link"
        >
          <Icon icon={item.icon} />
          <span className="label">{item.name}</span>
        </NavLink>
      </li>
    ))}
  </ol>
)

export default Sidebar

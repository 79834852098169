import {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from "react"
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Icon from "../../components/Icon/Icon"
import { PartieNonVisitee } from "../../types/report"
import { Hierarchy } from "../../utils/api"
import { CustomToggle } from "./ReportEditionDropZone"
import { ReportReducerDispatch } from "./ReportEditionReportReducer"
import { SelectedSetsReducerDispatch } from "./ReportEditionSetReducers"

interface ReportEditionPartiesNonVisiteesProps {
  hierarchy?: Hierarchy
}

interface ReportPartieNonVisiteeSectionProps {
  partieNonVisitee: PartieNonVisitee
  index: number
  hierarchy?: Hierarchy
}

const ReportEditionPartiesNonVisitees: FunctionComponent<
  ReportEditionPartiesNonVisiteesProps
> = ({ hierarchy }) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)
  if (!report) {
    return <></>
  }

  const addSection = () => {
    dispatchReport({
      type: "addPartieNonVisitee",
      data: {
        sousOuvrageId: "",
      },
    })
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`Parties non visitées (${
              report.form.partiesNonVisitees?.length || 0
            })`}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Button
              variant={"link"}
              className="add-button"
              onClick={addSection}
            >
              <Icon icon="add_circle_outline" />
              Ajouter un sous-ouvrage
            </Button>
            {report.form.partiesNonVisitees?.length ? (
              <Card.Body>
                {report.form.partiesNonVisitees?.map(
                  (partieNonVisitee, index) => (
                    <div key={`div-nv-${index}`}>
                      <ReportEditionPartieNonVisiteeSection
                        key={`ReportEditionPartieNonVisiteeSection-${index}`}
                        index={index}
                        partieNonVisitee={partieNonVisitee}
                        hierarchy={hierarchy}
                      />

                      {/* {index < e.length - 1 ? (
                      <hr className={"separator"} key={`hr-nv-${index}`} />
                    ) : null} */}
                    </div>
                  )
                )}
              </Card.Body>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportEditionPartieNonVisiteeSection: FunctionComponent<
  ReportPartieNonVisiteeSectionProps
> = ({ partieNonVisitee, index, hierarchy }) => {
  const { dispatchReport } = useContext(ReportReducerDispatch)
  const { selectedSets, dispatchSelectedSets } = useContext(
    SelectedSetsReducerDispatch
  )

  const initSet = useCallback(() => {
    if (partieNonVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "add",
        key: "so",
        data: partieNonVisitee.sousOuvrageId,
      })
    }
  }, [dispatchSelectedSets, partieNonVisitee])

  useEffect(() => {
    initSet()
  }, [initSet])

  const commentaireOnChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const newPartieNonVisitee = { ...partieNonVisitee }
    newPartieNonVisitee.commentaire = e.target.value

    dispatchReport({
      type: "replacePartieNonVisitee",
      data: newPartieNonVisitee,
      index: index,
    })
  }

  const sousOuvrageOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    // No need to continue if user selected same option
    if (e.target.value === partieNonVisitee.sousOuvrageId) {
      return
    }

    const newPartieNonVisitee = { ...partieNonVisitee }

    // Remove old selection from set
    if (newPartieNonVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "remove",
        key: "so",
        data: newPartieNonVisitee.sousOuvrageId,
      })
    }

    newPartieNonVisitee.sousOuvrageId = e.target.value

    dispatchReport({
      type: "replacePartieNonVisitee",
      data: newPartieNonVisitee,
      index: index,
    })

    if (newPartieNonVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "add",
        key: "so",
        data: newPartieNonVisitee.sousOuvrageId,
      })
    }
  }

  const removeComposant = () => {
    if (partieNonVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "remove",
        key: "so",
        data: partieNonVisitee.sousOuvrageId,
      })
    }

    dispatchReport({
      type: "removePartieNonVisitee",
      partieNonVisiteeIndex: index,
    })
  }

  const sousOuvrages = hierarchy?.sousOuvrages || []

  return (
    <Accordion>
      <Card>
        <Container fluid>
          <CustomToggle eventKey="0">
            <Card.Header>
              {partieNonVisitee.sousOuvrageId
                ? `Sous-ouvrage : ${partieNonVisitee.sousOuvrageId}`
                : "Sous-ouvrage : "}
            </Card.Header>
          </CustomToggle>
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              <Container fluid>
                <Row className="custom">
                  <Col className={"form-col flex"}>
                    <Form.Label className={"sous-ouvrage-non-visite-label"}>
                      Sous-ouvrage :
                    </Form.Label>
                    <Form.Select
                      required
                      onChange={sousOuvrageOnChange}
                      defaultValue={partieNonVisitee.sousOuvrageId}
                    >
                      <option value="">Sélectionner un Sous ouvrage</option>
                      {sousOuvrages
                        .filter(
                          (sousOuvrage) =>
                            sousOuvrage.id === partieNonVisitee.sousOuvrageId ||
                            !selectedSets["so"].has(sousOuvrage.id)
                        )
                        .map((sousOuvrage) => (
                          <option value={sousOuvrage.id} key={sousOuvrage.id}>
                            {sousOuvrage.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Button
                      variant={"link"}
                      className="remove-sub-button flex-button"
                      onClick={removeComposant}
                    >
                      <Icon icon="remove_circle_outline" />
                      Supprimer le sous-ouvrage
                    </Button>
                  </Col>
                </Row>
                <Row className="custom">
                  <Col className={"form-col"}>
                    <Form.Control
                      name={`partieNonVisitee_commentaire_${index}`}
                      value={partieNonVisitee.commentaire}
                      onChange={commentaireOnChange}
                      as={"textarea"}
                    />
                  </Col>
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Container>
      </Card>
    </Accordion>
  )
}

export default ReportEditionPartiesNonVisitees

import { Fragment, FunctionComponent } from "react"
import { Redirect } from "react-router-dom"
import { AdminView } from "./containers/AdminView/AdminView"
import DashboardView from "./containers/DashboardView/DashboardView"
import GedView from "./containers/GedView/GedView"
import GetAllReportsView from "./containers/GetAllReportsView/GetAllReportsView"
import HomeView from "./containers/HomeView/HomeView"
import { ProjectAdminView } from "./containers/ProjectAdminView/ProjectAdminView"
import ProjectView from "./containers/ProjectView/ProjectView"
import { ReportConsultationIndice } from "./containers/ReportConsultationIndice/ReportConsultationIndice"
import { CognitoUserExt } from "./types/CognitoUserExt"
import { Hierarchy, Project } from "./utils/api"
import { isAdmin } from "./utils/user"

export interface RouteProps {
  user: CognitoUserExt
}

export interface Route<TProps extends RouteProps = RouteProps> {
  readonly path: string
  readonly component: FunctionComponent<TProps>
  readonly exact?: boolean
}

export interface ProjectSubrouteProps extends RouteProps {
  project: Project
  hierarchy?: Hierarchy
}

export interface ProjectSubroute extends Route<ProjectSubrouteProps> {
  name?: string
  icon?: string
  sidebar?: boolean
  projectAdmin?: boolean
}

const ROUTES: Route[] = [
  {
    path: "/home",
    component: () => <HomeView />,
  },
  {
    path: "/",
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/project/:projectId",
    exact: false,
    component: ({ user }) => (
      <ProjectView subroutes={PROJECT_SUBROUTES} user={user} />
    ),
  },
  {
    path: "/admin",
    component: ({ user }) => {
      if (isAdmin(user)) return <AdminView />
      else return <h3>NOT AUTHORIZED</h3> // TODO ADD TRUE NOT AUTH
    },
  },
  {
    path: "*",
    component: () => <div>404 not found</div>,
  },
]

const PROJECT_SUBROUTES: ProjectSubroute[] = [
  {
    path: "/model",
    component: () => <Fragment />,
    name: "Maquette",
    icon: "view_in_ar",
    sidebar: true,
  },
  {
    path: "/dashboard",
    component: ({ project, hierarchy }) => (
      <DashboardView project={project} hierarchy={hierarchy} />
    ),
    name: "Bilan de santé",
    icon: "bar_chart",
    sidebar: true,
  },
  {
    path: "/files",
    exact: false,
    component: ({ project, hierarchy, user }) => (
      <GedView project={project} hierarchy={hierarchy} user={user} />
    ),
    name: "Documentation",
    icon: "topic",
    sidebar: true,
  },
  {
    path: "/report/:reportId",
    component: ({ project, hierarchy, user }) => (
      <ReportConsultationIndice
        hierarchy={hierarchy}
        project={project}
        user={user}
      />
    ),
  },
  {
    path: "/reports",
    component: ({ project }) => <GetAllReportsView project={project} />,
    sidebar: true,
    icon: "description",
    name: "Inspections & Interventions",
  },
  {
    path: "/admin",
    component: ({ project }) => <ProjectAdminView project={project} />,
    name: "Administration",
    icon: "admin_panel_settings",
    sidebar: true,
    projectAdmin: true,
  },
]

export default ROUTES

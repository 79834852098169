import { FunctionComponent } from "react"
import MarkdownIt from "markdown-it"

interface ModelDescriptionProps {
  description: string | undefined
}

const ModelDescription: FunctionComponent<ModelDescriptionProps> = ({
  description,
}) => {
  if (!description) {
    return <span>{"Aucune description"}</span>
  }

  const md = new MarkdownIt({
    html: false, // Enable HTML tags in source
    xhtmlOut: false, // Use '/' to close single tags (<br />).
    breaks: false, // Convert '\n' in paragraphs into <br>
    linkify: false, // Autoconvert URL-like text to links
    typographer: true, // Enable some language-neutral replacement + quotes beautification
    quotes: "“”‘’", // Double + single quotes replacement pairs, when typographer enabled
  })
  const renderedMd = md.render(description)

  return (
    <span
      className={"markdown-text"}
      dangerouslySetInnerHTML={{ __html: renderedMd }}
    />
  )
}

export default ModelDescription

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { Report } from "../../types/report"
import { Project } from "../../utils/api"
import { Disabled, DRAFT_REPORT_INDICE_ACTION, isLastIndice } from "./commons"

export interface DraftReportProps {
  project: Project
  report?: Report
  handleDialogOpen: (action: string, needUpdate: boolean) => void
}

export const DraftReport: FunctionComponent<DraftReportProps> = ({
  report,
  handleDialogOpen,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const strings = {
    title: "Passer en édition",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
    draft: "Cet indice est déjà en édition.",
    onlyLast: "Vous pouvez uniquement éditer le dernier indice.",
  }

  const on_click = () => {
    handleDialogOpen(DRAFT_REPORT_INDICE_ACTION, false)
  }

  const mustDisabled = (): Disabled => {
    if (report?.role != "owner")
      return { disabled: true, message: strings.unauthorized }
    if (!report?.isSubmitted) return { disabled: true, message: strings.draft }
    if (!isLastIndice(report, report.indicesReports as Report[]))
      return { disabled: true, message: strings.onlyLast }
    return { disabled: false, message: "" }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ m: "0.3em" }}>
        <Tooltip
          disableHoverListener={!mustDisabled().disabled}
          title={mustDisabled().disabled ? mustDisabled().message : false}
          arrow
          placement="bottom"
        >
          <span>
            <Button
              startIcon={<DriveFileRenameOutlineIcon />}
              ref={anchorRef}
              variant="outlined"
              id="composition-button"
              aria-haspopup="true"
              onClick={on_click}
              disabled={mustDisabled().disabled}
            >
              {strings.title}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}

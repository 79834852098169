import {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
} from "react"
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import Icon from "../../components/Icon/Icon"
import {
  PartieVisitee,
  PartieVisiteeSousOuvrageComposant,
  PartieVisiteeSousOuvrageComposantElement,
  PartieVisiteeSousOuvrageComposantElementCommentaire,
} from "../../types/report"
import {
  Composant,
  ElementWithinHierarchy,
  Hierarchy,
  Project,
  SousOuvrage,
} from "../../utils/api"
import { NOTE_IQOA_VALUES } from "../../utils/noteIQOA"
import ReportEditionDropZone, { CustomToggle } from "./ReportEditionDropZone"
import { ReportReducerDispatch } from "./ReportEditionReportReducer"
import { SelectedSetsReducerDispatch } from "./ReportEditionSetReducers"

interface ReportEditionPartiesVisiteesProps {
  project: Project
  hierarchy?: Hierarchy
}

interface ReportEditionPartieVisiteeSousOuvrageSectionProps {
  project: Project
  partieVisitee: PartieVisitee
  partieVisiteeIndex: number
  sousOuvrages: SousOuvrage[]
}

interface ReportEditionPartieVisiteeComposantSectionProps {
  project: Project
  parentSousOuvragesId: string
  partieVisiteeSOComposant: PartieVisiteeSousOuvrageComposant
  partieVisiteeIndex: number
  composantIndex: number
  composants?: Composant[]
}

interface ReportEditionPartieVisiteeElementSectionProps {
  project: Project
  parentSousOuvragesId: string
  parentComposantId: string
  partieVisiteeSOElement: PartieVisiteeSousOuvrageComposantElement
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  elements?: ElementWithinHierarchy[]
}

interface ReportEditionPartieVisiteeElementCommentaireProps {
  project: Project
  partieVisiteeIndex: number
  composantIndex: number
  elementIndex: number
  elementCommentaireIndex: number
  commentaire?: PartieVisiteeSousOuvrageComposantElementCommentaire
}

const ReportEditionPartiesVisitees: FunctionComponent<
  ReportEditionPartiesVisiteesProps
> = ({ project, hierarchy }) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)
  if (!report || !hierarchy) {
    console.warn("[ReportPartiesVisiteesForm] report or hierarchy undefined", {
      report,
      hierarchy,
    })
    return <></>
  }

  const addSection: MouseEventHandler<HTMLButtonElement> = () => {
    dispatchReport({
      type: "addPartieVisitee",
      data: {
        sousOuvrageId: "",
        composants: [],
        noteIQOA: 0,
      },
    })
  }

  const sousOuvrages = hierarchy.sousOuvrages

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>{`Parties visitées (${
            report.form.partiesVisitees?.length || 0
          })`}</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Button
              variant={"link"}
              className="add-button"
              onClick={addSection}
            >
              <Icon icon="add_circle_outline" />
              Ajouter un sous-ouvrage
            </Button>
            {report.form.partiesVisitees?.length ? (
              <Card.Body>
                {report.form.partiesVisitees?.map(
                  (partieVisitee, partieVisiteeIndex) => (
                    <div key={`div-${partieVisiteeIndex}`}>
                      <ReportEditionPartieVisiteeSousOuvrageSection
                        project={project}
                        key={`ReportEditionPartieVisiteeSousOuvrageSection-${partieVisiteeIndex}`}
                        partieVisiteeIndex={partieVisiteeIndex}
                        partieVisitee={partieVisitee}
                        sousOuvrages={sousOuvrages}
                      />
                    </div>
                  )
                )}
              </Card.Body>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportEditionPartieVisiteeSousOuvrageSection: FunctionComponent<
  ReportEditionPartieVisiteeSousOuvrageSectionProps
> = ({ project, partieVisitee, partieVisiteeIndex, sousOuvrages }) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)
  const { selectedSets, dispatchSelectedSets } = useContext(
    SelectedSetsReducerDispatch
  )

  const initSet = useCallback(() => {
    if (partieVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "add",
        key: "so",
        data: partieVisitee.sousOuvrageId,
      })
    }
  }, [dispatchSelectedSets, partieVisitee])

  useEffect(() => {
    initSet()
  }, [initSet])

  const commentaireOnChange: ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ): void => {
    const newPartieVisitee = { ...partieVisitee }
    newPartieVisitee.commentaireNoteIQOA = e.target.value
    dispatchReport({
      type: "replacePartieVisitee",
      data: newPartieVisitee,
      partieVisiteeIndex: partieVisiteeIndex,
    })
  }

  const sousOuvrageOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value === partieVisitee.sousOuvrageId) {
      return
    }

    const newPartieVisitee = { ...partieVisitee }

    if (newPartieVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "remove",
        key: "so",
        data: newPartieVisitee.sousOuvrageId,
      })
    }

    newPartieVisitee.sousOuvrageId = e.target.value
    dispatchReport({
      type: "replacePartieVisitee",
      data: newPartieVisitee,
      partieVisiteeIndex: partieVisiteeIndex,
    })

    if (newPartieVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "add",
        key: "so",
        data: newPartieVisitee.sousOuvrageId,
      })
    }
  }

  const addComposant: MouseEventHandler<HTMLButtonElement> = () => {
    dispatchReport({
      type: "addPartieVisiteeComposant",
      partieVisiteeIndex: partieVisiteeIndex,
      data: {
        composantId: "",
        elements: [],
      },
    })
  }

  const removePartieVisitee = () => {
    if (partieVisitee.sousOuvrageId) {
      dispatchSelectedSets({
        type: "remove",
        key: "so",
        data: partieVisitee.sousOuvrageId,
      })
    }

    dispatchReport({
      type: "removePartieVisitee",
      partieVisiteeIndex: partieVisiteeIndex,
    })
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`${
              partieVisitee.sousOuvrageId
                ? `Sous-ouvrage : ${partieVisitee.sousOuvrageId}`
                : "Sous-ouvrage : "
            } (${partieVisitee.composants.length})`}
            {report && report.type <= 4 ? (
              <BadgeIQOA note={partieVisitee.noteIQOA} />
            ) : null}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Container fluid>
              <Row className="custom">
                <Col className={"flex form-col"}>
                  <Form.Label className={"form-label"}>
                    <p>Sous-ouvrage :</p>
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={sousOuvrageOnChange}
                    defaultValue={partieVisitee.sousOuvrageId}
                  >
                    <option value="">Sélectionner un Sous ouvrage</option>
                    {sousOuvrages
                      .filter(
                        (sousOuvrage) =>
                          sousOuvrage.id === partieVisitee.sousOuvrageId ||
                          !selectedSets["so"].has(sousOuvrage.id)
                      )
                      .map((sousOuvrage) => (
                        <option value={sousOuvrage.id} key={sousOuvrage.id}>
                          {sousOuvrage.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Button
                    variant={"link"}
                    className={"remove-sub-button flex-button"}
                    onClick={removePartieVisitee}
                  >
                    <Icon icon="remove_circle_outline" />
                    Supprimer le sous-ouvrage
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className={"custom"}>
                  <Form.Control
                    value={partieVisitee.commentaireNoteIQOA || ""}
                    onChange={commentaireOnChange}
                    as={"textarea"}
                    placeholder={"Commentaire"}
                  />
                </Col>
              </Row>
            </Container>
            {partieVisitee.sousOuvrageId ? (
              <>
                <div>
                  <Button
                    className="add-sub-button"
                    variant={"link"}
                    onClick={addComposant}
                  >
                    <h5 className="title-button">Composants</h5>
                    <Icon icon="add_circle_outline" />
                  </Button>
                </div>
                {partieVisitee.composants.map((composant, composantIndex) => (
                  <ReportEditionPartieVisiteeComposantSection
                    project={project}
                    parentSousOuvragesId={partieVisitee.sousOuvrageId}
                    partieVisiteeSOComposant={composant}
                    partieVisiteeIndex={partieVisiteeIndex}
                    composantIndex={composantIndex}
                    key={`ReportEditionPartieVisiteeComposantSection-${partieVisiteeIndex}-${composantIndex}`}
                    composants={
                      sousOuvrages.find(
                        (so) => so.id === partieVisitee.sousOuvrageId
                      )?.composants
                    }
                  />
                ))}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export const ReportEditionPartieVisiteeComposantSection: FunctionComponent<
  ReportEditionPartieVisiteeComposantSectionProps
> = ({
  project,
  parentSousOuvragesId,
  partieVisiteeSOComposant,
  partieVisiteeIndex,
  composantIndex,
  composants,
}) => {
  const { dispatchReport } = useContext(ReportReducerDispatch)
  const { selectedSets, dispatchSelectedSets } = useContext(
    SelectedSetsReducerDispatch
  )

  const initSet = useCallback(() => {
    if (partieVisiteeSOComposant.composantId) {
      dispatchSelectedSets({
        type: "add",
        key: "composants",
        data: partieVisiteeSOComposant.composantId,
      })
    }
  }, [dispatchSelectedSets, partieVisiteeSOComposant])

  useEffect(() => {
    initSet()
  }, [initSet])

  const composantOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value === partieVisiteeSOComposant.composantId) {
      return
    }

    const newPartieVisiteeSOComposant = { ...partieVisiteeSOComposant }

    // Remove old selection from set
    if (newPartieVisiteeSOComposant.composantId) {
      dispatchSelectedSets({
        type: "remove",
        key: "composants",
        data: newPartieVisiteeSOComposant.composantId,
      })
    }

    newPartieVisiteeSOComposant.composantId = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeComposant",
      data: newPartieVisiteeSOComposant,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
    })

    if (newPartieVisiteeSOComposant.composantId) {
      dispatchSelectedSets({
        type: "add",
        key: "composants",
        data: newPartieVisiteeSOComposant.composantId,
      })
    }
  }

  const addElement = () => {
    dispatchReport({
      type: "addPartieVisiteeElement",
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      data: {
        elementId: "",
        commentaires: [],
        noteIQOA: 0,
      },
    })
  }

  const removePartieVisiteeComposant = () => {
    if (partieVisiteeSOComposant.composantId) {
      dispatchSelectedSets({
        type: "remove",
        key: "composants",
        data: partieVisiteeSOComposant.composantId,
      })
    }

    dispatchReport({
      type: "removePartieVisiteeComposant",
      partieVisiteeIndex: partieVisiteeIndex,
      partieVisiteeComposantIndex: composantIndex,
    })
  }

  if (!composants) {
    console.warn(
      "[ReportPartieVisiteeComposantSection] composants empty or undefined",
      {
        composants,
      }
    )
    return <></>
  }

  return (
    <Accordion className="custom">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`${
              partieVisiteeSOComposant.composantId
                ? `Composant : ${
                    partieVisiteeSOComposant.composantId.split("/")[1]
                  }`
                : "Composant : "
            } (${partieVisiteeSOComposant.elements.length})`}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Container fluid>
              <Row className="custom">
                <Col className={"flex form-col"}>
                  <Form.Label className={"form-label"}>
                    <p>Composant :</p>
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={composantOnChange}
                    defaultValue={partieVisiteeSOComposant.composantId}
                  >
                    <option value="">Sélectionner un Composant</option>
                    {composants
                      .filter(
                        (composant) =>
                          composant.id ===
                            partieVisiteeSOComposant.composantId ||
                          !(
                            selectedSets["so"].has(parentSousOuvragesId) &&
                            selectedSets["composants"].has(composant.id)
                          )
                      )
                      .map((composant) => (
                        <option value={composant.id} key={composant.id}>
                          {composant.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Button
                    variant={"link"}
                    className="remove-sub-button flex-button"
                    onClick={removePartieVisiteeComposant}
                  >
                    <Icon icon="remove_circle_outline" />
                    Supprimer le composant
                  </Button>
                </Col>
              </Row>
            </Container>

            {partieVisiteeSOComposant.composantId ? (
              <>
                <Button
                  className="add-sub-button"
                  variant={"link"}
                  onClick={addElement}
                >
                  <h5 className="title-button">Éléments</h5>
                  <Icon icon="add_circle_outline" />
                </Button>
                {partieVisiteeSOComposant.elements.map(
                  (partieVisiteeSOElement, elementIndex) => (
                    <ReportEditionPartieVisiteeElementSection
                      project={project}
                      parentSousOuvragesId={parentSousOuvragesId}
                      parentComposantId={partieVisiteeSOComposant.composantId}
                      partieVisiteeSOElement={partieVisiteeSOElement}
                      partieVisiteeIndex={partieVisiteeIndex}
                      composantIndex={composantIndex}
                      elementIndex={elementIndex}
                      key={`ReportEditionPartieVisiteeElementSection-${partieVisiteeIndex}-${composantIndex}-${elementIndex}`}
                      elements={
                        composants.find(
                          (composant) =>
                            composant.id ===
                            partieVisiteeSOComposant.composantId
                        )?.elements
                      }
                    />
                  )
                )}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportEditionPartieVisiteeElementSection: FunctionComponent<
  ReportEditionPartieVisiteeElementSectionProps
> = ({
  project,
  parentSousOuvragesId,
  parentComposantId,
  partieVisiteeSOElement,
  partieVisiteeIndex,
  composantIndex,
  elementIndex,
  elements,
}) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)
  const { selectedSets, dispatchSelectedSets } = useContext(
    SelectedSetsReducerDispatch
  )

  const initSet = useCallback(() => {
    if (partieVisiteeSOElement.elementId) {
      dispatchSelectedSets({
        type: "add",
        key: "composants",
        data: partieVisiteeSOElement.elementId,
      })
    }
  }, [dispatchSelectedSets, partieVisiteeSOElement])

  useEffect(() => {
    initSet()
  }, [initSet])

  const elementOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value === partieVisiteeSOElement.elementId) {
      return
    }

    const newPartieVisiteeSOElement = { ...partieVisiteeSOElement }

    if (newPartieVisiteeSOElement.elementId) {
      dispatchSelectedSets({
        type: "remove",
        key: "elements",
        data: newPartieVisiteeSOElement.elementId,
      })
    }

    newPartieVisiteeSOElement.elementId = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeElement",
      data: newPartieVisiteeSOElement,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
    })

    if (newPartieVisiteeSOElement.elementId) {
      dispatchSelectedSets({
        type: "add",
        key: "elements",
        data: newPartieVisiteeSOElement.elementId,
      })
    }
  }

  const noteIQOAOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    let parsedValue

    if (Number.isNaN(e.target.value)) {
      parsedValue = 0
    } else {
      parsedValue = parseInt(e.target.value)
    }

    if (parsedValue === partieVisiteeSOElement.noteIQOA) {
      return
    }

    const newPartieVisiteSOElement = { ...partieVisiteeSOElement }

    newPartieVisiteSOElement.noteIQOA = parsedValue
    dispatchReport({
      type: "replacePartieVisiteeElement",
      data: newPartieVisiteSOElement,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
    })
  }

  const addCommentaire: MouseEventHandler<HTMLButtonElement> = () => {
    dispatchReport({
      type: "addPartieVisiteeElementCommentaire",
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
      data: {
        attachedFiles: [],
      },
    })
  }

  const removeElement = () => {
    if (partieVisiteeSOElement.elementId) {
      dispatchSelectedSets({
        type: "remove",
        key: "composants",
        data: partieVisiteeSOElement.elementId,
      })
    }

    dispatchReport({
      type: "removePartieVisiteeElement",
      partieVisiteeIndex: partieVisiteeIndex,
      partieVisiteeComposantIndex: composantIndex,
      partieVisiteeElementIndex: elementIndex,
    })
  }

  if (!elements) {
    console.warn(
      "[ReportEditionPartieVisiteeElementSection] elements empty or undefined",
      {
        elements,
      }
    )
    return <></>
  }

  return (
    <Accordion className="custom">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`${
              partieVisiteeSOElement.elementId
                ? `Élément : ${partieVisiteeSOElement.elementId.split("/")[2]}`
                : "Élément : "
            } (${partieVisiteeSOElement.commentaires.length || 0})`}
            {report && report.type <= 4 ? (
              <BadgeIQOA note={partieVisiteeSOElement.noteIQOA} />
            ) : null}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Container fluid>
              <Row className="custom">
                <Col className={"flex form-col"}>
                  <Form.Label className={"element-label"}>
                    <p>Élément :</p>
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={elementOnChange}
                    defaultValue={partieVisiteeSOElement.elementId}
                  >
                    <option value="">Sélectionner un Élément</option>
                    {elements
                      .filter(
                        (element) =>
                          element.id === partieVisiteeSOElement.elementId ||
                          !(
                            selectedSets["so"].has(parentSousOuvragesId) &&
                            selectedSets["composants"].has(parentComposantId) &&
                            selectedSets["elements"].has(element.id)
                          )
                      )
                      .map((element) => (
                        <option value={element.id} key={element.id}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Select>
                  {report && report.type <= 4 ? (
                    <div className={"iqoa-select-container"}>
                      <Form.Select
                        required={
                          report.type == 2 ||
                          report.type == 3 ||
                          report.type == 4
                        }
                        onChange={noteIQOAOnChange}
                        defaultValue={partieVisiteeSOElement.noteIQOA}
                      >
                        <option value="">Note IQOA</option>
                        {NOTE_IQOA_VALUES.map(({ label, value }) => {
                          return (
                            <option value={value} key={value}>
                              {label}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <Button
                    variant={"link"}
                    className="remove-sub-button flex-button"
                    onClick={removeElement}
                  >
                    <Icon icon="remove_circle_outline" />
                    Supprimer l'élément
                  </Button>
                </Col>
              </Row>
            </Container>

            {partieVisiteeSOElement.elementId ? (
              <>
                <Button
                  className="add-sub-button"
                  variant={"link"}
                  onClick={addCommentaire}
                >
                  <h5 className={"title-button"}>Commentaires</h5>
                  <Icon icon="add_circle_outline" />
                </Button>
                {partieVisiteeSOElement.commentaires.map(
                  (commentaire, elementCommentaireIndex) => (
                    <ReportEditionPartieVisiteeElementCommentaire
                      project={project}
                      partieVisiteeIndex={partieVisiteeIndex}
                      composantIndex={composantIndex}
                      elementIndex={elementIndex}
                      elementCommentaireIndex={elementCommentaireIndex}
                      key={`ReportEditionPartieVisiteeElementCommentaire-${partieVisiteeIndex}-${composantIndex}-${elementIndex}-${elementCommentaireIndex}`}
                      commentaire={commentaire}
                    />
                  )
                )}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportEditionPartieVisiteeElementCommentaire: FunctionComponent<
  ReportEditionPartieVisiteeElementCommentaireProps
> = ({
  project,
  partieVisiteeIndex,
  composantIndex,
  elementIndex,
  elementCommentaireIndex,
  commentaire,
}) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)

  const titreOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCommentaire = { ...commentaire }
    newCommentaire.titre = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeElementCommentaire",
      data: newCommentaire,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
      elementCommentaireIndex: elementCommentaireIndex,
    })
  }

  const constatationOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCommentaire = { ...commentaire }
    newCommentaire.constatation = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeElementCommentaire",
      data: newCommentaire,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
      elementCommentaireIndex: elementCommentaireIndex,
    })
  }

  const interpretationOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCommentaire = { ...commentaire }
    newCommentaire.interpretation = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeElementCommentaire",
      data: newCommentaire,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
      elementCommentaireIndex: elementCommentaireIndex,
    })
  }

  const localisationOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCommentaire = { ...commentaire }
    newCommentaire.localisation = e.target.value
    dispatchReport({
      type: "replacePartieVisiteeElementCommentaire",
      data: newCommentaire,
      partieVisiteeIndex: partieVisiteeIndex,
      composantIndex: composantIndex,
      elementIndex: elementIndex,
      elementCommentaireIndex: elementCommentaireIndex,
    })
  }

  const removeCommentaire = () => {
    dispatchReport({
      type: "removePartieVisiteeElementCommentaire",
      partieVisiteeIndex: partieVisiteeIndex,
      partieVisiteeComposantIndex: composantIndex,
      partieVisiteeElementIndex: elementIndex,
      elementCommentaireIndex: elementCommentaireIndex,
    })
  }

  if (!commentaire) {
    console.warn(
      "[ReportEditionPartieVisiteeElementCommentaire] commentaire empty or undefined",
      {
        commentaire,
      }
    )
    return <></>
  }

  const getCommentTitle = (
    comment: PartieVisiteeSousOuvrageComposantElementCommentaire
  ) => {
    const maxLength = 100
    if (comment.titre) {
      const title = comment.titre.split("\n")[0]
      if (title.length > maxLength + 3)
        return title.substring(0, maxLength) + "..."
      else return title
    } else {
      return "Commentaire"
    }
  }

  return (
    <Accordion className="custom">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {commentaire.attachedFiles && commentaire.attachedFiles.length > 0
              ? `Commentaire : ${getCommentTitle(commentaire)} (${
                  commentaire.attachedFiles?.length
                })`
              : `Commentaire : ${getCommentTitle(commentaire)}`}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Button
              variant={"link"}
              className="remove-sub-button flex-button"
              onClick={removeCommentaire}
            >
              <Icon icon="remove_circle_outline" />
              Supprimer commentaire
            </Button>

            <Container fluid>
              <Row className="custom">
                <Col className={"form-col"}>
                  <Form.Control
                    value={commentaire.titre || ""}
                    placeholder={"Titre"}
                    onChange={titreOnChange}
                    as={"textarea"}
                  />
                </Col>
                {report && report.type <= 4 ? (
                  <>
                    <Col className={"form-col"}>
                      <Form.Control
                        value={commentaire.constatation || ""}
                        placeholder={"Constatation"}
                        onChange={constatationOnChange}
                        as={"textarea"}
                      />
                    </Col>
                    <Col className={"form-col"}>
                      <Form.Control
                        value={commentaire.interpretation || ""}
                        placeholder={"Interprétation"}
                        onChange={interpretationOnChange}
                        as={"textarea"}
                      />
                    </Col>
                  </>
                ) : null}
                <Col className={"form-col"}>
                  <Form.Control
                    value={commentaire.localisation || ""}
                    placeholder={"Localisation"}
                    onChange={localisationOnChange}
                    as={"textarea"}
                  />
                </Col>
              </Row>
              <Row className="custom">
                <Col className="form-col">
                  <ReportEditionDropZone
                    project={project}
                    isElement={true}
                    sousOuvrageIndex={partieVisiteeIndex}
                    composantIndex={composantIndex}
                    elementIndex={elementIndex}
                    commentaireIndex={elementCommentaireIndex}
                    dispatch={dispatchReport}
                  />
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export default ReportEditionPartiesVisitees

import Icon from "@mui/material/Icon"
import { FunctionComponent } from "react"

export interface IconProps {
  icon: string
  className?: string
}

const I: FunctionComponent<IconProps> = ({ icon, className }) => {
  return (
    <Icon
      baseClassName="material-icons-outlined"
      className={`icon ${className}`}
    >
      {icon}
    </Icon>
  )
}

export default I

import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react"
import { AddPermissionRow } from "../../components/PermissionRow/PermisisonRow"
import {
  getMainAPI,
  Permission,
  PermissionType,
  Project,
} from "../../utils/api"
import { ProjectAdminPermissions } from "../ProjectAdminView/ProjectAdminView"

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const AdminView: FunctionComponent<any> = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [projects, setProjects] = useState<Project[]>()
  const [perms, setPerms] = useState<Permission[]>([])
  const [isNeedUpdate, setIsNeedUpdate] = useState(true)

  useEffect(() => {
    if (isNeedUpdate) {
      setIsLoading(true)
      setIsNeedUpdate(false)
      getMainAPI()
        .getAdminProjects()
        .then((p) => {
          setProjects(p)

          let promises = p.map((project) =>
            getMainAPI().getAllProjectPermissions(project.id)
          )

          Promise.all(promises)
            .then((responses) => {
              let p: Permission[] = []
              responses.forEach((arr) => {
                p = p.concat(arr)
              })
              setPerms(p)
              setIsLoading(false)
            })
            .catch((err) => {
              console.error(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }
  }, [setIsLoading, isNeedUpdate])

  if (isLoading) return <CircularProgress />

  let c = 0
  let i = 0

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        position: "fixed",
        height: "95%",
        width: "100%",
      }}
    >
      <Tabs
        orientation="vertical"
        value={selectedTab}
        variant="scrollable"
        onChange={handleChange}
        aria-label="admin tabs"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {projects?.map((project) => {
          c++
          return (
            <Tab key={project.id} label={project.name} {...a11yProps(c - 1)} />
          )
        })}
      </Tabs>
      {projects?.map((project) => {
        i++
        const filteredPerms =
          !isLoading && perms
            ? perms.filter((perm) => perm.resource?.id == project.id)
            : []
        return (
          <TabPanel key={project.id} value={selectedTab} index={i - 1}>
            <Box>
              <Box sx={{ backgroundColor: "white", padding: "0.5em" }}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <AddPermissionRow
                    projectId={project.id}
                    projectName={project.name}
                    permissions={filteredPerms}
                    setIsNeedUpdate={setIsNeedUpdate}
                  />
                )}
              </Box>
              <hr />
              <ProjectAdminPermissions
                projectId={project.id}
                label="Project"
                permissions={filteredPerms}
                kind={PermissionType.project}
                setIsNeedUpdate={setIsNeedUpdate}
              />
            </Box>
          </TabPanel>
        )
      })}
    </Box>
  )
}

import { getGedAPI, SignedUrlInfo } from "./api"

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const downloadFile = (
  projectId: string,
  fileName: string,
  filePath: string
) => {
  getGedAPI()
    .getDownloadUrl(projectId, filePath)
    .then((response) => {
      const { url } = response as SignedUrlInfo
      const a = document.createElement("a")
      a.href = url
      a.download = fileName
      a.target = "_blank"
      a.rel = "noopener noreferrer"
      a.click()
    })
    .catch((error) => {
      console.error(error)
    })
}

import { url } from "gravatar"
import moment from "moment"
import { FormEventHandler, FunctionComponent, useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { useHistory } from "react-router-dom"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import Icon from "../../components/Icon/Icon"
import Loader from "../../components/Loader/Loader"
import { Report } from "../../types/report"
import {
  getGedAPI,
  getMainAPI,
  PostReportBody,
  Project,
  Reports,
} from "../../utils/api"
import { generateReportFolderPath, REPORT_TYPES } from "../../utils/report"

interface GetAllReportsViewBaseProps {
  project: Project
}

export interface GetAllReportsViewProps extends GetAllReportsViewBaseProps {}

export interface ReportCreationFormProps extends GetAllReportsViewBaseProps {
  onReportCreated: () => void
}

export interface ReportsListProps extends GetAllReportsViewBaseProps {
  reports: Reports
  isLoading: boolean
}

export interface ReportCreationModalProps extends GetAllReportsViewBaseProps {
  onReportCreated: () => void
  show: boolean
  handleClose: () => void
}

export interface CreateNewIndiceProps {
  project: Project
  report: Report
}

const GetAllReportsView: FunctionComponent<GetAllReportsViewProps> = ({
  project,
}) => {
  const [reports, setReports] = useState<Reports>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const loadReports = () => {
    getMainAPI()
      .getReports(project.id)
      .then((response) => {
        const reportState: Report[] = []
        const ids: string[] = []
        response.reports.forEach((rep) => {
          if (!ids.includes(rep.id)) ids.push(rep.id)
        })

        ids.forEach((id) => {
          let filteredReports: Report[] = response.reports.filter(
            (rep) => rep.id == id
          )
          filteredReports = filteredReports.sort((a, b) =>
            a.indice.localeCompare(b.indice)
          )
          filteredReports[filteredReports.length - 1].indicesReports =
            filteredReports
          reportState.push(filteredReports[filteredReports.length - 1])
        })

        setReports(reportState)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(loadReports, [project])

  return (
    <div className="get-all-reports-view">
      <div className="get-all-reports-view__header">
        <h5 className="mb-0">{project.name}</h5>
        {project.role == "owner" ? (
          <Button
            variant="link"
            className="create-report-button"
            onClick={() => setShowModal(true)}
          >
            <Icon icon="note_add" />
            <span>Nouveau</span>
          </Button>
        ) : null}
      </div>
      <ReportsList project={project} isLoading={isLoading} reports={reports} />
      <ReportCreationModal
        project={project}
        onReportCreated={loadReports}
        handleClose={() => setShowModal(false)}
        show={showModal}
      />
    </div>
  )
}

const ReportsList: FunctionComponent<ReportsListProps> = ({
  project,
  reports,
  isLoading,
}) => {
  const history = useHistory()

  if (isLoading) {
    return (
      <div className="reports-loader">
        <Loader fullscreen />
      </div>
    )
  }

  return (
    <div className="reports">
      <div className="reports__list">
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Titre</th>
              <th>Type</th>
              <th>Indice</th>
              {/* <th>Date prévisionnelle</th> */}
              <th>Intervenant(s)</th>
              <th>Soumis par</th>
              <th>Date</th>
              <th>Note IQOA</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, key) => {
              // const isLate = !report.isSubmitted && report.dueDate < now
              return (
                <tr key={key}>
                  <td className="is-submitted">
                    <div>
                      {report.isSubmitted ? <Icon icon="check" /> : null}
                    </div>
                  </td>
                  <td className={"title"}>
                    <span
                      id={report.id}
                      onClick={() => {
                        history.push(
                          `/project/${project.id}/report/${report.id}`
                        )
                      }}
                    >
                      {report.title}
                    </span>
                  </td>
                  <td className={"type"}>
                    {report.type != undefined
                      ? REPORT_TYPES[report.type].name
                      : undefined}
                  </td>
                  <td className={"indice"}>{report.indice}</td>
                  <td className="intervenants">
                    {report.isSubmitted
                      ? report.form.intervenants?.split("\n")[0]
                      : ""}
                  </td>
                  <td className={"submittedPer"}>
                    {report.submitterEmail ? (
                      <UserDetails email={report.submitterEmail} />
                    ) : null}
                  </td>
                  <td className={`submission-date`}>
                    {report.form.endDate && report.isSubmitted
                      ? `${moment(report.form.endDate).format("ll")}`
                      : null}
                  </td>
                  <td>
                    {report.type <= 4 && report.isSubmitted ? (
                      <h5>
                        <BadgeIQOA note={report.form.noteIQOA} />
                      </h5>
                    ) : null}
                  </td>
                  {/* <td>
                    <LinkContainer
                      to={`/project/${report.projectId}/report/${report.id}`}
                    >
                      <Button variant="link">Consulter</Button>
                    </LinkContainer>
                  </td> */}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const ReportCreationModal: FunctionComponent<ReportCreationModalProps> = ({
  project,
  onReportCreated,
  show,
  handleClose,
}) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Body>
        <ReportCreationForm
          project={project}
          onReportCreated={() => {
            onReportCreated()
            handleClose()
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

const ReportCreationForm: FunctionComponent<ReportCreationFormProps> = ({
  project,
  onReportCreated,
}) => {
  const [title, setTitle] = useState("")
  const [type, setType] = useState<number>(-1)
  const [validated, setValidated] = useState(false)
  const [successMessages, setSuccessMessages] = useState<string[]>([])

  const submitReport: FormEventHandler<HTMLFormElement> = (event) => {
    const form = event.currentTarget

    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
      return
    }

    const body: PostReportBody = {
      title: title,
      type: type,
    }

    getMainAPI()
      .postReport(project.id, body)
      .then((response) => {
        if (response.id) {
          createReportGedFolders(
            project.id,
            response.title,
            response.id,
            response.type
          ).then((resp) => {
            if (resp == true) {
              setSuccessMessages([
                ...successMessages,
                `Le rapport ${title} a bien été créé.`,
              ])
            }
          })
        }
        onReportCreated()
      })
      .catch(console.error)
    setValidated(false)
    form.reset()
    // AVOID Default SUBMIT form browser behaviors
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <>
      <div className={"report-creation-form"}>
        <h1 className="mb-5">Créer une nouvelle fiche</h1>
        <Form noValidate validated={validated} onSubmit={submitReport}>
          <Form.Group className="mb-2">
            <Form.Label>Titre</Form.Label>
            <Form.Control
              name={"title"}
              placeholder="Titre"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Form.Text className="text-muted">
              e.g. INSPECTION_Chaussée_2030
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Type de fiche</Form.Label>
            <Form.Select onChange={(e) => setType(+e.target.value)} required>
              <option className={"blank-select"} disabled selected />
              {REPORT_TYPES.map((v, i) => {
                return (
                  <option key={`opt-${v.name}`} value={i}>
                    {v.name}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-5">
            <Button variant="success" size="lg" type="submit">
              Créer le rapport
            </Button>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}

export const UserDetails: FunctionComponent<{ email: string }> = ({
  email,
}) => {
  const username = email
  const avatar = url(email, { d: "identicon" })
  return (
    <div className="user-details">
      <img
        className="user-avatar rounded-circle me-2"
        src={avatar}
        alt="User Avatar"
      />
      {username}
    </div>
  )
}

export default GetAllReportsView

export const createReportGedFolders = (
  projectId: string,
  title: string,
  id: string,
  type: number,
  indice?: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const report_files_path = generateReportFolderPath(
      title,
      id,
      type,
      indice ? indice : "A"
    )
    const paths = report_files_path.split("/")
    if (!indice) {
      const reportFolder = "/" + REPORT_TYPES[type].path
      getGedAPI()
        .createFolder(projectId, reportFolder, paths[paths.length - 2])
        .then(() => {
          getGedAPI()
            .createFolder(
              projectId,
              `/${REPORT_TYPES[type].path}/${paths[paths.length - 2]}`,
              "INDICE-A"
            )
            .then(() => {
              getGedAPI()
                .adminFilesLock(projectId, [
                  `/${REPORT_TYPES[type].path}/${paths[paths.length - 2]}`,
                ])
                .then(() => {
                  resolve(true)
                })
            })
          resolve(true)
        })
    } else {
      getGedAPI()
        .createFolder(
          projectId,
          `/${REPORT_TYPES[type].path}/${paths[paths.length - 2]}`,
          "INDICE-" + indice
        )
        .then(() => resolve(true))
        .catch(() => reject(false))
    }
  })
}

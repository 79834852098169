import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useState } from "react"
import { useHistory } from "react-router-dom"
import { Report } from "../../types/report"
import { getGedAPI, getMainAPI } from "../../utils/api"
import { generateReportFolderPath } from "../../utils/report"

import { dialogTitleStyle, getDialogError, getDialogLoader } from "./commons"

export interface DialogBoxDeleteIndiceProps {
  projectId: string
  report: Report
  handleClose: () => void
}

export const DialogBoxDeleteIndice: FunctionComponent<
  DialogBoxDeleteIndiceProps
> = ({ projectId, report, handleClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const history = useHistory()

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
  }

  const strings = {
    dialogTitle: `Supprimer un indice`,
    confirm: `Voulez-vous vraiment supprimer l'indice ${report.indice} du rapport ${report.title} ?`,
    warns: `Cette action est irréversible et entraînera la suppression de l'ensemble des données liées à cet indice.`,
    buttonCancel: "Annuler",
    buttonCreate: "Confirmer",
  }

  const onDelete = () => {
    setIsLoading(true)
    getMainAPI()
      .deleteLastReportIndice(projectId, report.id)
      .then(() => {
        const path = generateReportFolderPath(
          report.title,
          report.id,
          report.type,
          report.indice
        )

        getGedAPI()
          .getFiles(projectId, path, false)
          .then((folder) => {
            getGedAPI()
              .deleteResource(projectId, folder)
              .then(() => {
                if (report.indice == "A") {
                  const parentReportPath = path
                    .split("/")
                    .slice(0, -1)
                    .join("/")
                  getGedAPI()
                    .adminFilesUnlock(projectId, ["/" + parentReportPath])
                    .then(() => {
                      getGedAPI()
                        .deleteResource(projectId, undefined, parentReportPath)
                        .then(() => {
                          history.push(`/project/${report.projectId}/reports`)
                        })
                    })
                }
                setIsLoading(false)
                handleClose()
              })
          })
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
        setError(true)
      })
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          {strings.confirm}
          <br />
          {strings.warns}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button onClick={onDelete}>{strings.buttonCreate}</Button>
        </DialogActions>
      </List>
    </div>
  )
}

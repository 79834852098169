import { ChartData, ChartOptions } from "chart.js"
import MarkdownIt from "markdown-it"
import moment from "moment"
import { FunctionComponent, useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { Line } from "react-chartjs-2"
import { LinkContainer } from "react-router-bootstrap"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import Loader from "../../components/Loader/Loader"
import {
  ExtendedElementWithinHierarchy,
  ExtendedHierarchy,
  ExtendedSousOuvrage,
  getMainAPI,
  Hierarchy,
  Project,
} from "../../utils/api"
import { toNoteIQOALabel } from "../../utils/noteIQOA"
import DashboardSumary, { computeSONote } from "./DashboardSummary"

const CURRENT_DATE = new Date()
const CHART_MIN_DATE = new Date().setFullYear(CURRENT_DATE.getFullYear() - 15)
const CHART_MAX_DATE = new Date().setFullYear(CURRENT_DATE.getFullYear() + 1)

const DASHBOARD_DESCRIPTION_OBJECT_ID = ".dashboard"
export const GLOBAL_SOUS_OUVRAGE: ExtendedSousOuvrage = {
  id: ".global",
  name: "Ouvrage complet",
  composants: [],
}

interface DashboardProps {
  project: Project
  hierarchy?: Hierarchy
}

type SelectedEntity = ExtendedSousOuvrage | ExtendedElementWithinHierarchy

const DashboardView: FunctionComponent<DashboardProps> = ({
  hierarchy,
  project,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [extendedHierarchy, setExtendedHierarchy] =
    useState<ExtendedHierarchy>()
  const [dashboardDescription, setDashboardDescription] = useState<string>()
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>()

  useEffect(() => {
    Promise.all([
      getMainAPI().getHierarchy(project.id, true),
      getMainAPI()
        .getObject(project.id, DASHBOARD_DESCRIPTION_OBJECT_ID)
        .catch(() => {
          console.error("No description found for object .dashboard")
          return undefined
        }),
    ])
      .then(([extendedHierarchy, object]) => {
        setExtendedHierarchy(extendedHierarchy)
        setDashboardDescription(object?.Description)
        setIsLoading(false)
      })
      .catch(() => {
        console.error("Could not load the extended hierarchy")
        setIsLoading(false)
      })
  }, [project])

  // const computeOuvrageNote = (
  //   extendedHierarchy: ExtendedHierarchy | undefined
  // ) => {
  //   let highestNote: number | undefined = undefined
  //   let hasS = false
  //   if (!extendedHierarchy) return highestNote
  //   extendedHierarchy?.sousOuvrages.forEach((so) => {
  //     const soNote = computeSONote(so)
  //     if (
  //       (highestNote && soNote && soNote > highestNote) ||
  //       (highestNote == undefined && soNote != undefined)
  //     )
  //       highestNote = soNote
  //     if (soNote && soNote % 2 == 0) hasS = true
  //   })

  //   if (highestNote && hasS && highestNote % 2 != 0) return highestNote + 1
  //   return highestNote
  // }

  if (isLoading) {
    return <Loader fullscreen />
  }

  const md = new MarkdownIt({
    html: false, // Enable HTML tags in source
    xhtmlOut: false, // Use '/' to close single tags (<br />).
    breaks: false, // Convert '\n' in paragraphs into <br>
    linkify: false, // Autoconvert URL-like text to links
    typographer: true, // Enable some language-neutral replacement + quotes beautification
    quotes: "“”‘’", // Double + single quotes replacement pairs, when typographer enabled
  })
  const renderedMd = md.render(dashboardDescription || "")

  if (!hierarchy?.sousOuvrages[0]) {
    return (
      <div className="dashboard-view">
        <div className="dashboard-view__header">
          <h5 className="mb-0">{project.name} - Bilan de santé</h5>
        </div>

        <div className="dashboard-view__content">
          Erreur lors de la récupération des informations sur le sous-ouvrage.
          Veuillez contacter l'administrateur.
        </div>
      </div>
    )
  }

  return (
    <div className="dashboard-view">
      <div className="dashboard-view__header">
        <h5 className="mb-0">{project.name} - Bilan de santé</h5>
      </div>

      <div className="dashboard-view__content">
        <div className="note-iqoa-global">
          <h1>
            Classe IQOA:{" "}
            {extendedHierarchy ? (
              <BadgeIQOA note={computeOuvrageNote(extendedHierarchy)} />
            ) : null}
          </h1>
        </div>
        <div className="info-global">
          <span
            className={"markdown-text"}
            dangerouslySetInnerHTML={{ __html: renderedMd }}
          />
        </div>
        <div className="break" />
        <DashboardSumary
          hierarchy={extendedHierarchy || hierarchy}
          onSousOuvrageSelected={setSelectedEntity}
          onElementSelected={setSelectedEntity}
        />
        <div className="break" />

        {selectedEntity ? (
          <>
            <ModelDetails selection={selectedEntity} projectId={project.id} />
            <IQOANotesChart
              projectId={project.id}
              selection={selectedEntity?.id}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

const getChartData = (projectId: string, selectedSO: string) => {
  return getMainAPI()
    .getModelReports(projectId, selectedSO)
    .then(({ reports }) => {
      return reports
        .filter((r) => r.noteIQOA)
        .sort((r1, r2) => r1.endDate.localeCompare(r2.endDate))
        .map((r) => ({ x: Date.parse(r.endDate), y: r.noteIQOA! }))
    })
}

// const getChartData = (projectId: string, selectedSO: string) => {
//   return getMainAPI()
//     .getModelReports(projectId, selectedSO)
//     .then(({ reports }) => {
//       return reports
//         .filter((r) => r.noteIQOA)
//         .sort((r1, r2) => r1.dateInspection.localeCompare(r2.dateInspection))
//         .map((r) => ({ x: Date.parse(r.dateInspection), y: r.noteIQOA! }))
//     })
// }

const IQOANotesChart: FunctionComponent<{
  projectId: string
  selection: string
}> = ({ projectId, selection }) => {
  const [data, setData] = useState<Array<{ x: number; y: number }>>([])

  useEffect(() => {
    getChartData(projectId, selection).then(setData)
  }, [projectId, selection])

  const chartData: ChartData<"line"> = {
    datasets: [
      {
        data: data,
        fill: true,
        borderWidth: 2,
        borderColor: "rgb(24, 131, 61)",
        pointRadius: 4,
        tension: 0.1,
        spanGaps: true,
      },
    ],
  }
  const options: ChartOptions<"line"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (item) => {
            return toNoteIQOALabel(item.parsed.y)
          },
        },
      },
    },
    scales: {
      x: {
        min: CHART_MIN_DATE,
        max: CHART_MAX_DATE,
        type: "time",
        time: {
          unit: "year",
          tooltipFormat: "ll",
        },
      },
      y: {
        min: 1,
        max: 10,
        reverse: true,
        ticks: {
          stepSize: 1,
          callback: (value) => toNoteIQOALabel(value as number),
        },
      },
    },
  }
  return (
    <Card className="iqoa-notes-chart">
      <Card.Header className="iqoa-notes-chart__header">
        <span>Évolution des notes IQOA</span>
      </Card.Header>
      <Card.Body>
        <Line data={chartData} options={options} />
      </Card.Body>
    </Card>
  )
}

const ModelDetails: FunctionComponent<{
  selection: SelectedEntity
  projectId: string
}> = ({ selection, projectId }) => {
  if (!selection.excerpt) {
    return (
      <Card className="model-details bg-light">
        <Card.Header>
          <span>Dernière inspection</span>
        </Card.Header>
      </Card>
    )
  }
  return (
    <Card className="model-details bg-light">
      <Card.Header>
        <span>Dernière inspection</span>
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <h2>{selection.name}</h2>
          <h2 className="ms-auto">
            <BadgeIQOA note={selection.excerpt.noteIQOA} />
          </h2>
        </div>
        <div>Inspecté {moment(selection.excerpt.endDate).fromNow()}</div>
        {/* <div>Inspecté {moment(selection.excerpt.dateInspection).fromNow()}</div> */}
        <div className="comment">
          <span>
            {selection.excerpt.commentaireNoteIQOA || "Pas de commentaire"}
          </span>
        </div>
        <div className="mt-auto ms-auto">
          <LinkContainer
            to={`/project/${projectId}/report/${selection.excerpt.id}`}
          >
            <a>Consulter la fiche d'inspection</a>
          </LinkContainer>
        </div>
      </Card.Body>
    </Card>
  )
}

export const computeOuvrageNote = (
  extendedHierarchy: ExtendedHierarchy | undefined
) => {
  let highestNote: number | undefined = undefined
  let hasS = false
  if (!extendedHierarchy) return highestNote
  extendedHierarchy?.sousOuvrages.forEach((so) => {
    const soNote = computeSONote(so)
    if (
      (highestNote && soNote && soNote > highestNote) ||
      (highestNote == undefined && soNote != undefined)
    )
      highestNote = soNote
    if (soNote && soNote % 2 == 0) hasS = true
  })

  if (highestNote && hasS && highestNote % 2 != 0) return highestNote + 1
  return highestNote
}

export default DashboardView

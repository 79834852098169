import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { Folder } from "../../utils/api"
import { UPLOAD_FILE_ACTION, UPLOAD_FOLDER_ACTION } from "./commons"

export interface GedUploadProps {
  projectId: string
  folder?: Folder
  handleDialogOpen: (action: string) => void
}

export const GedUpload: FunctionComponent<GedUploadProps> = ({
  projectId,
  folder,
  handleDialogOpen,
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const strings = {
    upload: "Upload",
    file: "Fichier",
    folder: "Dossier",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const on_file = (event: Event | React.SyntheticEvent) => {
    handleClose(event)
    handleDialogOpen(UPLOAD_FILE_ACTION)
    folder = folder as Folder
  }

  const on_folder = (event: Event | React.SyntheticEvent) => {
    handleClose(event)
    handleDialogOpen(UPLOAD_FOLDER_ACTION)
    folder = folder as Folder
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  if (!projectId) return null

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ m: "0.3em" }}>
        <Tooltip
          disableHoverListener={folder?.permissions?.canEdit}
          title={folder?.permissions?.canEdit ? false : strings.unauthorized}
          arrow
          placement="bottom"
        >
          <span>
            <Button
              startIcon={<CloudUploadIcon />}
              ref={anchorRef}
              variant="outlined"
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disabled={!folder?.permissions?.canEdit}
            >
              {strings.upload}
            </Button>
          </span>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: 10,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={on_file}>{strings.file}</MenuItem>
                    <MenuItem onClick={on_folder}>{strings.folder}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Stack>
  )
}

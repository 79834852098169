import AccountCircle from "@mui/icons-material/AccountCircle"
import AddIcon from "@mui/icons-material/Add"
import DoneIcon from "@mui/icons-material/Done"
import PersonRemoveIcon from "@mui/icons-material/PersonRemove"
import { FormControl } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { Report } from "../../types/report"
import {
  getGedAPI,
  getMainAPI,
  GetProjectUsersResponseUser,
  Permission,
  PermissionType,
  Principal,
  Resource,
} from "../../utils/api"
import { getDialogLoader } from "../DialogBoxes/commons"

export interface AddPermissionRowProps {
  id?: string
  projectId: string
  projectName: string
  permissions?: Permission[]
  setIsNeedUpdate?: (b: boolean) => void
}

export const AddPermissionRow: FunctionComponent<AddPermissionRowProps> = ({
  // id,
  projectId,
  projectName,
  permissions,
  setIsNeedUpdate,
}) => {
  const [input, setInput] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [roleInput, setRoleInput] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  const strings = {
    mail: "Email",
    addWarningTitle:
      "Ajouter l'utilisateur " + input + " au projet " + projectId,
    addWarningDesc:
      "Vous êtes sur le point d'ajouter un utilisateur au projet. S'il n'est pas encore membre d'un projet Stwin, celui recevra un courriel avec un mot de passe temporaire lui permettant de se connecter à la plateforme.",
    validate: "OK",
    close: "Annuler",
    role: "Role",
    owner: "Owner",
    editor: "Editor",
    viewer: "Viewer",
    member: "Member",
    addLabel: "Ajouter un utilisateur au projet " + projectName + " :",
  }

  const styles = {
    main: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      margin: "0.3%",
      borderRadius: "10px",
      padding: "0.5em",
    },
  }

  const onRoleChange = (event: SelectChangeEvent) => {
    setRoleInput(event.target.value)
  }

  const onAddUser = () => {
    setIsLoading(true)
    handleClose()
    getMainAPI()
      .addUserInProject(projectId, input, roleInput)
      .then(() => {
        setRoleInput("")
        setInput("")
        if (setIsNeedUpdate) setIsNeedUpdate(true)
        setIsLoading(false)
      })
      .catch((err) => {
        setRoleInput("")
        setInput("")
        setIsLoading(false)
        console.error(err)
      })
  }

  const isInputValid = () => {
    let b: boolean = true
    const re =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
    if (!re.exec(input)) return false
    permissions?.forEach((perm) => {
      if (perm.principal.id == input) b = false
    })
    return b
  }

  if (!permissions || isLoading) return getDialogLoader()

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {strings.addWarningTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {strings.addWarningDesc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {strings.close}
          </Button>
          <Button onClick={onAddUser} autoFocus>
            {strings.validate}
          </Button>
        </DialogActions>
      </Dialog>
      <h6>{strings.addLabel}</h6>
      <Box sx={styles.main}>
        <TextField
          fullWidth
          error={!isInputValid() && input != ""}
          id="outlined-basic"
          label={strings.mail}
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ width: "12em", marginLeft: "0.5em" }}>
          <InputLabel id="label-select-role-user">{strings.role}</InputLabel>
          <Select
            labelId="label-select-role-user"
            label={strings.role}
            id="select-role-user"
            value={roleInput}
            onChange={onRoleChange}
          >
            <MenuItem key="add-owner" value="owner">
              {strings.owner}
            </MenuItem>
            <MenuItem key="add-editor" value="editor">
              {strings.editor}
            </MenuItem>
            <MenuItem key="add-viewer" value="viewer">
              {strings.viewer}
            </MenuItem>
            <MenuItem key="add-member" value="member">
              {strings.member}
            </MenuItem>
          </Select>
        </FormControl>

        <Button
          disabled={!isInputValid() || roleInput == ""}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      </Box>
    </>
  )
}

export interface PermissionRowProps {
  id?: string
  projectId: string
  resource?: Resource | Report
  users?: GetProjectUsersResponseUser[]
  permission?: Permission
  addButton?: boolean
  saveButton?: boolean
  deleteButton?: boolean
  disableMail?: boolean
  defaultUser?: Principal
  disableRole?: boolean
  defaultRole?: string
  showResource?: boolean
  setIsNeedUpdate?: (b: boolean) => void
}

export const PermissionRow: FunctionComponent<PermissionRowProps> = ({
  // id,
  projectId,
  resource,
  permission,
  users,
  addButton,
  saveButton,
  deleteButton,
  defaultUser,
  disableMail,
  defaultRole,
  disableRole,
  showResource,
  setIsNeedUpdate,
}) => {
  const [userInput, setUserInput] = useState<string>(
    defaultUser ? defaultUser.id : ""
  )
  const [roleInput, setRoleInput] = useState<string>(
    defaultRole ? defaultRole : ""
  )

  const getDisplayName = () => {
    if (
      permission?.kind == PermissionType.file ||
      permission?.kind == PermissionType.folder
    )
      return permission?.resource?.path
    if (permission?.kind == PermissionType.report) {
      return permission?.resource?.name
    }
    return ""
  }

  const [resourceInput, setResourceInput] = useState<string>(
    getDisplayName() as string
  )

  const [isLoading, setIsLoading] = useState(false)

  const strings = {
    dialogTitle: "Permissions",
    mail: "Email",
    resource: "Resource",
    kindResource: (kind: PermissionType): string => {
      if (kind == PermissionType.file) {
        return "(Fichier) Resource"
      } else if (kind == PermissionType.folder) {
        return "(Dossier) Resource"
      } else if (kind == PermissionType.report) {
        return "(Inspection) Resource"
      }
      return "(Projet) Resource"
    },
    role: "Role",
    owner: "Owner",
    editor: "Editor",
    viewer: "Viewer",
    member: "Member",
    buttonCancel: "OK",
  }

  const onAddRole = () => {
    setIsLoading(true)
    if (resource?.name) {
      getGedAPI()
        .createGedResourcePermissions(
          projectId,
          resource?.id as string,
          userInput,
          roleInput
        )
        .then(() => {
          setUserInput("")
          setRoleInput("")
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    } else {
      getMainAPI()
        .createReportPermission(
          projectId,
          resource?.id as string,
          userInput,
          roleInput
        )
        .then(() => {
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    }
  }

  const onUpdateRole = () => {
    if (permission?.kind == PermissionType.project) {
      setIsLoading(true)
      getMainAPI()
        .updateProjectPermissions(projectId, permission.id, roleInput)
        .then(() => {
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    } else if (
      permission?.kind == PermissionType.file ||
      PermissionType.folder == permission?.kind
    ) {
      setIsLoading(true)
      getGedAPI()
        .updateGedResourcePermissions(
          projectId,
          permission?.resource?.id as string,
          permission?.id as string,
          roleInput
        )
        .then(() => {
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    } else if (permission?.kind == PermissionType.report) {
      setIsLoading(true)
      getMainAPI()
        .updateReportPermission(
          projectId,
          permission?.resource?.id as string,
          permission?.id as string,
          roleInput
        )
        .then(() => {
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    }
  }

  const onDeleteRole = () => {
    if (permission?.kind == PermissionType.project) {
      setIsLoading(true)
      getMainAPI()
        .deleteUserInProject(
          projectId,
          permission.principal.id,
          permission.role
        )
        .then(() => {
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    } else if (
      permission?.kind == PermissionType.file ||
      PermissionType.folder == permission?.kind
    ) {
      setIsLoading(true)
      getGedAPI()
        .deleteGedResourcePermissions(
          projectId,
          permission?.resource?.id as string,
          permission?.id as string
        )
        .then(() => {
          setUserInput("")
          setRoleInput("")
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    } else if (permission?.kind == PermissionType.report) {
      setIsLoading(true)
      getMainAPI()
        .deleteReportPermission(
          projectId,
          permission?.resource?.id as string,
          permission?.id as string
        )
        .then(() => {
          setUserInput("")
          setRoleInput("")
          if (setIsNeedUpdate) setIsNeedUpdate(true)
          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setIsLoading(false)
        })
    }
  }

  const isAddUserValid = () => {
    if (userInput != "" && roleInput != "") {
      return true
    }
    return false
  }

  const isUpdateRoleValid = () => {
    if (userInput != defaultUser?.id || roleInput != defaultRole) {
      return true
    }
    return false
  }

  const isDeleteRoleValid = () => {
    if (userInput != "" && roleInput != "") {
      return true
    }
    return false
  }

  const onUserChange = (event: SelectChangeEvent) => {
    setUserInput(event.target.value)
  }

  const onRoleChange = (event: SelectChangeEvent) => {
    setRoleInput(event.target.value)
  }

  const onResourceChange = (event: SelectChangeEvent) => {
    setResourceInput(event.target.value)
  }

  if (isLoading) return getDialogLoader()

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {showResource ? (
        <FormControl sx={{ width: "25em", margin: "0.5em" }}>
          <InputLabel id="label-select-resource">
            {strings.kindResource(permission?.kind as PermissionType)}
          </InputLabel>
          <Select
            disabled
            labelId="label-select-resource"
            label={strings.kindResource(permission?.kind as PermissionType)}
            id="select-project-user"
            value={`${resourceInput} - (${permission?.resource?.id})`}
            onChange={onResourceChange}
          >
            <MenuItem
              key={permission?.resource?.id}
              value={`${getDisplayName()} - (${permission?.resource?.id})`}
            >
              {`${getDisplayName()} - (${permission?.resource?.id})`}
            </MenuItem>
          </Select>
        </FormControl>
      ) : null}
      <FormControl sx={{ width: "25em", margin: "0.5em" }}>
        <InputLabel id="label-select-project-user">{strings.mail}</InputLabel>
        <Select
          disabled={disableMail}
          labelId="label-select-project-user"
          label={strings.mail}
          id="select-project-user"
          value={userInput}
          onChange={onUserChange}
        >
          {users?.map((user) => (
            <MenuItem key={user.email} value={user.email}>
              {user.email}
            </MenuItem>
          ))}
          {defaultUser ? (
            <MenuItem key={defaultUser.id} value={defaultUser.id}>
              {defaultUser.id}
            </MenuItem>
          ) : null}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "8em", margin: "0.5em" }}>
        <InputLabel id="label-select-role-user">{strings.role}</InputLabel>
        <Select
          disabled={disableRole}
          labelId="label-select-role-user"
          label={strings.role}
          id="select-role-user"
          value={roleInput}
          onChange={onRoleChange}
        >
          <MenuItem key="add-owner" value="owner">
            {strings.owner}
          </MenuItem>
          <MenuItem key="add-editor" value="editor">
            {strings.editor}
          </MenuItem>
          <MenuItem key="add-viewer" value="viewer">
            {strings.viewer}
          </MenuItem>
          {permission?.kind == "Project" ? (
            <MenuItem key="add-member" value="member">
              {strings.member}
            </MenuItem>
          ) : null}
        </Select>
      </FormControl>
      {addButton ? (
        <Button disabled={!isAddUserValid()} onClick={onAddRole}>
          <AddIcon />
        </Button>
      ) : null}
      {saveButton ? (
        <Button disabled={!isUpdateRoleValid()} onClick={onUpdateRole}>
          <DoneIcon />
        </Button>
      ) : null}
      {deleteButton ? (
        <Button disabled={!isDeleteRoleValid()} onClick={onDeleteRole}>
          <PersonRemoveIcon sx={{ color: "red" }} />
        </Button>
      ) : null}
    </Box>
  )
}

import { FunctionComponent } from "react"
import { Badge } from "react-bootstrap"
import { toNoteIQOALabel, toNoteIQOASeverity } from "../../utils/noteIQOA"

const BadgeIQOA: FunctionComponent<{
  note?: number
  className?: string
  showTitle?: boolean
}> = ({ note, className, showTitle }) => {
  const severity = toNoteIQOASeverity(note)
  return (
    <Badge
      bg={severity}
      className={`badge-iqoa ${className}`}
      text={severity == "warning" ? "dark" : undefined}
    >
      {showTitle
        ? `Classe IQUOA : ${toNoteIQOALabel(note)}`
        : toNoteIQOALabel(note)}
    </Badge>
  )
}

export default BadgeIQOA

const REPORT_FOLDER_PATH_PROTOTYPE =
  "{{REPORTS_PATH}}/{{REPORT_NAME}}-{{SLICED_REPORT_ID}}/INDICE-{{INDICE_REPORT}}"
const SLICE_REPORT_ID_SIZE = 3

const REPORT_PATH_PATROUILLAGE =
  "3- Vie de l'ouvrage/3-02 Dossier de surveillance/3-02-01 Patrouillages"
const REPORT_PATH_VISITE_ANNUELLE =
  "3- Vie de l'ouvrage/3-02 Dossier de surveillance/3-02-02 Visites annuelles"
const REPORT_PATH_VISITE_EVALUATION =
  "3- Vie de l'ouvrage/3-02 Dossier de surveillance/3-02-03 Visites d'évaluation"
const REPORT_PATH_INSPECTION_DETAILLE =
  "3- Vie de l'ouvrage/3-02 Dossier de surveillance/3-02-04 Inspections détaillées périodiques & exceptionnelles"
const REPORT_PATH_ENTRETIEN_COURANT =
  "3- Vie de l'ouvrage/3-05 Dossier des opérations ne justifiant pas un nouvel état de référence/3-05-01 Entretien courant"
const REPORT_PATH_ENTRETIEN_SPECIALISE =
  "3- Vie de l'ouvrage/3-05 Dossier des opérations ne justifiant pas un nouvel état de référence/3-05-02 Entretien spécialisé"
const REPORT_PATH_REPARATION =
  "3- Vie de l'ouvrage/3-05 Dossier des opérations ne justifiant pas un nouvel état de référence/3-05-03 Réparations"
const REPORT_PATH_INVESTIGATION =
  "3- Vie de l'ouvrage/3-05 Dossier des opérations ne justifiant pas un nouvel état de référence/3-05-04 Investigations"

export const REPORT_TYPES = [
  // Inspections
  {
    name: "Inspection (patrouillage)",
    shortName: "Patrouillage",
    path: REPORT_PATH_PATROUILLAGE,
  },
  {
    name: "Inspection (visite annuelle)",
    shortName: "Visite annuelle",
    path: REPORT_PATH_VISITE_ANNUELLE,
  },
  {
    name: "Inspection (visite d'évaluation)",
    shortName: "Visite d'évaluation",
    path: REPORT_PATH_VISITE_EVALUATION,
  },
  {
    name: "Inspection (détaillée périodique)",
    shortName: "Détaillé périodique",
    path: REPORT_PATH_INSPECTION_DETAILLE,
  },
  {
    name: "Inspection (détaillée exceptionnelle)",
    shortName: "Détaillée exceptionnelle",
    path: REPORT_PATH_INSPECTION_DETAILLE,
  },
  // Interventions
  {
    name: "Intervention (entretien courant)",
    shortName: "Entretien courant",
    path: REPORT_PATH_ENTRETIEN_COURANT,
  },
  {
    name: "Intervention (entretien spécialisé)",
    shortName: "Entretien spécialisé",
    path: REPORT_PATH_ENTRETIEN_SPECIALISE,
  },
  {
    name: "Intervention (réparation)",
    shortName: "Réparation",
    path: REPORT_PATH_REPARATION,
  },
  {
    name: "Intervention (investigation)",
    shortName: "Investigation",
    path: REPORT_PATH_INVESTIGATION,
  },
]

export const generateReportFolderPath = (
  reportName: string,
  reportId: string,
  reportType: number,
  indice: string
): string =>
  REPORT_FOLDER_PATH_PROTOTYPE.replace(
    "{{REPORTS_PATH}}",
    REPORT_TYPES[reportType].path
  )
    .replace("{{REPORT_NAME}}", reportName)
    .replace(
      "{{SLICED_REPORT_ID}}",
      reportId.slice(reportId.length - SLICE_REPORT_ID_SIZE)
    )
    .replace("{{INDICE_REPORT}}", indice)

export const getNextIndice = function (indice: any): string {
  if (indice === "Z" || indice === "z") {
    return (
      String.fromCharCode(indice.charCodeAt() - 25) +
      String.fromCharCode(indice.charCodeAt() - 25)
    ) // AA or aa
  } else {
    var lastChar = indice.slice(-1)
    var sub = indice.slice(0, -1)
    if (lastChar === "Z" || lastChar === "z") {
      // If a string of length > 1 ends in Z/z,
      // increment the string (excluding the last Z/z) recursively,
      // and append A/a (depending on casing) to it
      return (
        getNextIndice(sub) + String.fromCharCode(lastChar.charCodeAt() - 25)
      )
    } else {
      // (take till last char) append with (increment last char)
      return sub + String.fromCharCode(lastChar.charCodeAt() + 1)
    }
  }
}

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import TextField from "@mui/material/TextField"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { Folder, getGedAPI, Resource } from "../../utils/api"
import {
  dialogTitleStyle,
  getDialogError,
  getDialogLoader,
  getReadablePath,
  isValidName,
} from "./commons"

export interface DialogBoxCreateProps {
  projectId: string
  dstFolder: Folder
  handleClose: () => void
  setFile?: (resource: Resource) => void
}

export const DialogBoxCreate: FunctionComponent<DialogBoxCreateProps> = ({
  projectId,
  dstFolder,
  handleClose,
  setFile,
}) => {
  const [input, setInput] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
    destinationTitle: { fontWeight: "bold" },
    destination: { backgroundColor: "#edeff2", textAlign: "center" },
  }

  const strings = {
    dialogTitle: "Créer un nouveau dossier",
    buttonCancel: "Annuler",
    buttonCreate: "Créer",
    destination: "Destination",
    helperText:
      "Votre nom de dossier doit respecter ces règles: 1 à 255 caractères, doit être unique et ne pas comporter les caractères suivants: / \\ : { } ? * < >",
  }

  const isActionValid = (): boolean => {
    if (!isValidName(input)) return false

    if (error || isLoading) return false

    let returnValue = true

    dstFolder.content?.forEach((file: Resource) => {
      if (file?.name == input) {
        returnValue = false
      }
    })
    return returnValue
  }

  const onTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(event.target.value as string)
  }

  const createFolder = (projectId: string, path: string, name: string) => {
    const errorAction = () => {
      setIsLoading(false)
      setError(true)
    }

    const successAction = () => {
      if (setFile) {
        getGedAPI()
          .getFiles(projectId, dstFolder.path, true)
          .then((file) => {
            setFile(file)
            setIsLoading(false)
            handleClose()
          })
          .catch(() => {
            errorAction()
          })
      }
    }

    getGedAPI()
      .createFolder(projectId, path, name)
      .then((response) => {
        if (response == "Created") return successAction()
        return errorAction()
      })
      .catch(() => {
        errorAction()
      })
  }

  const onCreate = () => {
    setIsLoading(true)
    setError(false)
    createFolder(projectId, dstFolder.path, input)
    setInput("")
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.destinationTitle}>{strings.destination}:</Box>
          <Box sx={styles.destination}>{getReadablePath(dstFolder.path)}</Box>
          <TextField
            error={input.length > 0 ? !isActionValid() : false}
            autoFocus
            margin="dense"
            id="name"
            label="Nom de dossier"
            type="text"
            fullWidth
            variant="standard"
            onChange={onTextChange}
            helperText={isActionValid() ? "" : strings.helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button disabled={!isActionValid()} onClick={onCreate}>
            {strings.buttonCreate}
          </Button>
        </DialogActions>
      </List>
    </div>
  )
}

import { FunctionComponent } from "react"
import { Breadcrumb as RBBreadcrumb, Container } from "react-bootstrap"
import { Link } from "react-router-dom"

export interface BreadcrumbItem {
  name: string
  link?: string
  active?: boolean
}

export interface BreacrumbProps {
  items: BreadcrumbItem[]
}

export const Breadcrumb: FunctionComponent<BreacrumbProps> = ({ items }) => {
  return (
    <Container fluid className="my-2">
      <RBBreadcrumb>
        {items.map((item, key) => {
          const linkAs = () =>
            item.link ? (
              <Link to={item.link}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )
          return (
            <RBBreadcrumb.Item active={item.active} key={key} linkAs={linkAs}>
              {item.name}
            </RBBreadcrumb.Item>
          )
        })}
      </RBBreadcrumb>
    </Container>
  )
}

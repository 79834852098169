import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useState } from "react"
import { AsyncDownload, Folder, getGedAPI } from "../../utils/api"
import {
  dialogTitleStyle,
  getCodeText,
  getDialogError,
  getDialogLoader,
} from "./commons"

export interface DialogBoxDownloadProps {
  projectId: string
  folder: Folder
  handleClose: () => void
}

export const DialogBoxDownload: FunctionComponent<DialogBoxDownloadProps> = ({
  projectId,
  folder,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isDone, setIsDone] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
  }

  const strings = {
    dialogTitle: "Télécharger un dossier",
    dialogContent: "Voulez-vous vraiment télécharger le dossier :",
    buttonCancel: "Annuler",
    buttonDownload: "Confirmer",
    loadingMessage:
      "Votre lien de téléchargement est en cours de préparation...",
    doneMessage:
      "Dès qu'il sera disponible, vous pourrez y accéder depuis votre boîte de réception.",
  }

  const onDownload = () => {
    setIsLoading(true)

    getGedAPI()
      .getDownloadUrl(projectId, folder.path)
      .then((response) => {
        response = response as AsyncDownload
        if (response.operationId) {
          setIsLoading(false)
          setIsDone(true)
        }
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }

  if (isLoading) {
    return getDialogLoader(strings.loadingMessage)
  }

  if (error) {
    return getDialogError(handleClose)
  }

  if (isDone) {
    return (
      <div style={styles.size}>
        <DialogTitle>
          {strings.loadingMessage}
          <br />
          {strings.doneMessage}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </div>
    )
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent>
          {strings.dialogContent} {getCodeText(folder.name)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button onClick={onDownload}>{strings.buttonDownload}</Button>
        </DialogActions>
      </List>
    </div>
  )
}

import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import TextField from "@mui/material/TextField"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { Folder, getGedAPI, Resource } from "../../utils/api"
import {
  dialogTitleStyle,
  getDialogError,
  getDialogLoader,
  isValidName,
} from "./commons"

export interface DialogBoxRenameProps {
  projectId: string
  kind: string
  folder: Folder
  resource: Resource
  handleClose: () => void
  setFile?: (resource: Resource) => void
}

export const DialogBoxRename: FunctionComponent<DialogBoxRenameProps> = ({
  projectId,
  kind,
  folder,
  resource,
  handleClose,
  setFile,
}) => {
  let defaultInput
  const [input, setInput] = useState<string>(resource.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
  }

  const strings = {
    dialogTitle:
      kind == "folder" ? "Renommer un dossier" : "Renommer un fichier",
    buttonCancel: "Annuler",
    buttonRename: "Renommer",
    textLabel: "Nom",
    helperText:
      "Le nouveau nom doit respecter ces règles: 1 à 255 caractères, doit être unique et ne pas comporter les caractères suivants: / \\ : { } ? * < >",
  }

  const isActionValid = (): boolean => {
    const isNameAlreadyTaken = (
      sourceName: string,
      content: Resource[]
    ): boolean => {
      for (let i = 0; i < content.length; i++) {
        if (sourceName == content[i].name) return true
      }
      return false
    }

    if (folder.content && folder.content.length > 0) {
      if (isNameAlreadyTaken(input, folder.content)) return false
    }
    if (input == resource.name) return false
    return isValidName(input)
  }

  const onTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(event.target.value as string)
  }

  const removeExtensionFromName = (name: string) => {
    return name.replace(/\.[^/.]+$/, "")
  }

  const onRename = () => {
    const errorAction = () => {
      setIsLoading(false)
      setError(true)
    }

    const successAction = () => {
      if (setFile) {
        getGedAPI()
          .getFiles(projectId, folder.path, true)
          .then((file) => {
            setFile(file)
            setIsLoading(false)
            handleClose()
          })
          .catch(() => {
            errorAction()
          })
      }
    }

    setIsLoading(true)
    setError(false)
    getGedAPI()
      .renameResource(projectId, resource, input)
      .then((response) => {
        if (response.status == "success") return successAction()
        return errorAction()
      })
      .catch(() => {
        errorAction()
      })
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  if (kind == "folder") defaultInput = resource.name
  else defaultInput = removeExtensionFromName(resource.name)

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          <TextField
            error={input.length > 0 ? !isActionValid() : false}
            autoFocus
            margin="dense"
            id="name"
            label={strings.textLabel}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={defaultInput}
            onChange={onTextChange}
            helperText={isActionValid() ? "" : strings.helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button disabled={!isActionValid()} onClick={onRename}>
            {strings.buttonRename}
          </Button>
        </DialogActions>
      </List>
    </div>
  )
}

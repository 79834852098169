import { createContext, Dispatch } from "react"

interface AddAction {
  type: "add"
  key: SelectedSetsKeys
  data: string
}

interface RemoveAction {
  type: "remove"
  key: SelectedSetsKeys
  data: string
}

interface SelectedSets {
  so: Set<string>
  composants: Set<string>
  elements: Set<string>
}

type SelectedSetsAction = AddAction | RemoveAction
type SelectedSetsKeys = keyof SelectedSets

export const SelectedSetsReducerDispatch = createContext<{
  selectedSets: SelectedSets
  dispatchSelectedSets: Dispatch<SelectedSetsAction>
}>({
  selectedSets: {
    so: new Set<string>(),
    composants: new Set<string>(),
    elements: new Set<string>(),
  },
  dispatchSelectedSets: () => null,
})

export const selectedReducer = (
  selectedSets: SelectedSets,
  action: SelectedSetsAction
): SelectedSets => {
  if (!action.data || !action.key) {
    return selectedSets
  }

  switch (action.type) {
    case "add":
      return add(selectedSets, action)
    case "remove":
      return remove(selectedSets, action)
  }

  console.error("[selectedElementsReducer] Unknown action ", action)
  return selectedSets
}

const add = (
  selectedSets: SelectedSets,
  action: SelectedSetsAction
): SelectedSets => {
  return {
    ...selectedSets,
    [action.key]: new Set(selectedSets[action.key]).add(action.data),
  }
}

const remove = (
  selectedSets: SelectedSets,
  action: SelectedSetsAction
): SelectedSets => {
  const newSet = new Set(selectedSets[action.key])
  newSet.delete(action.data)

  return {
    ...selectedSets,
    [action.key]: newSet,
  }
}

import { FunctionComponent } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { DroppedFiles } from "../../containers/ReportEditionView/ReportEditionDropZone"
import { Project, Resource } from "../../utils/api"
import GedResource from "./GedResource"
import "./GedResource.scss"

interface GedResourcesContainerProps {
  project: Project
  resources: Array<Resource>
  xs?: number
  limit?: number
  attachedFiles?: DroppedFiles
}

const GedResourcesContainer: FunctionComponent<GedResourcesContainerProps> = ({
  project,
  resources,
  xs,
  limit,
}) => {
  let content
  let usedResources

  if (limit) {
    usedResources = resources.slice(0, limit)
  } else {
    usedResources = resources
  }

  if (usedResources.length > 0) {
    content = (
      <Container fluid>
        <Row>
          {usedResources.map((r, key) => (
            <Col xs={xs} key={`ged-resources-${key}`}>
              <GedResource
                resource={r}
                projectId={project.id}
                tooltipPreview={true}
                // To avoid Popover to go outside the viewport
                tooltipPlacement={(key + 1) % 3 == 0 ? "top" : undefined}
              />
            </Col>
          ))}
        </Row>
      </Container>
    )
  } else {
    content = <i>Aucun fichier trouvé</i>
  }

  return <div className="ged-resources">{content}</div>
}

export default GedResourcesContainer

import { DraftReport } from "../types/report"

// WARNING
// This code contains the logic related to the IQOA notation used by SETEC TPI ;
// If your are not familiar with this notation, you MUST read the document
// "IQOA Classification des ouvrages.pdf" before editing this file ;

const NOTE_IQOA_LABELS = [
  "1",
  "1S",
  "2",
  "2S",
  "2E",
  "2ES",
  "3",
  "3S",
  "3U",
  "3US",
]

export const NOTE_IQOA_VALUES = NOTE_IQOA_LABELS.map((note, key) => {
  return {
    value: key + 1,
    label: note,
  }
})

const hasSMention = (note: number): boolean => {
  if (note == 2 || note == 4 || note == 6 || note == 8 || note == 10)
    return true
  return false
}

export const CalculateSousOuvragesAndOuvrageNotes = (
  report: DraftReport
): DraftReport => {
  if (report.form.partiesVisitees) {
    report.form.partiesVisitees.forEach((sousOuvrage) => {
      let sMention = false
      let highestNote = 0
      sousOuvrage.composants.forEach((composant) => {
        composant.elements.forEach((element) => {
          if (element.noteIQOA > highestNote) {
            highestNote = element.noteIQOA
          }
          if (!sMention) sMention = hasSMention(element.noteIQOA)
        })
        if (sMention && !hasSMention(highestNote)) highestNote += 1
        sousOuvrage.noteIQOA = highestNote
      })
    })

    let sMention = false
    let highestNote = 0
    report.form.partiesVisitees.forEach((sousOuvrage) => {
      if (sousOuvrage.noteIQOA > highestNote) highestNote = sousOuvrage.noteIQOA
      if (!sMention) sMention = hasSMention(sousOuvrage.noteIQOA)
    })
    if (sMention && !hasSMention(highestNote)) highestNote += 1
    report.form.noteIQOA = highestNote
  }
  return report
}

export const toNoteIQOALabel = (note?: number) =>
  NOTE_IQOA_LABELS[note! - 1] || "N/A"

export const toNoteIQOASeverity = (note?: number) =>
  !note ? "secondary" : note < 3 ? "success" : note < 7 ? "warning" : "danger"

import { FunctionComponent, useEffect, useState } from "react"
import { ListGroup } from "react-bootstrap"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import {
  ElementWithinHierarchy,
  ExtendedComposant,
  ExtendedElementWithinHierarchy,
  ExtendedHierarchy,
  ExtendedSousOuvrage,
  SousOuvrage,
} from "../../utils/api"
import { computeOuvrageNote } from "./DashboardView"

interface DashboardSummaryProps {
  hierarchy: ExtendedHierarchy
  onSousOuvrageSelected: (so: SousOuvrage) => void
  onElementSelected: (e: ElementWithinHierarchy) => void
}

const DashboardSumary: FunctionComponent<DashboardSummaryProps> = ({
  hierarchy,
  onSousOuvrageSelected,
  onElementSelected,
}) => {
  const [globalSo] = useState<ExtendedSousOuvrage>({
    id: ".global",
    name: "Ouvrage complet",
    composants: [],
    excerpt: hierarchy.excerpt,
  })
  const [selectedSo, setSelectedSo] = useState<ExtendedSousOuvrage>(globalSo)
  const [selectedComposant, setSelectedComposant] =
    useState<ExtendedComposant>()
  const [selectedElement, setSelectedElement] =
    useState<ExtendedElementWithinHierarchy>()
  const onSoSelected = (selection: ExtendedSousOuvrage) => {
    setSelectedSo(selection)
    setSelectedComposant(selection.composants.find((c) => c.excerpt))
    setSelectedElement(undefined)
  }

  useEffect(
    () => onSousOuvrageSelected(selectedSo),
    [onSousOuvrageSelected, selectedSo]
  )

  useEffect(
    () => selectedElement && onElementSelected(selectedElement),
    [onElementSelected, selectedElement]
  )

  let count = 0

  return (
    <div className="dashboard-summary">
      <div className="dashboard-summary__section">
        <h6>Sous-ouvrages:</h6>
        <div className="dashboard-summary__section__list">
          <ListGroup>
            {[globalSo, ...hierarchy.sousOuvrages].map((so, key) => {
              let noteIQOA
              if (count == 0) noteIQOA = computeOuvrageNote(hierarchy)
              else noteIQOA = computeSONote(so)
              count++
              return (
                <ListGroup.Item
                  action
                  key={key}
                  disabled={!so.excerpt}
                  onClick={() => onSoSelected(so)}
                  active={so === selectedSo}
                >
                  {so.name}
                  {noteIQOA ? (
                    <BadgeIQOA className="ms-auto" note={noteIQOA} />
                  ) : null}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </div>
      </div>
      <div className="dashboard-summary__section">
        <h6>Composants:</h6>
        <div className="dashboard-summary__section__list">
          {selectedSo ? (
            <ListGroup>
              {selectedSo.composants.map((c, key) => {
                return (
                  <ListGroup.Item
                    action
                    key={key}
                    disabled={!c.excerpt}
                    onClick={() => setSelectedComposant(c)}
                    active={c === selectedComposant}
                  >
                    {c.name}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          ) : null}
        </div>
      </div>
      <div className="dashboard-summary__section">
        <h6>Elements:</h6>
        <div className="dashboard-summary__section__list">
          {selectedComposant ? (
            <ListGroup>
              {selectedComposant.elements.map((e, key) => {
                const noteIQOA = e.excerpt?.noteIQOA
                return (
                  <ListGroup.Item
                    action
                    key={key}
                    disabled={!e.excerpt}
                    onClick={() => setSelectedElement(e)}
                    active={e === selectedElement}
                  >
                    {e.name}
                    {noteIQOA ? (
                      <BadgeIQOA className="ms-auto" note={noteIQOA} />
                    ) : null}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export const computeSONote = (so: ExtendedSousOuvrage) => {
  let hasS = false
  let highestNote = -1

  so.composants.forEach((composant) => {
    composant.elements.forEach((element) => {
      if (element.excerpt?.noteIQOA) {
        if (element.excerpt.noteIQOA > highestNote)
          highestNote = element.excerpt.noteIQOA
        if (element.excerpt.noteIQOA % 2 == 0) hasS = true
      }
    })
  })

  if (highestNote == -1) return undefined

  if (highestNote % 2 != 0 && hasS) highestNote += 1

  return highestNote
}

export default DashboardSumary

import { FunctionComponent, useContext } from "react"
import { Accordion, Card, Col, Row } from "react-bootstrap"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import {
  PartieVisitee,
  PartieVisiteeSousOuvrageComposant,
  PartieVisiteeSousOuvrageComposantElement,
  PartieVisiteeSousOuvrageComposantElementCommentaire,
} from "../../types/report"
import { Project } from "../../utils/api"
import { CustomToggle } from "../ReportEditionView/ReportEditionDropZone"
import { ReportConsultationElementCommentFiles } from "./ReportConsultationFiles"
import { ReportContext } from "./ReportConsultationView"

interface ReportConsultationPartiesVisiteesProps {
  project: Project
}
interface ReportConsultationPartiesVisiteesContentProps {
  project: Project
}
interface ReportConsultationPartieVisiteeSousOuvrageSectionProps {
  project: Project
  sousOuvrageIndex: number
  partieVisitee: PartieVisitee
}
interface ReportConsultationPartieVisiteeComposantSectionProps {
  project: Project
  sousOuvrageIndex: number
  composantIndex: number
  composant: PartieVisiteeSousOuvrageComposant
}
interface ReportConsultationPartieVisiteeElementSectionProps {
  project: Project
  sousOuvrageIndex: number
  composantIndex: number
  elementIndex: number
  element: PartieVisiteeSousOuvrageComposantElement
}
interface ReportConsultationPartieVisiteeElementCommentaireProps {
  project: Project
  commentaire: PartieVisiteeSousOuvrageComposantElementCommentaire
  SOIndex: number
  composantIndex: number
  elementIndex: number
  commentaireIndex: number
}

const ReportingConsultationPartiesVisitees: FunctionComponent<
  ReportConsultationPartiesVisiteesProps
> = ({ project }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>{`Parties visitées (${
            report.form.partiesVisitees?.length || 0
          })`}</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            {report.form.partiesVisitees?.length ? (
              <ReportConsultationPartiesVisiteesContent project={project} />
            ) : (
              <i>Aucune donnée renseignée</i>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportConsultationPartiesVisiteesContent: FunctionComponent<
  ReportConsultationPartiesVisiteesContentProps
> = ({ project }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <>
      {report.form.partiesVisitees?.map((partieVisitee, sousOuvrageIndex) => (
        <div key={`container-${sousOuvrageIndex}`}>
          <ReportConsultationPartieVisiteeSousOuvrageSection
            project={project}
            sousOuvrageIndex={sousOuvrageIndex}
            partieVisitee={partieVisitee}
          />
        </div>
      ))}
    </>
  )
}

const ReportConsultationPartieVisiteeSousOuvrageSection: FunctionComponent<
  ReportConsultationPartieVisiteeSousOuvrageSectionProps
> = ({ project, sousOuvrageIndex, partieVisitee }) => {
  const { report } = useContext(ReportContext)
  if (!report) return null
  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`Sous-ouvrage : ${partieVisitee.sousOuvrageId} (${partieVisitee.composants.length})`}
            {report.type <= 4 ? (
              <BadgeIQOA note={partieVisitee.noteIQOA} />
            ) : null}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            {partieVisitee.composants.map((composant, composantIndex) => (
              <ReportConsultationPartieVisiteeComposantSection
                project={project}
                sousOuvrageIndex={sousOuvrageIndex}
                composantIndex={composantIndex}
                composant={composant}
                key={`container-${sousOuvrageIndex}-composant-${composantIndex}`}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportConsultationPartieVisiteeComposantSection: FunctionComponent<
  ReportConsultationPartieVisiteeComposantSectionProps
> = ({ project, sousOuvrageIndex, composantIndex, composant }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <div>
      <Accordion>
        <Card>
          <CustomToggle eventKey="0">
            <Card.Header>
              {`Composant : ${composant.composantId.split("/")[1]} (${
                composant.elements.length
              })`}
            </Card.Header>
          </CustomToggle>
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              {composant.elements.map((element, elementIndex) => (
                <ReportConsultationPartieVisiteeElementSection
                  project={project}
                  sousOuvrageIndex={sousOuvrageIndex}
                  composantIndex={composantIndex}
                  elementIndex={elementIndex}
                  element={element}
                  key={`container-${sousOuvrageIndex}-composant-${composantIndex}-element-${elementIndex}`}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    </div>
  )
}

const ReportConsultationPartieVisiteeElementSection: FunctionComponent<
  ReportConsultationPartieVisiteeElementSectionProps
> = ({ project, sousOuvrageIndex, composantIndex, elementIndex, element }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {`Élément : ${element.elementId.split("/")[2]} (${
              element.commentaires.length || 0
            })`}
            {report.type <= 4 ? <BadgeIQOA note={element.noteIQOA} /> : null}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            {element.commentaires.map(
              (commentaire, elementCommentaireIndex) => (
                <ReportConsultationPartieVisiteeElementCommentaire
                  project={project}
                  commentaire={commentaire}
                  SOIndex={sousOuvrageIndex}
                  composantIndex={composantIndex}
                  elementIndex={elementIndex}
                  commentaireIndex={elementCommentaireIndex}
                  key={`container-${sousOuvrageIndex}-composant-${composantIndex}-element-${elementIndex}-commentaire-${elementCommentaireIndex}`}
                />
              )
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportConsultationPartieVisiteeElementCommentaire: FunctionComponent<
  ReportConsultationPartieVisiteeElementCommentaireProps
> = ({
  project,
  commentaire,
  SOIndex,
  composantIndex,
  elementIndex,
  commentaireIndex,
}) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  const getCommentTitle = (
    comment: PartieVisiteeSousOuvrageComposantElementCommentaire
  ) => {
    const maxLength = 100
    if (comment.titre) {
      const title = comment.titre.split("\n")[0]
      if (title.length > maxLength + 3)
        return title.substring(0, maxLength) + "..."
      else return title
    } else {
      return "Commentaire"
    }
  }

  return (
    // <div className={"section"}>
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            {commentaire.attachedFiles && commentaire.attachedFiles.length > 0
              ? `Commentaire : ${getCommentTitle(commentaire)} (${
                  commentaire.attachedFiles?.length
                })`
              : `Commentaire : ${getCommentTitle(commentaire)}`}
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Row>
              <Col>
                {report && report.type <= 4 ? <b>Titre</b> : <b>Commentaire</b>}
                <div className={"text-user"}>
                  {commentaire.titre ? (
                    commentaire.titre
                  ) : (
                    <i>Aucune donnée renseignée.</i>
                  )}
                </div>
              </Col>
              {report && report.type <= 4 ? (
                <>
                  <Col>
                    <b>Constatation</b>
                    <div className={"text-user"}>
                      {commentaire.constatation ? (
                        commentaire.constatation
                      ) : (
                        <i>Aucune donnée renseignée.</i>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <b>Interprétation</b>
                    <div className={"text-user"}>
                      {commentaire.interpretation ? (
                        commentaire.interpretation
                      ) : (
                        <i>Aucune donnée renseignée.</i>
                      )}
                    </div>
                  </Col>
                </>
              ) : null}
              <Col>
                <b>Localisation</b>
                <div className={"text-user"}>
                  {commentaire.localisation ? (
                    commentaire.localisation
                  ) : (
                    <i>Aucune donnée renseignée.</i>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              {commentaire.attachedFiles?.length ? (
                <ReportConsultationElementCommentFiles
                  project={project}
                  SOIndex={SOIndex}
                  composantIndex={composantIndex}
                  elementIndex={elementIndex}
                  commentaireIndex={commentaireIndex}
                />
              ) : null}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
    // </div>
  )
}

export default ReportingConsultationPartiesVisitees

import { FunctionComponent, useContext } from "react"
import { Accordion, Card, Col, Row } from "react-bootstrap"
import { PartieNonVisitee } from "../../types/report"
import { CustomToggle } from "../ReportEditionView/ReportEditionDropZone"
import { ReportContext } from "./ReportConsultationView"

interface ReportConsultationPartiesNonVisiteesProps {}
interface ReportConsultationPartiesNonVisiteesContentProps {}
interface ReportConsultationPartieNonVisiteeSectionProps {
  partieNonVisitee: PartieNonVisitee
}

const ReportConsultationPartiesNonVisitees: FunctionComponent<
  ReportConsultationPartiesNonVisiteesProps
> = () => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>{`Parties non visitées (${
            report.form.partiesNonVisitees?.length || 0
          })`}</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            {report.form.partiesNonVisitees?.length ? (
              <ReportConsultationPartiesNonVisiteesContent />
            ) : (
              <i>Aucune donnée renseignée</i>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const ReportConsultationPartiesNonVisiteesContent: FunctionComponent<
  ReportConsultationPartiesNonVisiteesContentProps
> = () => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <>
      {report.form.partiesNonVisitees?.map((partieNonVisitee, index) => (
        <div key={`div-nv-${index}`}>
          <ReportConsultationPartieNonVisiteeSection
            key={`ReportConsultationPartieNonVisiteeSection-${index}`}
            partieNonVisitee={partieNonVisitee}
          />

          {/* {index < e.length - 1 ? (
            <hr className={"separator"} key={`hr-nv-${index}`} />
          ) : null} */}
        </div>
      ))}
    </>
  )
}

const ReportConsultationPartieNonVisiteeSection: FunctionComponent<
  ReportConsultationPartieNonVisiteeSectionProps
> = ({ partieNonVisitee }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>{`Sous-ouvrage : ${partieNonVisitee.sousOuvrageId}`}</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Row>
              <Col>
                {partieNonVisitee.commentaire ? (
                  <div>{partieNonVisitee.commentaire}</div>
                ) : (
                  <i>Aucune donnée renseignée.</i>
                )}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export default ReportConsultationPartiesNonVisitees

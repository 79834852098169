import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import { FunctionComponent, useState } from "react"
import { createReportGedFolders } from "../../containers/GetAllReportsView/GetAllReportsView"
import { Report } from "../../types/report"
import { Folder, getGedAPI, getMainAPI, PostReportBody } from "../../utils/api"
import { sleep } from "../../utils/ged"
import { generateReportFolderPath, getNextIndice } from "../../utils/report"
import { isLastIndice } from "../ReportOptions/commons"

import { dialogTitleStyle, getDialogError, getDialogLoader } from "./commons"

export interface DialogBoxNewIndiceProps {
  projectId: string
  report: Report
  handleClose: () => void
}

export const DialogBoxNewIndice: FunctionComponent<DialogBoxNewIndiceProps> = ({
  projectId,
  report,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
  }

  const getLastIndice = () => {
    let lastIndice = report
    report.indicesReports?.forEach((rep) => {
      if (isLastIndice(rep, report.indicesReports as Report[])) lastIndice = rep
    })
    return lastIndice
  }

  const lastIndice = getLastIndice()

  const strings = {
    dialogTitle: "Créer un nouvel indice",
    confirm: `Voulez vous vraiment créer l'indice ${getNextIndice(
      report.indice
    )} du rapport ${report.title} dans le projet ${projectId} ?`,
    warns: `Un nouvel indice en édition va être créer en dupliquant l'indice ${lastIndice.indice}. Une fois ce nouvel indice soumis, il invalidera le dernier indice soumis.`,
    date: "Date prévisionnelle",
    buttonCancel: "Annuler",
    buttonCreate: "Créer",
  }

  const onCreate = () => {
    setIsLoading(true)

    const body: PostReportBody = {
      title: report.title,
      type: report.type,
    }
    getMainAPI()
      .createReportIndice(projectId, report.id, body)
      .then(() => {
        createReportGedFolders(
          projectId,
          report.title,
          report.id,
          report.type,
          getNextIndice(report.indice)
        )
          .then(() => {
            getGedAPI()
              .getFiles(
                projectId,
                generateReportFolderPath(
                  report.title,
                  report.id,
                  report.type,
                  report.indice
                )
              )
              .then((resp) => {
                const folder = resp as Folder
                const promises: Promise<string>[] = []
                folder.content?.forEach((file) => {
                  promises.push(
                    getGedAPI().copyResource(
                      projectId,
                      file.path,
                      "/" +
                        generateReportFolderPath(
                          report.title,
                          report.id,
                          report.type,
                          getNextIndice(report.indice)
                        )
                    )
                  )
                })
                if (promises.length == 0) {
                  setIsLoading(false)
                  handleClose()
                }

                Promise.all(promises).then(() => {
                  sleep(1000).then(() => {
                    setIsLoading(false)
                    handleClose()
                  })
                })
              })
          })
          .catch((err) => {
            setIsLoading(false)
            setError(true)
            console.error(err)
          })
      })
      .catch((err) => {
        setIsLoading(false)
        setError(true)
        console.error(err)
      })

    setError(false)
  }

  if (isLoading) {
    return getDialogLoader()
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          {strings.confirm}
          <br />
          {strings.warns}
          {/* <hr />
          <Form.Group className="mb-3">
            <Form.Label>{strings.date}</Form.Label>
            <Form.Control
              type={"date"}
              name={"dueDate"}
              placeholder="Date"
              onChange={(e) => setDueDate(e.target.value)}
              required
            />
          </Form.Group> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          {/* <Button disabled={dueDate == ""} onClick={onCreate}> */}
          <Button onClick={onCreate}>{strings.buttonCreate}</Button>
        </DialogActions>
      </List>
    </div>
  )
}

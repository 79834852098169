import moment from "moment"
import { FunctionComponent, useContext } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { Project } from "../../utils/api"
import { REPORT_TYPES } from "../../utils/report"
import { ReportContext } from "./ReportConsultationView"

interface ReportConsultationHeaderProps {
  project: Project
}

interface ReportConsultationChronologieProps {}

interface DateElementDescriptor {
  timestamp: number
  formattedDate: string
  label: string
}

const ReportConsultationHeader: FunctionComponent<
  ReportConsultationHeaderProps
> = ({ project }) => {
  const { report } = useContext(ReportContext)

  if (!report) {
    return <></>
  }

  return (
    <Card>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col className={"form-col"}>
              <div>Nom ouvrage : {project.name}</div>
            </Col>
            <Col className={"form-col"}>
              <div>Type de fiche : {REPORT_TYPES[report.type].name}</div>
            </Col>
          </Row>
          {report.submitterEmail ? (
            <Row>
              <Col>
                <div>Soumis par : {report.submitterEmail}</div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <div>Chronologie :</div>
              <ReportConsultationChronologie />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Intervenants</h5>
              <div className={"text-user"}>{report.form.intervenants}</div>
            </Col>
            <Col>
              <h5>Conditions Climatiques</h5>
              <div className={"text-user"}>
                {report.form.conditionsClimatiques ? (
                  report.form.conditionsClimatiques
                ) : (
                  <i>Aucune donnée renseignée.</i>
                )}
              </div>
            </Col>
            <Col>
              <h5>Moyens Utilisés</h5>
              <div className={"text-user"}>
                {report.form.moyensUtilises ? (
                  report.form.moyensUtilises
                ) : (
                  <i>Aucune donnée renseignée.</i>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

const ReportConsultationChronologie: FunctionComponent<
  ReportConsultationChronologieProps
> = () => {
  const { report } = useContext(ReportContext)
  const dateFormat = "DD-MM-YYYY"

  if (!report) {
    return <></>
  }

  const descriptors: DateElementDescriptor[] = [
    {
      timestamp: Date.parse(report.creationDate),
      formattedDate: moment(Date.parse(report.creationDate)).format(dateFormat),
      label: "Création",
    },
    {
      timestamp: Date.parse(report.form.startDate),
      formattedDate: moment(Date.parse(report.form.startDate)).format(
        dateFormat
      ),
      label: "Début de  l'inspection",
    },
    {
      timestamp: Date.parse(report.form.endDate),
      formattedDate: moment(Date.parse(report.form.endDate)).format(dateFormat),
      label: "Fin de l'inspection",
    },
    {
      timestamp: Date.parse(report.submissionDate),
      formattedDate: moment(Date.parse(report.submissionDate)).format(
        dateFormat
      ),
      label: "Soumission",
    },
    // {
    //   timestamp: Date.parse(report.dueDate),
    //   formattedDate: moment(Date.parse(report.dueDate)).format(dateFormat),
    //   label: "Date prévisionnelle",
    // },
  ]

  const sortedDescriptors = descriptors.sort((a, b) => {
    return a.timestamp - b.timestamp
  })

  const step = 100 / (sortedDescriptors.length + 1)

  return (
    <div className={"chronologie"}>
      <div className={"bar"} />

      {sortedDescriptors.map((descriptor, index) => (
        <div
          className={"element"}
          style={{ left: step * (index + 1) + "%" }}
          key={`descriptor-${index}`}
        >
          <div className={"label"}>{descriptor.label}</div>
          <div className={"dot"} />
          <div className={"value"}>{descriptor.formattedDate}</div>
        </div>
      ))}
    </div>
  )
}

export default ReportConsultationHeader

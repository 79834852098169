import {
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { Link } from "react-router-dom"
import GedResourcesContainer from "../../components/GedResource/GedResourcesContainer"
import Icon from "../../components/Icon/Icon"
import Loader from "../../components/Loader/Loader"
import { Report } from "../../types/report"
import { getGedAPI, getPresignedApi, Project, Resource } from "../../utils/api"
import { generateReportFolderPath } from "../../utils/report"
import {
  ReportAction,
  ReportReducerDispatch,
} from "./ReportEditionReportReducer"

interface ReportEditionDropZoneProps {
  project: Project
  isElement?: boolean
  sousOuvrageIndex?: number
  composantIndex?: number
  elementIndex?: number
  commentaireIndex?: number
  dispatch?: (a: ReportAction) => void
}

interface StatusIconProps {
  fileStatus: FileStatus
}

interface StatusBarProps {
  project: Project
  index: number
  fileName: string
  fileBlob?: Blob
  setAttachedFiles: Dispatch<Resource[]>
  isElement?: boolean
  sousOuvrageIndex?: number
  composantIndex?: number
  elementIndex?: number
  commentaireIndex?: number
  dispatch?: (a: ReportAction) => void
}

export interface DroppedFile {
  name: string
  blob?: Blob
}

type FileStatus = "Uploading" | "Success" | "Failure" | "None"
export type DroppedFiles = DroppedFile[]

interface DroppedFilesReducerSetAction {
  type: "set"
  data: DroppedFiles
}

interface DroppedFilesReducerMergeAction {
  type: "merge"
  data: DroppedFiles
}

interface DroppedFilesReducerRemoveAction {
  type: "remove"
  index: number
}

type DroppedFilesReducerAction =
  | DroppedFilesReducerMergeAction
  | DroppedFilesReducerRemoveAction
  | DroppedFilesReducerSetAction

export function CustomToggle(obj: any) {
  const children = obj.children
  const eventKey = obj.eventKey
  const decoratedOnClick = useAccordionButton(eventKey)

  return <span onClick={decoratedOnClick}>{children}</span>
}

const droppedFilesReducer = (
  droppedFiles: DroppedFiles,
  action: DroppedFilesReducerAction
) => {
  switch (action.type) {
    case "merge": {
      return [...droppedFiles, ...action.data]
    }
    case "set": {
      return [...action.data]
    }
    case "remove": {
      const newDroppedFiles = [...droppedFiles]
      newDroppedFiles.splice(action.index, 1)
      return newDroppedFiles
    }
  }

  return [...droppedFiles]
}

const DroppedFilesDispatch = createContext<{
  droppedFiles: DroppedFiles
  droppedFilesDispatch: Dispatch<DroppedFilesReducerAction>
}>({
  droppedFiles: [],
  droppedFilesDispatch: () => null,
})

const DROP_FILE_UPDATE_TIMEOUT_MS = 5000

const ReportEditionDropZone: FunctionComponent<ReportEditionDropZoneProps> = ({
  project,
  isElement,
  sousOuvrageIndex,
  composantIndex,
  elementIndex,
  commentaireIndex,
  dispatch,
}) => {
  const [attachedFiles, setAttachedFiles] = useState<Array<Resource>>([])
  const { report } = useContext(ReportReducerDispatch)
  const [mustUpdateFiles, setMustUpdateFiles] = useState<boolean>(true)

  const [droppedFiles, droppedFilesDispatch] = useReducer(
    droppedFilesReducer,
    []
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      const newDroppedFiles: DroppedFiles = files.map((file) => ({
        name: file.name,
        blob: file.slice(),
      }))

      droppedFilesDispatch({
        type: "merge",
        data: newDroppedFiles,
      })

      if (
        sousOuvrageIndex != undefined &&
        sousOuvrageIndex >= 0 &&
        composantIndex != undefined &&
        composantIndex >= 0 &&
        elementIndex != undefined &&
        elementIndex >= 0 &&
        commentaireIndex != undefined &&
        commentaireIndex >= 0 &&
        dispatch
      ) {
        dispatch({
          type: "replacePartieVisiteeElementCommentaireFile",
          subType: "merge",
          partieVisiteeIndex: sousOuvrageIndex,
          composantIndex: composantIndex,
          elementIndex: elementIndex,
          elementCommentaireIndex: commentaireIndex,
          files: newDroppedFiles,
        })
      }

      setTimeout(() => {
        updateAttachedFiles(
          project.id,
          report,
          setAttachedFiles,
          isElement,
          sousOuvrageIndex,
          composantIndex,
          elementIndex,
          commentaireIndex
        )
      }, DROP_FILE_UPDATE_TIMEOUT_MS)
    },
    onDropRejected: (rejections) => {
      const newDroppedFiles: DroppedFiles = rejections.map((rejection) => ({
        name: rejection.file.name,
      }))

      droppedFilesDispatch({
        type: "merge",
        data: newDroppedFiles,
      })
      if (
        sousOuvrageIndex != undefined &&
        sousOuvrageIndex >= 0 &&
        composantIndex != undefined &&
        composantIndex >= 0 &&
        elementIndex != undefined &&
        elementIndex >= 0 &&
        commentaireIndex != undefined &&
        commentaireIndex >= 0 &&
        dispatch
      ) {
        dispatch({
          type: "replacePartieVisiteeElementCommentaireFile",
          subType: "merge",
          partieVisiteeIndex: sousOuvrageIndex,
          composantIndex: composantIndex,
          elementIndex: elementIndex,
          elementCommentaireIndex: commentaireIndex,
          files: newDroppedFiles,
        })
      }
    },
  })

  useEffect(() => {
    if (mustUpdateFiles) {
      setMustUpdateFiles(false)
      updateAttachedFiles(
        project.id,
        report,
        setAttachedFiles,
        isElement,
        sousOuvrageIndex,
        composantIndex,
        elementIndex,
        commentaireIndex
      )
        .then((resp) => {
          const datas: DroppedFiles = []
          resp.forEach((file) => {
            datas.push({
              name: file.name,
            })
          })
          if (
            sousOuvrageIndex != undefined &&
            sousOuvrageIndex >= 0 &&
            composantIndex != undefined &&
            composantIndex >= 0 &&
            elementIndex != undefined &&
            elementIndex >= 0 &&
            commentaireIndex != undefined &&
            commentaireIndex >= 0 &&
            dispatch
          ) {
            dispatch({
              type: "replacePartieVisiteeElementCommentaireFile",
              subType: "set",
              partieVisiteeIndex: sousOuvrageIndex,
              composantIndex: composantIndex,
              elementIndex: elementIndex,
              elementCommentaireIndex: commentaireIndex,
              files: datas,
            })
          }
          droppedFilesDispatch({
            type: "set",
            data: datas,
          })
        })
        .catch((err) => console.error(err))
    }
  }, [
    project.id,
    report,
    setAttachedFiles,
    mustUpdateFiles,
    commentaireIndex,
    composantIndex,
    elementIndex,
    isElement,
    sousOuvrageIndex,
    dispatch,
  ])

  if (!report) {
    return <></>
  }

  let reportFolderPath = generateReportFolderPath(
    report.title,
    report.id,
    report.type,
    report.indice
  )
  if (isElement) {
    if (
      sousOuvrageIndex != undefined &&
      sousOuvrageIndex >= 0 &&
      composantIndex != undefined &&
      composantIndex >= 0 &&
      elementIndex != undefined &&
      elementIndex >= 0 &&
      commentaireIndex != undefined &&
      commentaireIndex >= 0
    ) {
      const element =
        report.form.partiesVisitees?.[sousOuvrageIndex].composants[
          composantIndex
        ].elements[elementIndex]
      reportFolderPath = `${reportFolderPath}/IMAGES/${element?.elementId}`
    } else {
      console.error("ReportDropZone Error: Invalid props")
    }
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>Documents joints ({attachedFiles.length})</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Button
              className="refresh-button"
              variant={"link"}
              onClick={() => {
                updateAttachedFiles(
                  project.id,
                  report,
                  setAttachedFiles,
                  isElement,
                  sousOuvrageIndex,
                  composantIndex,
                  elementIndex,
                  commentaireIndex
                )
              }}
            >
              <Icon icon="refresh" />
            </Button>
            {attachedFiles.length > 0 ? (
              <Card.Body>
                <GedResourcesContainer
                  project={project}
                  resources={attachedFiles}
                  limit={50}
                  xs={4}
                  attachedFiles={isElement ? attachedFiles : undefined} // FORCE REFRESH
                />
                {attachedFiles.length > 0 ? (
                  <Link to={`../files/${reportFolderPath}`}>
                    <Button variant="link" className="ms-auto mt-auto d-block">
                      Accéder à tous les documents joints
                    </Button>
                  </Link>
                ) : null}
              </Card.Body>
            ) : null}

            <Card.Footer>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {droppedFiles.length > 0 ? (
                  <DroppedFilesDispatch.Provider
                    value={{ droppedFiles, droppedFilesDispatch }}
                  >
                    <Container fluid>
                      <Row className="justify-content-md-center file">
                        {droppedFiles.map((droppedFile, index) => {
                          return (
                            <Col key={`file-status-${index}`} xs={1}>
                              <StatusCard
                                project={project}
                                fileName={droppedFile.name}
                                fileBlob={droppedFile.blob}
                                key={`statusBar-${index}`}
                                index={index}
                                setAttachedFiles={setAttachedFiles}
                                isElement={isElement}
                                sousOuvrageIndex={sousOuvrageIndex}
                                composantIndex={composantIndex}
                                elementIndex={elementIndex}
                                commentaireIndex={commentaireIndex}
                                dispatch={dispatch}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </Container>
                  </DroppedFilesDispatch.Provider>
                ) : (
                  <p>
                    Glisser / Déploser des documents ici, ou cliquer pour les
                    choisir manuellement...
                  </p>
                )}
              </div>
            </Card.Footer>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

const StatusCard: FunctionComponent<StatusBarProps> = ({
  project,
  fileName,
  index,
  fileBlob,
  setAttachedFiles,
  isElement,
  sousOuvrageIndex,
  composantIndex,
  elementIndex,
  commentaireIndex,
  dispatch,
}) => {
  const [status, setStatus] = useState<FileStatus>("None")

  const { report } = useContext(ReportReducerDispatch)
  const { droppedFilesDispatch } = useContext(DroppedFilesDispatch)

  useEffect(() => {
    if (!report || !fileBlob || status == "Success" || status == "Uploading") {
      return
    }

    if (status == "Failure") {
      console.error(`Upload of file ${fileName} failed.`)
    }

    let reportFolderPath = generateReportFolderPath(
      report.title,
      report.id,
      report.type,
      report.indice
    )
    if (isElement) {
      if (
        sousOuvrageIndex != undefined &&
        sousOuvrageIndex >= 0 &&
        composantIndex != undefined &&
        composantIndex >= 0 &&
        elementIndex != undefined &&
        elementIndex >= 0 &&
        commentaireIndex != undefined &&
        commentaireIndex >= 0
      ) {
        const element =
          report.form.partiesVisitees?.[sousOuvrageIndex].composants[
            composantIndex
          ].elements[elementIndex]
        reportFolderPath = `${reportFolderPath}/IMAGES/${element?.elementId}`
      } else {
        console.error("ReportDropZone Error: Invalid props")
      }
    }
    setStatus("Uploading")
    getGedAPI()
      .getUploadUrl(project.id, `${reportFolderPath}/${fileName}`)
      .then((response) => {
        getPresignedApi()
          .uploadFile(response.url, fileBlob)
          .then(() => {
            setStatus("Success")
          })
          .catch((error) => {
            console.error(error)

            setStatus("Failure")
          })
      })
      .catch((error) => {
        console.error(error)

        setStatus("Failure")
      })
  }, [
    project,
    report,
    fileName,
    fileBlob,
    setStatus,
    status,
    commentaireIndex,
    composantIndex,
    elementIndex,
    isElement,
    sousOuvrageIndex,
  ])

  return (
    <Card
      onClick={(e) => {
        e.stopPropagation()
        if (
          report?.title &&
          report?.id &&
          report?.type != undefined &&
          report?.indice &&
          status !== "Uploading"
        ) {
          let reportFolderPath = generateReportFolderPath(
            report.title,
            report.id,
            report.type,
            report.indice
          )
          if (isElement) {
            if (
              sousOuvrageIndex != undefined &&
              sousOuvrageIndex >= 0 &&
              composantIndex != undefined &&
              composantIndex >= 0 &&
              elementIndex != undefined &&
              elementIndex >= 0 &&
              commentaireIndex != undefined &&
              commentaireIndex >= 0
            ) {
              const element =
                report.form.partiesVisitees?.[sousOuvrageIndex].composants[
                  composantIndex
                ].elements[elementIndex]
              reportFolderPath = `${reportFolderPath}/IMAGES/${element?.elementId}`
            } else {
              console.error("ReportDropZone Error: Invalid props")
            }
          }

          getGedAPI()
            .getFiles(project.id, `${reportFolderPath}/${fileName}`)
            .then((resp) => {
              getGedAPI()
                .deleteResource(project.id, resp)
                .then(() => {
                  updateAttachedFiles(
                    project.id,
                    report,
                    setAttachedFiles,
                    isElement,
                    sousOuvrageIndex,
                    composantIndex,
                    elementIndex,
                    commentaireIndex
                  )
                    .then(() => {})
                    .finally(() => {
                      if (
                        sousOuvrageIndex != undefined &&
                        sousOuvrageIndex >= 0 &&
                        composantIndex != undefined &&
                        composantIndex >= 0 &&
                        elementIndex != undefined &&
                        elementIndex >= 0 &&
                        commentaireIndex != undefined &&
                        commentaireIndex >= 0 &&
                        dispatch
                      ) {
                        dispatch({
                          type: "replacePartieVisiteeElementCommentaireFile",
                          subType: "remove",
                          partieVisiteeIndex: sousOuvrageIndex,
                          composantIndex: composantIndex,
                          elementIndex: elementIndex,
                          elementCommentaireIndex: commentaireIndex,
                          index: index,
                        })
                      }

                      droppedFilesDispatch({
                        type: "remove",
                        index: index,
                      })
                    })
                })
            })
        }
      }}
      className={status === "Uploading" ? "not-clickable" : ""}
    >
      <Card.Title>{fileName}</Card.Title>
      <Card.Text>
        <div>
          <StatusIcon fileStatus={status} />
        </div>
      </Card.Text>
    </Card>
  )
}

const StatusIcon: FunctionComponent<StatusIconProps> = ({ fileStatus }) => {
  switch (fileStatus) {
    case "None":
      return (
        <div className={"status-icon-container"}>
          <Icon icon={"remove_circle_outline"} className={"hover-on"} />
          <Icon icon={"check_circle_outline"} className={"hover-off "} />
        </div>
      )
    case "Success":
      return (
        <div className={"status-icon-container"}>
          <Icon icon={"remove_circle_outline"} className={"hover-on"} />
          <Icon icon={"check_circle_outline"} className={"hover-off "} />
        </div>
      )
    case "Failure":
      return (
        <div className={"status-icon-container"}>
          <Icon icon={"remove_circle_outline"} className={"hover-on"} />
          <Icon icon={"error"} className={"hover-off "} />
        </div>
      )
    case "Uploading":
      return (
        <div className={"status-icon-container"}>
          <Loader fullscreen />
        </div>
      )
  }

  console.error("[StatusIcon] Unknown file status: ", fileStatus)
  return (
    <div className={"status-icon-container"}>
      <Icon icon={"remove_circle_outline"} className={"hover-on"} />
      <Icon icon={"error"} className={"hover-off "} />
    </div>
  )
}

const updateAttachedFiles = (
  projectId: string,
  report: Report | undefined,
  setAttachedFiles: Dispatch<Resource[]>,
  isElement?: boolean,
  sousOuvrageIndex?: number,
  composantIndex?: number,
  elementIndex?: number,
  commentaireIndex?: number
) => {
  return new Promise<Resource[]>((resolve, reject) => {
    if (!report) {
      reject(undefined)
      return
    }

    let reportFolderPath = generateReportFolderPath(
      report.title,
      report.id,
      report.type,
      report.indice
    )
    if (isElement) {
      if (
        sousOuvrageIndex != undefined &&
        sousOuvrageIndex >= 0 &&
        composantIndex != undefined &&
        composantIndex >= 0 &&
        elementIndex != undefined &&
        elementIndex >= 0 &&
        commentaireIndex != undefined &&
        commentaireIndex >= 0
      ) {
        const element =
          report.form.partiesVisitees?.[sousOuvrageIndex].composants[
            composantIndex
          ].elements[elementIndex]
        reportFolderPath = `${reportFolderPath}/IMAGES/${element?.elementId}`
      } else {
        console.error("ReportDropZone Error: Invalid props")
      }
    }

    getGedAPI()
      .getFiles(projectId, reportFolderPath)
      .then((response) => {
        if (response.kind === "File") {
          console.error("[updateAttachedFiles] Report folder cannot be a file")
          reject(undefined)
          return
        }

        if (response.content == undefined) {
          reject(undefined)
          return
        }

        if (isElement) {
          if (
            report.form.partiesVisitees &&
            sousOuvrageIndex != undefined &&
            sousOuvrageIndex >= 0 &&
            composantIndex != undefined &&
            composantIndex >= 0 &&
            elementIndex != undefined &&
            elementIndex >= 0 &&
            commentaireIndex != undefined &&
            commentaireIndex >= 0
          ) {
            let nameMap: string[] = []
            const files =
              report.form.partiesVisitees[sousOuvrageIndex].composants[
                composantIndex
              ].elements[elementIndex].commentaires[commentaireIndex]
                .attachedFiles
            if (files != undefined && files.length > 0)
              nameMap = (
                report.form.partiesVisitees[sousOuvrageIndex].composants[
                  composantIndex
                ].elements[elementIndex].commentaires[commentaireIndex]
                  .attachedFiles as DroppedFiles
              ).map((f) => f.name)
            const filtered = response.content.filter((f) =>
              nameMap.includes(f.name)
            )
            setAttachedFiles(filtered)
            resolve(filtered)
            return
          } else {
            console.error("ReportDropZone Error: Invalid props")
            reject(undefined)
            return
          }
        }
        setAttachedFiles(response.content)
        resolve(response.content)
        return
      })
  })
}

export default ReportEditionDropZone

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import moment from "moment"
import numeral from "numeral"
import { FunctionComponent } from "react"
import { Alert, Table } from "react-bootstrap"
import { FileIcon } from "../../components/FileIcon/FileIcon"
import { File, Folder } from "../../utils/api"

export interface DialogBoxFolderViewProps {
  projectId: string
  folder?: Folder
  onFolderChange: (path: string) => void
  onBackClick: () => void
}

export const DialogBoxFolderView: FunctionComponent<
  DialogBoxFolderViewProps
> = ({ projectId, folder, onFolderChange, onBackClick }) => {
  const strings = {
    emptyFolder: "Ce dossier est vide",
    name: "Nom",
    lastEdit: "Dernière modification",
    childrenCount: "Nombre de fichiers",
    size: "Taille",
  }

  if (!folder || !folder.content || !projectId) return null

  const getColumnTitles = () => {
    return (
      <thead>
        <tr>
          <th scope="col">
            <Button
              startIcon={<ArrowBackIosIcon color="action" />}
              onClick={folder.path == "/" ? undefined : onBackClick}
              hidden={folder.path == "/"}
            />
          </th>
          <th scope="col">{strings.name}</th>
          <th className="d-none d-sm-table-cell" scope="col">
            {strings.lastEdit}
          </th>
          <th className="d-none d-sm-table-cell" scope="col">
            {strings.childrenCount}
          </th>
          <th className="d-none d-sm-table-cell" scope="col">
            {strings.size}
          </th>
          <th scope="col" />
        </tr>
      </thead>
    )
  }

  if (folder.content.length == 0)
    return (
      <div>
        <Table>{getColumnTitles()}</Table>
        <Box display={"flex"} sx={{ justifyContent: "center", padding: "1em" }}>
          <Alert variant="secondary">{strings.emptyFolder}</Alert>
        </Box>
      </div>
    )

  const onFolderClick = (event: any) => {
    if (!folder || !folder.content || folder.content.length == 0) return null
    const targetFolder = event.target.id
    if (!targetFolder) return null
    folder.content?.forEach((file) => {
      if (file.name == targetFolder) onFolderChange(file.path)
    })
  }

  const getFolderRow = (f: Folder) => {
    const getColumns = () => {
      return (
        <>
          <td id={f.name} className="icon">
            <FileIcon file={folder} />
          </td>
          <td id={f.name} className="name">
            {f.name}
          </td>
          <td id={f.name} className="last-modified d-none d-sm-table-cell">
            {moment(f.lastModified).fromNow()}
          </td>
          <td id={f.name} className="size d-none d-sm-table-cell">
            {f.childrenCount}
          </td>
          <td id={f.name} className="size d-none d-sm-table-cell" />
        </>
      )
    }

    return f.permissions?.canView ? (
      <tr
        key={"folder-" + f.name}
        onClick={onFolderClick}
        id={f.name}
        className="folder"
      >
        {getColumns()}
      </tr>
    ) : (
      <tr
        key={"folder-" + f.name}
        onClick={onFolderClick}
        id={f.name}
        className="folder"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          pointerEvents: "none",
        }}
      >
        {getColumns()}
      </tr>
    )
  }

  const getFileRow = (f: File) => {
    const getColumns = () => {
      return (
        <>
          <td className="icon">
            <FileIcon file={f} />
          </td>
          <td>{f.name}</td>
          <td className="last-modified d-none d-sm-table-cell">
            {moment(f.lastModified).fromNow()}
          </td>
          <td className="size d-none d-sm-table-cell" />
          <td className="size d-none d-sm-table-cell">
            {numeral(f.size).format("0.0 ib")}
          </td>
        </>
      )
    }
    return f.permissions?.canView ? (
      <tr key={"file-" + f.name} className={"file"}>
        {getColumns()}
      </tr>
    ) : (
      <tr
        key={"file-" + f.name}
        className={"file"}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          pointerEvents: "none",
        }}
      >
        {getColumns()}
      </tr>
    )
  }

  return (
    <>
      <Table>
        {getColumnTitles()}
        <tbody>
          {folder.content.map((f) => {
            switch (f.kind) {
              case "Folder":
                return getFolderRow(f)
              case "File":
                return getFileRow(f)
            }
          })}
        </tbody>
      </Table>
    </>
  )
}

import Box from "@mui/material/Box"
import { FunctionComponent } from "react"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { Report } from "../../types/report"
import { Project } from "../../utils/api"
import { DialogBox } from "../DialogBoxes/DialogBoxes"
import { CreateIndice } from "./CreateIndice"
import { DeleteIndice } from "./DeleteIndice"
import { DraftReport } from "./DraftReport"
import { ReportPermissions } from "./ReportPermissions"

export interface ReportToolbarProps {
  project: Project
  user: CognitoUserExt
  indices: Report[]
  report: Report
  dialogAction?: string
  setDialogActionState: (
    action: string | undefined,
    needUpdate: boolean | undefined
  ) => void
}

export const ReportToolbar: FunctionComponent<ReportToolbarProps> = ({
  project,
  user,
  report,
  dialogAction,
  setDialogActionState,
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <DialogBox
        projectId={project.id}
        user={user}
        action={dialogAction}
        setDialogActionState={setDialogActionState}
        dialogResource={report}
      />
      <CreateIndice
        project={project}
        report={report}
        handleDialogOpen={setDialogActionState}
      />
      <DraftReport
        project={project}
        report={report}
        handleDialogOpen={setDialogActionState}
      />
      <DeleteIndice
        project={project}
        report={report}
        handleDialogOpen={setDialogActionState}
      />
      <ReportPermissions
        project={project}
        report={report}
        handleDialogOpen={setDialogActionState}
      />
    </Box>
  )
}

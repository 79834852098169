import { FunctionComponent } from "react"
import { Spinner } from "react-bootstrap"

interface LoaderProps {
  fullscreen?: boolean
}

const Loader: FunctionComponent<LoaderProps> = ({ fullscreen }) => {
  return (
    <div
      className={`d-flex align-items-center loader-container ${
        fullscreen ? "w-100 h-100 justify-content-center" : ""
      }`}
    >
      <Spinner animation="border" role="status" className={"loader"}>
        <span className={"visually-hidden"}>Loading...</span>
      </Spinner>
    </div>
  )
}

export default Loader

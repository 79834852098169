import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react"
import { DocumentationLocker } from "../../components/DocumentationLocker/DocumentationLocker"
import Error from "../../components/Error/Error"
import {
  AddPermissionRow,
  PermissionRow,
  PermissionRowProps,
} from "../../components/PermissionRow/PermisisonRow"
import {
  getGedAPI,
  getMainAPI,
  Permission,
  PermissionType,
  Project,
  Roles,
} from "../../utils/api"

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export interface ProjectAdminProps {
  project: Project
}

export const ProjectAdminView: FunctionComponent<ProjectAdminProps> = ({
  project,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const [gedPermissions, setGedPermissions] = useState<Permission[]>()
  const [reportsPermissions, setReportsPermissions] = useState<Permission[]>()
  const [projectPermissions, setProjectPermissions] = useState<Permission[]>()
  const [isProjectLoading, setIsProjectLoading] = useState(false)
  const [isGedLoading, setIsGedLoading] = useState(false)
  const [isReportsLoading, setIsReportsLoading] = useState(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState(true)
  const [isError, setIsError] = useState(false)

  const strings = {
    mail: "Email",
    project: "Ouvrage",
    reports: "Inspections",
    ged: "Documentation",
    locker: "Verrouillage",
  }

  useEffect(() => {
    if (isNeedUpdate) {
      setIsNeedUpdate(false)
      setIsProjectLoading(true)
      setIsGedLoading(true)
      setIsReportsLoading(true)

      getMainAPI()
        .getAllProjectPermissions(project.id)
        .then((perms) => {
          setProjectPermissions(perms)
          setIsProjectLoading(false)
        })
        .catch(() => setIsError(true))

      getMainAPI()
        .getAllReportPermissions(project.id)
        .then((perms) => {
          setReportsPermissions(perms)
          setIsReportsLoading(false)
        })
        .catch(() => setIsError(true))

      getGedAPI()
        .getAllGedPermissions(project.id)
        .then((gedPermissions) => {
          setGedPermissions(gedPermissions)
          setIsGedLoading(false)
        })
        .catch(() => setIsError(true))
    }
  }, [project.id, isNeedUpdate])

  // TODO : ADD REAL NOT AHUTORIZED PAGE
  if (project.role != Roles.owner) return <p>NOT AUTHORIZED</p>

  if (isError) return <Error />

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={strings.project} {...a11yProps(0)} />
          <Tab label={strings.reports} {...a11yProps(1)} />
          <Tab label={strings.ged} {...a11yProps(2)} />
          <Tab label={strings.locker} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <Box sx={{ backgroundColor: "white", padding: "0.5em" }}>
          {isProjectLoading ? (
            <CircularProgress />
          ) : (
            <AddPermissionRow
              projectId={project.id}
              projectName={project.name}
              setIsNeedUpdate={setIsNeedUpdate}
              permissions={
                !isProjectLoading && projectPermissions
                  ? (projectPermissions as Permission[])
                  : undefined
              }
            />
          )}
        </Box>
        <hr />
        {isProjectLoading ? (
          <CircularProgress />
        ) : (
          <ProjectAdminPermissions
            projectId={project.id}
            label={strings.project}
            permissions={projectPermissions as Permission[]}
            kind={PermissionType.project}
            setIsNeedUpdate={setIsNeedUpdate}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {isReportsLoading ? (
          <CircularProgress />
        ) : (
          <ProjectAdminPermissions
            projectId={project.id}
            label={strings.reports}
            permissions={reportsPermissions as Permission[]}
            kind={PermissionType.report}
            setIsNeedUpdate={setIsNeedUpdate}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {isGedLoading ? (
          <CircularProgress />
        ) : (
          <ProjectAdminPermissions
            projectId={project.id}
            label={strings.ged}
            permissions={gedPermissions as Permission[]}
            kind={PermissionType.folder}
            setIsNeedUpdate={setIsNeedUpdate}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <DocumentationLocker project={project} />
      </TabPanel>
    </Box>
  )
}

export interface ProjectAdminPermissionsProps {
  projectId: string
  label: string
  permissions: Permission[]
  kind: PermissionType
  setIsNeedUpdate?: (b: boolean) => void
}

export const ProjectAdminPermissions: FunctionComponent<
  ProjectAdminPermissionsProps
> = ({ projectId, permissions, kind, setIsNeedUpdate }) => {
  const getRowProps = (permission: Permission) => {
    let permRowProps = {}

    if (kind == PermissionType.project)
      permRowProps = {
        id: permission.id,
        projectId: projectId,
        permission: permission,
        defaultUser: permission.principal,
        defaultRole: permission.role,
        disableMail: true,
        saveButton: true,
        deleteButton: true,
        setIsNeedUpdate: setIsNeedUpdate,
      }
    else if (kind == PermissionType.report)
      permRowProps = {
        id: permission.id,
        projectId: projectId,
        permission: permission,
        defaultUser: permission.principal,
        defaultRole: permission.role,
        showResource: true,
        disableMail: true,
        saveButton: true,
        deleteButton: true,
        setIsNeedUpdate: setIsNeedUpdate,
      }
    else if (kind == PermissionType.file || kind == PermissionType.folder)
      permRowProps = {
        id: permission.id,
        projectId: projectId,
        permission: permission,
        showResource: true,
        defaultUser: permission.principal,
        defaultRole: permission.role,
        disableMail: true,
        saveButton: true,
        deleteButton: true,
        setIsNeedUpdate: setIsNeedUpdate,
      }

    return permRowProps
  }

  const styles = {
    perms: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "auto",
    },
    perm: {
      backgroundColor: "white",
      margin: "0.3%",
      borderRadius: "10px",
    },
  }

  if (!permissions) return null

  return (
    <Box sx={styles.perms}>
      {permissions.map((permission) => {
        return (
          <Box key={permission.id} sx={styles.perm}>
            <PermissionRow
              key={permission.id}
              {...(getRowProps(permission) as PermissionRowProps)}
            />
          </Box>
        )
      })}
    </Box>
  )
}

import { useAuthenticator } from "@aws-amplify/ui-react"
import { url } from "gravatar"
import { forwardRef, FunctionComponent } from "react"
import { Dropdown, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import logo from "../../assets/setec-logo-small.png"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { isAdmin } from "../../utils/user"
import "./Topbar.scss"
import TopbarInbox from "./TopbarInbox"

export interface TopbarProps {
  signOut: ReturnType<typeof useAuthenticator>["signOut"]
  user: CognitoUserExt
}

const Topbar: FunctionComponent<TopbarProps> = ({ signOut, user }) => {
  const mail = user.attributes?.email || ""
  const avatar = url(mail, { d: "identicon" })
  const CustomToggle = forwardRef<any, any>(({ onClick }, ref) => (
    <img
      className="rounded-circle"
      src={avatar}
      style={{
        height: "45px",
        cursor: "pointer",
      }}
      alt="User Avatar"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    />
  ))

  return (
    <Navbar
      className="top-bar px-1 px-md-3"
      expand="lg"
      style={{ backgroundColor: "#ffffff" }}
    >
      <Link to="/home">
        <Navbar.Brand>
          <img
            className=""
            src={logo}
            style={{
              height: "45px",
            }}
            alt="Brand"
          />
        </Navbar.Brand>
      </Link>
      <TopbarInbox />
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          Custom toggle
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {!isAdmin(user) ? null : (
            <Dropdown.Item eventKey="2">
              <Link
                to="/admin"
                style={{ color: "#1e2125", textDecoration: "none" }}
              >
                Panel d'administration
              </Link>
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="1" onClick={signOut}>
            Se déconnecter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  )
}

export default Topbar

import moment from "moment"
import { ChangeEventHandler, FunctionComponent, useContext } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { Project } from "../../utils/api"
import { REPORT_TYPES } from "../../utils/report"
import { ReportReducerDispatch } from "./ReportEditionReportReducer"

interface ReportEditionHeaderProps {
  project: Project
}

const ReportEditionHeader: FunctionComponent<ReportEditionHeaderProps> = ({
  project,
}) => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)

  const setConditionClimatique: ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    dispatchReport({
      type: "replaceRootAttribute",
      key: "conditionsClimatiques",
      data: e.target.value,
    })
  }

  const setIntervenants: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    dispatchReport({
      type: "replaceRootAttribute",
      key: "intervenants",
      data: e.target.value,
    })
  }

  const setMoyensUtilises: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatchReport({
      type: "replaceRootAttribute",
      key: "moyensUtilises",
      data: e.target.value,
    })
  }

  const setStartDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const isoDate = e.target.valueAsDate?.toISOString() || ""

    dispatchReport({
      type: "replaceRootAttribute",
      key: "startDate",
      data: isoDate,
    })
  }

  const setEndDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const isoDate = e.target.valueAsDate?.toISOString() || ""

    dispatchReport({
      type: "replaceRootAttribute",
      key: "endDate",
      data: isoDate,
    })
  }

  if (!report) {
    return <></>
  }

  const reportCreationDateFormatted = moment(
    Date.parse(report.creationDate)
  ).format("DD-MM-YYYY hh:mm")

  let endDateFormatted
  if (report.form?.endDate) {
    endDateFormatted = moment(Date.parse(report.form?.endDate)).format(
      "YYYY-MM-DD"
    )
  }

  let startDateFormatted
  if (report.form?.startDate) {
    startDateFormatted = moment(Date.parse(report.form?.startDate)).format(
      "YYYY-MM-DD"
    )
  }

  const isDatesValid = () => {
    if (report.form.startDate && report.form.endDate) {
      if (report.form.startDate <= report.form.endDate) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  const isInvalid = !isDatesValid()

  return (
    <Card>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col className={"form-col"}>
              <div>Nom ouvrage : {project.name}</div>
            </Col>
            <Col className={"form-col"}>
              <div>Date Initialisation : {reportCreationDateFormatted}</div>
            </Col>
            <Col className={"form-col"}>
              Type de fiche: {REPORT_TYPES[report.type].name}
            </Col>
            <Col className={"flex form-col"}>
              <Form.Label className={"flex-label"}>Date de début :</Form.Label>
              <Form.Control
                type={"date"}
                name={"startDate"}
                className={"small-date"}
                placeholder="Date"
                value={startDateFormatted || ""}
                onChange={setStartDate}
                isInvalid={isInvalid}
                required
              />
            </Col>
            <Col className={"flex form-col"}>
              <Form.Label className={"flex-label"}>Date de fin :</Form.Label>
              <Form.Control
                type={"date"}
                name={"endDate"}
                className={"small-date"}
                placeholder="Date"
                value={endDateFormatted || ""}
                onChange={setEndDate}
                isInvalid={isInvalid}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 5 }} className={"form-col"}>
              <Form.Label className={"flex-label"}>Intervenant(s) :</Form.Label>
              <Form.Control
                name={"conditionClimatiques"}
                onChange={setIntervenants}
                value={report.form.intervenants || ""}
                as={"textarea"}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 5 }} className={"form-col"}>
              <Form.Label>Conditions Climatiques :</Form.Label>
              <Form.Control
                name={"conditionClimatiques"}
                onChange={setConditionClimatique}
                value={report.form.conditionsClimatiques || ""}
                as={"textarea"}
              />
            </Col>

            <Col xs={{ span: 5, offset: 1 }} className={"form-col"}>
              <Form.Label>Moyens Utilisés :</Form.Label>
              <Form.Control
                name={"moyensUtilises"}
                onChange={setMoyensUtilises}
                value={report.form.moyensUtilises || ""}
                as={"textarea"}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

export default ReportEditionHeader

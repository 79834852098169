import Box from "@mui/material/Box"
import { ChangeEventHandler, FunctionComponent, useContext } from "react"
import { Accordion, Card } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import { CustomToggle } from "./ReportEditionDropZone"
import { ReportReducerDispatch } from "./ReportEditionReportReducer"

interface ReportEditionConclusionProps {}

const ReportEditionConclusion: FunctionComponent<
  ReportEditionConclusionProps
> = () => {
  const { report, dispatchReport } = useContext(ReportReducerDispatch)

  const setCommentaireNoteIQOA: ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    dispatchReport({
      type: "replaceRootAttribute",
      key: "commentaireNoteIQOA",
      data: e.target.value,
    })
  }

  if (!report) {
    return <></>
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flexGrow: 1 }}>Conclusion</Box>
              {report && report.type <= 4 ? (
                <Box>
                  <BadgeIQOA note={report.form.noteIQOA} />
                </Box>
              ) : null}
            </Box>
          </Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <Card.Body>
              <Form.Label>Commentaire :</Form.Label>
              <Form.Control
                name={"conditionClimatiques"}
                onChange={setCommentaireNoteIQOA}
                value={report.form.commentaireNoteIQOA || ""}
                as={"textarea"}
              />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export default ReportEditionConclusion

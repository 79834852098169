import Box from "@mui/material/Box"
import { FunctionComponent } from "react"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { Folder, Resource } from "../../utils/api"
import { DialogBox } from "../DialogBoxes/DialogBoxes"
import { GedCreate } from "./GedCreate"
import { GedDownload } from "./GedDownload"
import { GedPermissions } from "./GedPermissions"
import { GedUpload } from "./GedUpload"

export interface GedToolbarProps {
  projectId: string
  folder: Folder
  user: CognitoUserExt
  dialogAction?: string
  setDialogActionState: (
    action: string | undefined,
    dialogResource: Resource | undefined
  ) => void
  dialogResource: Resource
  setFile?: (resource: Resource) => void
}

export const GedToolbar: FunctionComponent<GedToolbarProps> = ({
  projectId,
  folder,
  user,
  dialogAction,
  setDialogActionState,
  dialogResource,
  setFile,
}) => {
  const styles = {
    flexBox: { display: "flex", marginLeft: "0.4em" },
  }

  const handleDialogOpen = (action: string) => {
    setDialogActionState(action, folder)
  }

  if (folder == undefined) {
    return <Box sx={styles.flexBox} />
  }

  return (
    <Box sx={styles.flexBox}>
      <DialogBox
        projectId={projectId}
        folder={folder}
        user={user}
        action={dialogAction}
        setDialogActionState={setDialogActionState}
        dialogResource={dialogResource}
        setFile={setFile}
      />
      <GedCreate
        projectId={projectId}
        folder={folder}
        handleDialogOpen={handleDialogOpen}
      />
      <GedUpload
        projectId={projectId}
        folder={folder}
        handleDialogOpen={handleDialogOpen}
      />
      <GedDownload
        projectId={projectId}
        folder={folder}
        handleDialogOpen={handleDialogOpen}
      />
      <GedPermissions
        projectId={projectId}
        folder={folder}
        handleDialogOpen={handleDialogOpen}
      />
    </Box>
  )
}

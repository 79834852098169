import MoreVertIcon from "@mui/icons-material/MoreVert"
import Button from "@mui/material/Button"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { File, Folder, Resource } from "../../utils/api"
import { downloadFile } from "../../utils/ged"
import {
  DELETE_FILE_ACTION,
  DELETE_FOLDER_ACTION,
  DOWNLOAD_FOLDER_ACTION,
  MOVE_FILE_ACTION,
  MOVE_FOLDER_ACTION,
  RENAME_FILE_ACTION,
  RENAME_FOLDER_ACTION,
  SHARE_FILE_ACTION,
  SHARE_FOLDER_ACTION,
} from "./commons"

export interface GedMenuListProps {
  projectId: string
  kind: string
  file?: File
  folder?: Folder
  setDialogActionState: (
    action: string | undefined,
    dialogResource: Resource | undefined
  ) => void
}

export const GedMenuList: FunctionComponent<GedMenuListProps> = ({
  projectId,
  kind,
  file,
  folder,
  setDialogActionState,
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const strings = {
    rename: "Renommer",
    move: "Déplacer",
    download: "Télécharger",
    delete: "Supprimer",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
    locked: "Cette resource est verouillée.",
    share: "Permissions",
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const on_download = (event: Event | React.SyntheticEvent) => {
    if (kind === "file" && file != undefined) {
      downloadFile(projectId, file.name, file.path)
    } else if (kind === "folder" && folder != undefined) {
      setDialogActionState(DOWNLOAD_FOLDER_ACTION, folder)
    }
    handleClose(event)
  }

  const on_share = (event: Event | React.SyntheticEvent) => {
    if (kind === "file" && file != undefined) {
      setDialogActionState(SHARE_FILE_ACTION, file)
    } else if (kind === "folder" && folder != undefined) {
      setDialogActionState(SHARE_FOLDER_ACTION, folder)
    }
    handleClose(event)
  }

  const on_move = (event: Event | React.SyntheticEvent) => {
    if (kind === "file" && file != undefined) {
      setDialogActionState(MOVE_FILE_ACTION, file)
    } else if (kind === "folder" && folder != undefined) {
      setDialogActionState(MOVE_FOLDER_ACTION, folder)
    }
    handleClose(event)
  }

  const on_rename = (event: Event | React.SyntheticEvent) => {
    if (kind === "file" && file != undefined) {
      setDialogActionState(RENAME_FILE_ACTION, file)
    } else if (kind === "folder" && folder != undefined) {
      setDialogActionState(RENAME_FOLDER_ACTION, folder)
    }
    handleClose(event)
  }

  const on_delete = (event: Event | React.SyntheticEvent) => {
    if (kind === "file" && file != undefined) {
      setDialogActionState(DELETE_FILE_ACTION, file)
    } else if (kind === "folder" && folder != undefined) {
      setDialogActionState(DELETE_FOLDER_ACTION, folder)
    }
    handleClose(event)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const getTooltipTitle = (resource?: Resource) => {
    if (resource?.permissions?.canEdit && !resource?.isLock) {
      return false
    } else if (resource?.isLock) {
      return strings.locked
    }
    return strings.unauthorized
  }

  const getMenuList = () => {
    let resource: Resource | undefined = undefined
    if (kind == "file" && file) {
      resource = file
    } else if (kind == "folder" && folder) {
      resource = folder
    }

    return (
      <MenuList
        autoFocusItem={open}
        id="composition-menu"
        aria-labelledby="composition-button"
        onKeyDown={handleListKeyDown}
      >
        <Tooltip
          disableHoverListener={
            resource?.permissions?.canEdit && !resource?.isLock ? true : false
          }
          title={getTooltipTitle(resource)}
          arrow
          placement="left"
        >
          <span>
            <MenuItem
              disabled={
                resource?.permissions?.canEdit && !resource?.isLock
                  ? false
                  : true
              }
              onClick={on_rename}
            >
              {strings.rename}
            </MenuItem>
          </span>
        </Tooltip>

        <Tooltip
          disableHoverListener={
            resource?.permissions?.canEdit && !resource?.isLock ? true : false
          }
          title={getTooltipTitle(resource)}
          arrow
          placement="left"
        >
          <span>
            <MenuItem
              disabled={
                resource?.permissions?.canEdit && !resource?.isLock
                  ? false
                  : true
              }
              onClick={on_move}
            >
              {strings.move}
            </MenuItem>
          </span>
        </Tooltip>

        <Tooltip
          disableHoverListener={resource?.permissions?.canDownload}
          title={
            resource?.permissions?.canDownload ? false : strings.unauthorized
          }
          arrow
          placement="left"
        >
          <span>
            <MenuItem
              disabled={!resource?.permissions?.canDownload}
              onClick={on_download}
            >
              {strings.download}
            </MenuItem>
          </span>
        </Tooltip>

        <Tooltip
          disableHoverListener={
            resource?.permissions?.canEdit && !resource?.isLock ? true : false
          }
          title={getTooltipTitle(resource)}
          arrow
          placement="left"
        >
          <span>
            <MenuItem
              disabled={
                resource?.permissions?.canRemove && !resource?.isLock
                  ? false
                  : true
              }
              onClick={on_delete}
            >
              {strings.delete}
            </MenuItem>
          </span>
        </Tooltip>

        <Tooltip
          disableHoverListener={resource?.permissions?.canShare}
          title={resource?.permissions?.canShare ? false : strings.unauthorized}
          arrow
          placement="left"
        >
          <span>
            <MenuItem
              disabled={!resource?.permissions?.canShare}
              onClick={on_share}
            >
              {strings.share}
            </MenuItem>
          </span>
        </Tooltip>
      </MenuList>
    )
  }

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{
            zIndex: 1,
          }}
        >
          <MoreVertIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: 2,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  {getMenuList()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}

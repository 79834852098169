import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export const dialogTitleStyle = {
  textAlign: "center",
  fontWeight: 600,
  padding: "0.3em 0.3em 0.3em 0.3em",
  marginLeft: "0.8em",
  marginTop: "0.3em",
  marginBottom: "0.3em",
  marginRight: "0.8em",
  fontSize: "1.25rem",
}

export const getReadablePath = (currentPath: string): string => {
  if (currentPath === "/") return "  /  "
  const splitPaths = currentPath.split("/")
  let str: string = ""

  splitPaths.forEach(function (value) {
    str += value + "  /  "
  })
  return str
}

export const getCodeText = (str: string) => {
  return <code className="gl-white-space-pre-wrap">{str}</code>
}

export const isValidName = (str: string): boolean => {
  let count: number = 0

  if (str == undefined) return false
  for (let i = 0; i < str.length; i++) {
    if (str[i] == " ") count++
    if (count == str.length) return false
  }
  let regex = /^[^/\\<>{}*:?*"]{1,255}$/g
  return regex.test(str)
}

export const getDialogLoader = (message?: string) => {
  return (
    <div>
      {!message ? null : <DialogContent id={message}>{message}</DialogContent>}
      <Box sx={{ padding: "1em", display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    </div>
  )
}

export const getDialogError = (handleClose: () => void, error?: string) => {
  const style = { backgroundColor: "#fcf1ef", justifyContent: "center" }
  return (
    <div style={style}>
      <DialogTitle>{error ? error : "Une erreur s'est produite."}</DialogTitle>
      <DialogActions>
        <Button sx={{ border: "1px solid #1976d2" }} onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </div>
  )
}

import { Report } from "../../types/report"

export const SHARE_REPORT_ACTION = "shareReport"
export const CREATE_REPORT_INDICE_ACTION = "createReportIndice"
export const DRAFT_REPORT_INDICE_ACTION = "draftReport"
export const DELETE_REPORT_INDICE_ACTION = "deleteReport"

export interface Disabled {
  disabled: boolean
  message: string
}

export const isLastIndice = (report: Report, indices: Report[]) => {
  let highestIndice = "A"
  indices.forEach((indice) => {
    if (indice.indice.localeCompare(highestIndice))
      highestIndice = indice.indice
  })
  if (highestIndice == report.indice) return true
  return false
}

/* global Autodesk, THREE */

import {
  Dispatch,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Button } from "react-bootstrap"
import Icon from "../../components/Icon/Icon"
import {
  getGedAPI,
  getMainAPI,
  GetModelReportsResponse,
  ModelObject,
  Project,
  Resource,
} from "../../utils/api"
import ModelViewerInfo from "./ModelViewerInfo"

import { useLocation } from "react-router-dom"
import "./ModelViewer.scss"
import { getIdMapping } from "./utils"

const VIEWER_DIV_ID = "model-viewer"
const GLOBAL_OBJECT_ID = ".global"

const KEY_MAP: Record<string, boolean> = {}
const SWITCH_FORGE_TOOL_BAR_SHORTCUT = "KeyT"

const ID_ATTRIBUTE_NAME = "LcIFCProperty:IFCString"
const ID_DISPLAY_CATEGORY = "IFC"
const ID_DISPLAY_NAME = "GLOBALID"

const SELECTION_COLOR = new THREE.Color(0xff0000)
const EXTENDED_SELECTION_COLOR_VECTOR = new THREE.Vector4(1.0, 0.0, 0.0)
const SOFT_SELECTION_COLOR = new THREE.Color(0xff6666)
const HIGHLIGHT_COLOR_VECTOR = new THREE.Vector4(0.8, 0, 0, 0.8)
const SOFT_HIGHLIGHT_COLOR_VECTOR = new THREE.Vector4(0.9, 0.9, 0.9, 1)

// if the number of objects selected is higher, only the clicked object will be
// really selected and the rest will only be highlighted for performance reasons
// Some problematic selections (low number but complex geometry)
// will unfortunately bypass checks using this value
const SELECTION_TOO_BIG_THRESHOLD: number = 1000

interface ModelViewerProps {
  project: Project
  urn: string
  locationPath: string
}

interface ModelViewerReactSetters {
  setSelectedId: Dispatch<number | undefined>
  setObjectData: Dispatch<ModelObject | undefined>
  setObjectResources: Dispatch<Resource[]>
  setObjectIsLoading: Dispatch<boolean>
  setDisplayInfo: Dispatch<boolean>
  setHoveredId: Dispatch<number | undefined>
  setViewerIdMapping: Dispatch<Record<number, number[]>>
  setExtendedSelectionHideFlag: Dispatch<number | undefined>
  setExtendedSelectionDbIds: Dispatch<number[] | undefined>
}

const initializeView = (
  projectId: string,
  urn: string,
  reactSetters: ModelViewerReactSetters,
  loading: boolean = true
): Promise<Autodesk.Viewing.GuiViewer3D> => {
  const options: Autodesk.Viewing.InitializerOptions = {
    getAccessToken: (callback: (token: string, expires: number) => any) => {
      getMainAPI()
        .getBimToken(projectId)
        .then((token) => callback?.(token.access_token, token.expires_in))
    },
  }

  return new Promise((resolve) => {
    Autodesk.Viewing.Initializer(options, function () {
      const htmlDiv = document.getElementById(VIEWER_DIV_ID)
      const viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv!)
      const startedCode = viewer.start()
      resolve(viewer)
      if (startedCode > 0) {
        console.error("Failed to create a Viewer: WebGL not supported.")
      }
      if (loading) {
        loadModel(projectId, urn, viewer, reactSetters)
      }
    })
  })
}

const loadModel = (
  projectId: string,
  urn: string,
  viewer: Autodesk.Viewing.GuiViewer3D,
  reactSetters: ModelViewerReactSetters
) => {
  const onDocumentLoadSuccess = (doc: Autodesk.Viewing.Document) => {
    const node = doc.getRoot().getDefaultGeometry()
    doc.downloadAecModelData()
    viewer
      .loadDocumentNode(doc, node)
      .then(() => {
        configureViewer(viewer, reactSetters)

        loadModelElementRelationships(projectId, viewer, reactSetters)

        // By default, the toolbar is visible, this is not needed for this application.
        switchToolbarDisplay()
      })
      .catch((err) => {
        console.error("Could not load viewable: " + err)
      })
  }
  const onDocumentLoadFailure = (err: any) => {
    console.error("Could not load document: " + err)
  }

  Autodesk.Viewing.Document.load(
    "urn:" + urn,
    onDocumentLoadSuccess,
    onDocumentLoadFailure
  )
}

const loadModelElementRelationships = (
  projectId: string,
  viewer: Autodesk.Viewing.GuiViewer3D,
  reactSetters: ModelViewerReactSetters
) => {
  Promise.all([getMainAPI().getElements(projectId), getIdMapping(viewer)]).then(
    ([elements, mapping]) => {
      const viewerIdMapping = elements.elements.reduce((acc, current) => {
        const dbIds = current.objectIds
          .map((id) => mapping[id])
          .filter((dbId) => dbId)
        dbIds.forEach((dbId) => (acc[dbId] = dbIds))
        return acc
      }, {} as Record<number, number[]>)
      reactSetters.setViewerIdMapping(viewerIdMapping)
    }
  )
}

const configureViewer = (
  viewer: Autodesk.Viewing.GuiViewer3D,
  reactSetters: ModelViewerReactSetters
) => {
  viewer.setFocalLength(200)
  viewer.setQualityLevel(/* ambient shadows */ false, /* antialiasing */ true)
  viewer.setGroundShadow(false)
  viewer.setGhosting(false)
  viewer.setEnvMapBackground(false)
  viewer.setProgressiveRendering(false)
  viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.REGULAR)
  viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => {
    const ids = viewer.getSelection()
    if (!ids!.length) {
      reactSetters.setSelectedId(undefined)
      return
    }
    // user selected an item that belonged to a group
    // thus selecting the full group in the callback
    // we assume that multiple selected items can only come
    // from the selectedId callback
    if (ids.length > 1) {
      return
    }
    let selId: number = ids[0]
    reactSetters.setSelectedId(selId)
  })
  // Event for highlight handling. e is the event structure and contains
  // the dbId of new object
  viewer.addEventListener(Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED, (e) => {
    // get dbId of hovered object from event e
    const dbId = e.dbId
    reactSetters.setHoveredId(dbId)
  })

  viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, (e) => {
    const dbIds = e.nodeIdArray
    reactSetters.setExtendedSelectionHideFlag(dbIds[0])
  })
}

function getObjectData(
  projectId: string,
  objectId: string,
  reactSetters: ModelViewerReactSetters
): void {
  reactSetters.setObjectData(undefined)
  reactSetters.setObjectIsLoading(true)
  reactSetters.setObjectResources([])

  getMainAPI()
    .getObject(projectId, objectId)
    .then((objectData) => {
      reactSetters.setObjectData(objectData)
      reactSetters.setObjectIsLoading(false)
    })
    .catch(() => {
      reactSetters.setObjectIsLoading(false)
    })
}

const keyDownHandler = (event: KeyboardEvent) => {
  switch (event.code) {
    case SWITCH_FORGE_TOOL_BAR_SHORTCUT:
      KEY_MAP["SWITCH_FORGE_TOOL_BAR_SHORTCUT"] = true
      break
  }
}

const keyUpHandler = () => {
  if (KEY_MAP["SWITCH_FORGE_TOOL_BAR_SHORTCUT"]) {
    switchToolbarDisplay()
    KEY_MAP["SWITCH_FORGE_TOOL_BAR_SHORTCUT"] = false
  }
}

const switchToolbarDisplay = () => {
  const element = document.getElementById("guiviewer3d-toolbar")
  if (!element) {
    console.log("Hide toolbar: guiviewer3d-toolbar not found")
    return
  }

  if (element.style.display !== "none") {
    element.style.display = "none"
  } else {
    element.style.display = "block"
  }
}

const isModelPage = (locationPath: string): boolean => {
  let regex = /\/project\/.*\/model$/g
  return regex.test(locationPath)
}

const ModelViewer: FunctionComponent<ModelViewerProps> = ({
  project,
  urn,
  locationPath,
}) => {
  const [objTree, setObjTree] = useState<Autodesk.Viewing.InstanceTree>()
  const [ungroupedDbIds, setUngroupedDbIds] = useState<number[]>()
  const [extendedSelectionDbIds, setExtendedSelectionDbIds] =
    useState<number[]>()
  const [extendedSelectionHideFlag, setExtendedSelectionHideFlag] =
    useState<number>()
  const [viewer, setViewer] = useState<Autodesk.Viewing.GuiViewer3D>()
  const [objectData, setObjectData] = useState<ModelObject | undefined>()
  const [objectIsLoading, setObjectIsLoading] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [displayInfo, setDisplayInfo] = useState(false)
  const [objectResources, setObjectResources] = useState<Resource[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [hoveredId, setHoveredId] = useState<number>()
  const [currentPath, setCurrentPath] = useState<string>("")
  const [visible, setVisible] = useState<boolean>(true)
  const location = useLocation()
  const [reports, setReports] = useState<GetModelReportsResponse>()
  const [isLoading, setIsLoading] = useState(true)
  const divViewId = VIEWER_DIV_ID
  const [viewerIdMapping, setViewerIdMapping] = useState<
    Record<number, number[]>
  >({})
  const [isGlobalObject, setIsGlobalObject] = useState(true)
  const closeDisplayInfo = () => {
    setDisplayInfo(false)
  }

  // Debug function for obj hierarchy in model
  //const printHierarchy = useCallback(
  //  (startId: number) => {
  //    if (objTree) {
  //      const rootId: number = objTree.getRootId()
  //      let indent: string = ""
  //      let parentId: number = startId
  //      let startChildrenCount: number = objTree.getChildCount(startId)
  //      console.log("Start ID             : " + startId)
  //      console.log("Start Children count : " + startChildrenCount)
  //      while ((parentId = objTree.getNodeParentId(parentId)) != rootId) {
  //        indent = indent + "  "
  //        let childrenCount = objTree.getChildCount(parentId)
  //        console.log(indent + "Id         : " + parentId)
  //        console.log(indent + "Children   : " + childrenCount)
  //      }
  //    }
  //  },
  //  [objTree]
  //)

  const getRelatedDbIds = useCallback(
    (dbId: number): number[] | undefined => {
      if (objTree) {
        const rootId: number = objTree.getRootId()
        let currentId: number = dbId
        while (currentId != rootId) {
          if (currentId <= 0) {
            console.error(
              "ModelViewer: getRelatedDbIds: Unexpected error in the hierachical tree."
            )
            break
          }
          if (viewerIdMapping[currentId] != null) {
            return viewerIdMapping[currentId]
          }
          currentId = objTree.getNodeParentId(currentId)
        }
      }
      return undefined
    },
    [objTree, viewerIdMapping]
  )

  const getAllLeafDbIds = useCallback(
    (rootId: number): number[] | undefined => {
      const allDbIds: number[] = []
      if (!rootId) {
        return allDbIds
      }
      const queue: number[] = []
      queue.push(rootId)
      while (queue.length > 0) {
        const treeNodeId: number | undefined = queue.shift()
        if (!treeNodeId) continue
        if (objTree?.getChildCount(treeNodeId) == 0) allDbIds.push(treeNodeId)
        else {
          objTree?.enumNodeChildren(treeNodeId, function (childId) {
            queue.push(childId)
          })
        }
      }
      return allDbIds
    },
    [objTree]
  )

  const setters = useMemo<ModelViewerReactSetters>(() => {
    return {
      setObjectData,
      setObjectIsLoading,
      setDisplayInfo,
      setSelectedId,
      setObjectResources,
      setHoveredId,
      setViewerIdMapping,
      setExtendedSelectionHideFlag,
      setExtendedSelectionDbIds,
    }
  }, [
    setObjectData,
    setObjectIsLoading,
    setDisplayInfo,
    setSelectedId,
    setObjectResources,
    setHoveredId,
    setViewerIdMapping,
    setExtendedSelectionHideFlag,
    setExtendedSelectionDbIds,
  ])

  const goodCallBack = (objTreeSuccess: Autodesk.Viewing.InstanceTree) => {
    setObjTree(objTreeSuccess)
  }

  const badCallBack = (e: any) => {
    console.error("Failed to get objTree : " + e)
  }

  // Changing page event
  // Hiding model cause losing the current selection
  // because it unload it from the viewer and reset some setters.
  // Moreover, the OS can see that the OpenGL render
  // isn't in focus mode and idle the renderer.
  // So is not needed to hide the models in background.
  useEffect(() => {
    if (viewer && !isModelPage(currentPath)) {
      if (visible) {
        viewer.setNavigationLock(true)
        // viewer.hideModel(1)
        setVisible(false)
      }
    }
    if (viewer && isModelPage(currentPath)) {
      if (!visible) {
        // viewer.showModel(1, true)
        viewer.setNavigationLock(false)
        setVisible(true)
      }
    }
  }, [currentPath, viewer, visible])

  useEffect(() => {
    if (viewer && !objectIsLoading && !objTree) {
      viewer.getObjectTree(goodCallBack, badCallBack)
    }
  }, [viewer, objectIsLoading, objTree])

  useEffect(() => {
    if (ungroupedDbIds) return
    if (!objTree || !viewerIdMapping) return
    let unrelatedIds: number[] = []
    let rootNodeId: number = objTree.getRootId()
    const allDbids: number[] | undefined = getAllLeafDbIds(rootNodeId)

    allDbids?.forEach((elementId: number) => {
      const relatedIds = getRelatedDbIds(elementId)
      if (relatedIds === undefined) {
        unrelatedIds.push(elementId)
      }
    })
    setUngroupedDbIds(unrelatedIds)
  }, [
    objTree,
    ungroupedDbIds,
    viewerIdMapping,
    getRelatedDbIds,
    getAllLeafDbIds,
    viewer,
  ])

  useEffect(() => {
    if (!viewer && isModelPage(locationPath) && !isLoaded) {
      setIsLoaded(true)
      initializeView(project.id, urn, setters).then((view) => {
        setViewer(view)
      })
      window.addEventListener("keydown", keyDownHandler)
      window.addEventListener("keyup", keyUpHandler)
    } else {
      return
    }
  }, [viewer, locationPath, isLoaded, project.id, urn, setters])

  // Destroy viewer effect
  useEffect(() => {
    return () => {
      if (!viewer) return
      window.removeEventListener("keydown", keyDownHandler)
      window.removeEventListener("keyup", keyUpHandler)
      viewer.finish()
      Autodesk.Viewing.shutdown()
    }
  }, [viewer, project, urn, setters])

  // On Selected Id Changed Effet
  useEffect(() => {
    if (!viewer) {
      return
    }
    if (selectedId === undefined) {
      setIsGlobalObject(true)
      getObjectData(project.id, GLOBAL_OBJECT_ID, setters)
      return
    }
    setIsGlobalObject(false)
    let relatedDbIds = getRelatedDbIds(selectedId)
    const propertiesId: number =
      relatedDbIds === undefined ? 0 : relatedDbIds[0]
    if (relatedDbIds === undefined) {
      relatedDbIds = ungroupedDbIds
      viewer.setSelectionColor(
        SOFT_SELECTION_COLOR,
        Autodesk.Viewing.SelectionMode.REGULAR
      )
    } else {
      viewer.setSelectionColor(
        SELECTION_COLOR,
        Autodesk.Viewing.SelectionMode.REGULAR
      )
    }

    // spectial selection handling (checking if too many items to select for performance reasons)
    // if that is the case, only clicked item will be selected and the rest will only be highlighted
    let totalLeaves: number = 0
    relatedDbIds?.forEach((elementId: number) => {
      let leaves: number[] | undefined = getAllLeafDbIds(elementId)
      if (leaves != undefined) totalLeaves += leaves.length
    })
    if (totalLeaves > SELECTION_TOO_BIG_THRESHOLD) {
      setters.setExtendedSelectionHideFlag(-1)
      setters.setExtendedSelectionDbIds(relatedDbIds)
    } else {
      setters.setExtendedSelectionHideFlag(-1)
      setters.setExtendedSelectionDbIds([])
      viewer.select(
        relatedDbIds,
        viewer.model,
        Autodesk.Viewing.SelectionMode.REGULAR
      )
    }

    viewer.getProperties(propertiesId, ({ properties }) => {
      const ifcGlobalValues = properties.find(
        (p) =>
          p.attributeName === ID_ATTRIBUTE_NAME &&
          p.displayCategory === ID_DISPLAY_CATEGORY &&
          p.displayName === ID_DISPLAY_NAME
      )
      const objectId = ifcGlobalValues?.displayValue as string | undefined
      if (objectId) {
        setters.setDisplayInfo(true)
        return getObjectData(project.id, objectId, setters)
      } else {
        setters.setObjectData(undefined)
        setters.setObjectIsLoading(false)
      }
    })
  }, [
    selectedId,
    project,
    setters,
    viewer,
    viewerIdMapping,
    ungroupedDbIds,
    getRelatedDbIds,
    getAllLeafDbIds,
  ])

  // on hoverId change
  useEffect(() => {
    if (
      !viewer ||
      !viewer.model ||
      viewerIdMapping === undefined ||
      ungroupedDbIds === undefined
    )
      return
    viewer?.clearThemingColors(viewer.model)

    // refresh extended selection highlight that we might have erased
    extendedSelectionDbIds?.forEach((element) => {
      viewer.setThemingColor(
        element,
        EXTENDED_SELECTION_COLOR_VECTOR,
        viewer.model,
        true
      )
    })

    if (hoveredId === undefined || hoveredId === 0 || hoveredId === -1) return
    let hoverIds: number[] | undefined = getRelatedDbIds(hoveredId)
    let hoverColor: THREE.Vector4
    if (hoverIds === undefined) {
      hoverIds = ungroupedDbIds
      hoverColor = SOFT_HIGHLIGHT_COLOR_VECTOR
    } else {
      hoverColor = HIGHLIGHT_COLOR_VECTOR
    }
    hoverIds?.forEach((element) => {
      viewer.setThemingColor(element, hoverColor, viewer.model, true)
    })
  }, [
    hoveredId,
    project,
    setters,
    viewer,
    viewerIdMapping,
    ungroupedDbIds,
    extendedSelectionDbIds,
    extendedSelectionHideFlag,
    getRelatedDbIds,
  ])

  // for extended selection hide
  useEffect(() => {
    if (
      extendedSelectionDbIds === undefined ||
      extendedSelectionHideFlag === undefined ||
      !viewer ||
      !viewer.model
    )
      return
    if (extendedSelectionDbIds.length > 0 && extendedSelectionHideFlag != -1) {
      viewer.hide(extendedSelectionDbIds, viewer.model)
    }
  }, [setters, viewer, extendedSelectionDbIds, extendedSelectionHideFlag])

  // On Object Data Changed
  useEffect(() => {
    if (!objectData) {
      return
    }

    const mdValues = [
      objectData.SousOuvrage,
      `${objectData.SousOuvrage}/${objectData.Composant}`,
      `${objectData.SousOuvrage}/${objectData.Composant}/${objectData.Element}`,
    ]

    setIsLoading(true)
    getGedAPI()
      .searchByMetaData(project.id, "modelRefs", mdValues)
      .then((resources) => {
        setObjectResources(resources)
        getMainAPI()
          .getModelReports(
            project.id,
            isGlobalObject
              ? ".global"
              : [
                  objectData.SousOuvrage,
                  objectData.Composant,
                  objectData.Element,
                ]
                  .filter((e) => e)
                  .join("/")
          )
          .then((response) => {
            setReports(response)
            setIsLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setIsLoading(false)
          })
      })
  }, [objectData, project, isGlobalObject])

  if (location.pathname != currentPath) setCurrentPath(location.pathname)

  let viewerClass: string = "model-viewer-container"
  if (!isModelPage(location.pathname))
    viewerClass = "model-viewer-container-hidden"

  return (
    <div id={viewerClass}>
      <div className="model-viewer-info-container">
        {displayInfo && !objectIsLoading ? (
          <ModelViewerInfo
            project={project}
            data={objectData}
            loading={isLoading}
            reports={reports as GetModelReportsResponse}
            close={closeDisplayInfo}
            isGlobalObject={isGlobalObject}
            resources={objectResources}
          />
        ) : (
          <Button variant="light" onClick={() => setDisplayInfo(true)}>
            <Icon icon="info" />
          </Button>
        )}
      </div>
      <div id={divViewId} />
    </div>
  )
}

export default ModelViewer

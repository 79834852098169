import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import { Input } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import TextField from "@mui/material/TextField"
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Folder,
  getGedAPI,
  getPresignedApi,
  Resource,
  UploadLink,
  UploadResponse,
} from "../../utils/api"
import { sleep } from "../../utils/ged"
import {
  dialogTitleStyle,
  getDialogError,
  getReadablePath,
  isValidName,
} from "./commons"

export interface DialogBoxUploadProps {
  projectId: string
  kind: string
  resource: Resource
  accept?: string
  handleClose: () => void
  setFile?: (resource: Resource) => void
}

export const DialogBoxUpload: FunctionComponent<DialogBoxUploadProps> = ({
  projectId,
  kind,
  resource,
  accept,
  handleClose,
  setFile,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [source, setSource] = useState<FileList | null | undefined>(null)
  const [inputError, setInputError] = useState<string | undefined>(undefined)
  const [inputSource, setInputSource] = useState<string>("")
  const [folderNameSource, setFolderNameSource] = useState<string>()
  const [uploadCount, setUploadCount] = useState<number>(0)
  const [failedUploadCount, setFailedUploadCount] = useState<number>(0)

  const folder = resource as Folder

  const styles = {
    size: { minWidth: "40em" },
    dialogTitle: dialogTitleStyle,
    dialogContent: { paddingBottom: "0.3em", paddingTop: "0.3em" },
    destinationTitle: { fontWeight: "bold" },
    destination: { backgroundColor: "#edeff2", textAlign: "center" },
    textField: { marginRight: "0.5em" },
  }

  const strings = {
    dialogTitle: kind == "folder" ? "Upload un dossier" : "Upload des fichiers",
    destination: "Destination",
    buttonCancel: "Annuler",
    buttonUpload: "Confirmer",
    helperText:
      "Vos fichiers doivent respecter ces règles: 1 à 255 caractères, doit être unique et ne pas comporter les caractères suivants: / \\ : { } ? * < >",
    helperTextEmptyFolder:
      "Veuillez sélectionner un dossier qui n'est pas vide.",
    helperTextError: "Une erreur s'est produite.",
    helperTextSize: "",
    ongoingUpload: "L'upload est en cours. Merci de ne pas fermer cette page.",
    nameAlreadyTaken: (name: string) => {
      return 'Le nom : "' + name + '" existe déjà.'
    },
    invalidName: (name: string) => {
      return 'Le nom : "' + name + "\" n'est pas valide."
    },
  }

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute("directory", "")
      ref.current.setAttribute("webkitdirectory", "")
      ref.current.setAttribute("mozdirectory", "")
      ref.current.setAttribute("msdirectory", "")
      ref.current.setAttribute("odirectory", "")
    }
  }, [ref])

  const onUpload = () => {
    const successAction = async (
      source: FileList,
      response: UploadResponse
    ): Promise<void> => {
      const getUrl = (name: string, uploadLinks: UploadLink[]): string => {
        for (let i = 0; i < uploadLinks.length; i++) {
          if (uploadLinks[i].name == name) return uploadLinks[i].url
        }
        return ""
      }

      const refreshFolder = () => {
        if (setFile) {
          getGedAPI()
            .getFiles(projectId, folder.path, true)
            .then((file) => {
              setFile(file)
              setIsLoading(false)
              handleClose()
            })
            .catch((e) => {
              console.error(e)
              errorAction()
            })
        }
      }

      const resolvePromisesSeq = async (tasks: any[]) => {
        const results = []
        for (const task of tasks) {
          try {
            const res = await task
            if (res.status != 200) console.error("Upload failed: ", res)
            results.push(res)
            const failedCount = results.filter(
              (res) => res.status != 200
            ).length
            const uploadCount = results.length - failedCount
            setFailedUploadCount(failedCount)
            setUploadCount(uploadCount)
          } catch (err) {
            console.error("Upload failed: ", err)
            results.push({ status: 500, error: err })
            const failedCount = results.filter(
              (res) => res.status != 200
            ).length
            const uploadCount = results.length - failedCount
            setFailedUploadCount(failedCount)
            setUploadCount(uploadCount)
          }
        }

        return results
      }

      const promises = []

      for (let i = source.length - 1; i >= 0; i--) {
        promises.push(
          getPresignedApi().uploadFile(
            getUrl(
              source[i].webkitRelativePath
                ? source[i].webkitRelativePath.substring(
                    (folderNameSource?.length as number) + 1
                  )
                : source[i].name,
              response.uploadLinks
            ),
            source[i]
          )
        )
      }

      await resolvePromisesSeq(promises)
      // Sleeping because indexes are created by an asyncrounous S3 event
      sleep(3000).then(() => {
        setIsLoading(false)
        handleClose()
        refreshFolder()
      })
    }

    const errorAction = () => {
      setError(true)
      setIsLoading(false)
    }

    setIsLoading(true)

    let paginatedFileList: FileList[] = []
    const arrayFileList = Array.prototype.slice.call(source)

    const chunkSize = 100

    for (let i = 0; i < arrayFileList.length; i += chunkSize) {
      const chunk = arrayFileList.slice(i, i + chunkSize)
      const dt = new DataTransfer()
      chunk.forEach((f) => {
        dt.items.add(f)
      })
      paginatedFileList.push(dt.files)
    }

    const promises = paginatedFileList.map((fileList) => {
      return getGedAPI().getUploadUrls(
        projectId,
        resource.path,
        kind,
        fileList,
        folderNameSource
      )
    })

    let uploadLinks: UploadResponse = {
      uploadLinks: [],
    }

    Promise.all(promises)
      .then((results) => {
        results.forEach((resp) => {
          uploadLinks.uploadLinks = uploadLinks.uploadLinks.concat(
            resp.uploadLinks
          )
        })
        if (source) successAction(source, uploadLinks)
        else errorAction()
      })
      .catch((err) => {
        console.error(err)
        errorAction()
      })
  }

  const isActionValid = (): boolean => {
    const validateFileUpload = (): boolean => {
      const filesName = folderNameSource?.split(" ; ")

      filesName?.forEach((element) => {
        if (!isValidName(element)) return false
      })

      return true
    }

    const validateFolderUpload = (): boolean => {
      if (source === undefined) {
        if (!inputError) setInputError(strings.helperTextEmptyFolder)
        return false
      } else if (!isValidName(folderNameSource as string)) {
        if (!inputError) setInputError(strings.helperText)
        return false
      }
      return true
    }

    const isSourceValid = (): boolean => {
      if (kind == "file") return validateFileUpload()
      else if (kind == "folder") return validateFolderUpload()
      return false
    }

    const isNameAlreadyTaken = (
      source: File,
      content: Resource[],
      parentFolderName?: string
    ): boolean => {
      if (parentFolderName) {
        for (let i = 0; i < content.length; i++) {
          if (content[i].name == parentFolderName) return true
        }
      }
      for (let i = 0; i < content.length; i++) {
        if (source.webkitRelativePath) {
          return false
        }
        if (source.name == content[i].name) return true
      }
      return false
    }

    if (inputSource == "") return false

    if (source && isSourceValid()) {
      for (let i = 0; i < source.length; i++) {
        if (!isValidName(source[i].name)) {
          if (!inputError) setInputError(strings.invalidName(source[i].name))
          return false
        }
        if (folder.content && folder.content.length > 0) {
          if (kind == "folder") {
            const srcFolderName = source[0].webkitRelativePath?.split("/")[0]
            if (isNameAlreadyTaken(source[i], folder.content, srcFolderName)) {
              if (!inputError)
                setInputError(strings.nameAlreadyTaken(srcFolderName))
              return false
            }
          }
          if (isNameAlreadyTaken(source[i], folder.content)) {
            if (!inputError)
              setInputError(strings.nameAlreadyTaken(source[i].name))
            return false
          }
        }
      }
    }
    return true
  }

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputSource("")
    setInputError(undefined)
    if (event.target.files && event.target.files.length > 0) {
      setSource(event.target.files)
      let input: string = ""
      for (let i = 0; i < event.target.files.length; i++) {
        input += folder.path + "/" + event.target.files[i].name + " ; "
      }
      setInputSource(input)
      return
    }
    setInputError("An unexpected error happened")
  }

  const onFolderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputSource("")
    setInputError(undefined)
    if (event.target.files && event.target.files.length > 0) {
      setSource(event.target.files)
      const srcFolderName =
        event.target.files[0].webkitRelativePath.split("/")[0]
      setFolderNameSource(srcFolderName)
      let inputPath
      if (srcFolderName)
        inputPath =
          folder.path == "/"
            ? folder.path + srcFolderName
            : folder.path + "/" + srcFolderName
      else inputPath = ""
      setInputSource(inputPath)
    } else {
      setSource(undefined)
    }
  }

  const getUploadInput = () => {
    if (kind == "file") {
      return (
        <div>
          <Input
            type="file"
            hidden
            onChange={onFilesChange}
            inputProps={{
              multiple: true,
              accept: getInputAcceptProp(),
            }}
          />
        </div>
      )
    } else {
      return (
        <Input
          hidden
          type="file"
          onChange={onFolderChange}
          inputProps={{
            ref: ref,
          }}
        />
      )
    }
  }

  const getInputAcceptProp = (): string => {
    if (accept) return accept
    return "*"
  }

  const getTextFieldHelper = (): string => {
    if (inputError) return inputError
    else if (isActionValid()) return ""
    else if (kind == "file") return strings.helperTextSize
    else if (kind == "folder") return strings.helperText
    return strings.helperTextError
  }

  if (isLoading) {
    const message = `${strings.ongoingUpload}\n Upload(s): ${uploadCount} | Echec(s): ${failedUploadCount}`
    return (
      <div>
        {!message ? null : (
          <DialogContent id={message}>{message}</DialogContent>
        )}
        <Box sx={{ padding: "1em", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </div>
    )
  }

  if (error) {
    return getDialogError(handleClose)
  }

  return (
    <div style={styles.size}>
      <DialogTitle sx={styles.dialogTitle}>{strings.dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.destinationTitle}>{strings.destination}:</Box>
          <Box sx={styles.destination}>{getReadablePath(folder.path)}</Box>
        </DialogContent>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={{ display: "flex" }}>
            <TextField
              error={inputError ? true : false}
              disabled
              autoFocus
              margin="dense"
              id="files"
              type="text"
              fullWidth
              value={inputSource}
              variant="standard"
              helperText={getTextFieldHelper()}
              sx={styles.textField}
            />
            <Button variant="contained" component="label" sx={{ height: "" }}>
              <FolderOpenIcon />
              {getUploadInput()}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.buttonCancel}</Button>
          <Button disabled={!isActionValid()} onClick={onUpload}>
            {strings.buttonUpload}
          </Button>
        </DialogActions>
      </List>
    </div>
  )
}

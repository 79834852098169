import { createContext, FunctionComponent } from "react"
import Loader from "../../components/Loader/Loader"
import { SubmittedReport } from "../../types/report"
import { Project } from "../../utils/api"
import ReportConsultationHeader from "./ReportConsultationHeader"

import ReportConsultationConclusion from "./ReportConsultationConclusion"
import { ReportConsultationFiles } from "./ReportConsultationFiles"
import ReportConsultationPartiesNonVisitees from "./ReportConsultationPartiesNonVisitees"
import ReportConsultationPartiesVisitees from "./ReportConsultationPartiesVisitees"
import "./ReportConsultationView.scss"

interface ReportConsultationViewProps {
  project: Project
  report: SubmittedReport
  visible: boolean
}

export const ReportContext = createContext<{
  report: SubmittedReport | undefined
}>({
  report: undefined,
})

const ReportConsultationView: FunctionComponent<
  ReportConsultationViewProps
> = ({ project, report, visible }) => {
  if (!report || !visible) {
    return <Loader fullscreen />
  }

  const headerPrefix = `${project.name} - ${report.title} - Consultation formulaire`

  document.title = headerPrefix + " - StructuralTwin"

  return (
    <>
      <div className={"report-consultation"}>
        <ReportContext.Provider value={{ report }}>
          <ReportConsultationHeader project={project} />
          <ReportConsultationFiles project={project} />
          <ReportConsultationPartiesNonVisitees />
          <ReportConsultationPartiesVisitees project={project} />
          <ReportConsultationConclusion />
        </ReportContext.Provider>
      </div>
    </>
  )
}

export default ReportConsultationView

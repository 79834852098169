import { FunctionComponent, useContext, useEffect, useState } from "react"
import { Accordion, Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import GedResourcesContainer from "../../components/GedResource/GedResourcesContainer"
import { getGedAPI, Project, Resource } from "../../utils/api"
import { generateReportFolderPath } from "../../utils/report"
import {
  CustomToggle,
  DroppedFiles,
} from "../ReportEditionView/ReportEditionDropZone"
import { ReportContext } from "./ReportConsultationView"

interface ReportConsultationElementCommentFilesProps {
  project: Project
  SOIndex: number
  composantIndex: number
  elementIndex: number
  commentaireIndex: number
}

export const ReportConsultationElementCommentFiles: FunctionComponent<
  ReportConsultationElementCommentFilesProps
> = ({ project, SOIndex, composantIndex, elementIndex, commentaireIndex }) => {
  const [attachedFiles, setAttachedFiles] = useState<Array<Resource>>([])
  const { report } = useContext(ReportContext)

  useEffect(() => {
    if (!report) {
      return
    }

    let reportFolderPath = generateReportFolderPath(
      report.title,
      report.id,
      report.type,
      report.indice
    )

    const element =
      report.form.partiesVisitees?.[SOIndex].composants[composantIndex]
        .elements[elementIndex]
    reportFolderPath = `${reportFolderPath}/IMAGES/${element?.elementId}`

    getGedAPI()
      .getFiles(report.projectId, reportFolderPath)
      .then((response) => {
        if (response.kind === "File") {
          console.error("[updateAttachedFiles] Report folder cannot be a file")
          return
        }

        if (response.content == undefined) {
          return
        }
        let nameMap: string[] = []
        nameMap = (
          report.form.partiesVisitees?.[SOIndex].composants[composantIndex]
            .elements[elementIndex].commentaires[commentaireIndex]
            .attachedFiles as DroppedFiles
        ).map((f) => f.name)
        const filtered = response.content.filter((f) =>
          nameMap.includes(f.name)
        )

        setAttachedFiles(filtered)
      })
  }, [
    project.id,
    report,
    setAttachedFiles,
    SOIndex,
    commentaireIndex,
    composantIndex,
    elementIndex,
  ])

  if (!report) {
    return <></>
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>Documents joints ({attachedFiles.length})</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <GedResourcesContainer
              project={project}
              resources={attachedFiles}
              limit={50}
              xs={4}
            />
            {attachedFiles.length > 0 ? (
              <Link
                to={`../files/${generateReportFolderPath(
                  report.title,
                  report.id,
                  report.type,
                  report.indice
                )}`}
              >
                <Button variant="link" className="ms-auto mt-auto d-block">
                  Accéder à tous les documents joints
                </Button>
              </Link>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

interface ReportConsultationFilesProps {
  project: Project
}

export const ReportConsultationFiles: FunctionComponent<
  ReportConsultationFilesProps
> = ({ project }) => {
  const [attachedFiles, setAttachedFiles] = useState<Array<Resource>>([])
  const { report } = useContext(ReportContext)

  useEffect(() => {
    if (!report) {
      return
    }

    getGedAPI()
      .getFiles(
        report.projectId,
        generateReportFolderPath(
          report.title,
          report.id,
          report.type,
          report.indice
        )
      )
      .then((response) => {
        if (response.kind === "File") {
          console.error("[updateAttachedFiles] Report folder cannot be a file")
          return
        }

        if (response.content == undefined) {
          return
        }

        setAttachedFiles(response.content)
      })
  }, [project.id, report, setAttachedFiles])

  if (!report) {
    return <></>
  }

  return (
    <Accordion>
      <Card>
        <CustomToggle eventKey="0">
          <Card.Header>Documents joints ({attachedFiles.length})</Card.Header>
        </CustomToggle>
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <GedResourcesContainer
              project={project}
              resources={attachedFiles}
              limit={50}
              xs={4}
            />
            {attachedFiles.length > 0 ? (
              <Link
                to={`../files/${generateReportFolderPath(
                  report.title,
                  report.id,
                  report.type,
                  report.indice
                )}`}
              >
                <Button variant="link" className="ms-auto mt-auto d-block">
                  Accéder à tous les documents joints
                </Button>
              </Link>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

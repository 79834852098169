import docx from "../../assets/file-icons/files icon-docx.png"
import folder from "../../assets/file-icons/files icon-folder.png"
import music from "../../assets/file-icons/files icon-music.png"
import other from "../../assets/file-icons/files icon-other.png"
import pdf from "../../assets/file-icons/files icon-pdf.png"
import picture from "../../assets/file-icons/files icon-picture.png"
import ppt from "../../assets/file-icons/files icon-ppt.png"
import video from "../../assets/file-icons/files icon-video.png"
import xls from "../../assets/file-icons/files icon-xls.png"
import zip from "../../assets/file-icons/files icon-zip.png"
import { Resource } from "../../utils/api"

type PNGIcon = string

const DEFAULT_ICON = other
const ICONS_MAP = [
  [picture, "svg", "jpg", "jpeg", "png", "img", "tif", "tiff", "gif"],
  [docx, "txt", "docx", "md", "odt", "rtf"],
  [music, "mp3", "mid", "aac", "flac", "wav"],
  [pdf, "pdf"],
  [ppt, "ppt", "pttx", "pptm"],
  [video, "mp4", "mov", "wmv", "avi"],
  [xls, "xls", "xlsm", "xlsb", "xltx", "csv"],
  [zip, "zip", "rar", "7z", "tar"],
].reduce((map, [icon, ...extensions]) => {
  extensions.forEach((extension) => (map[extension] = icon))
  return map
}, {} as Record<string, PNGIcon>)

interface FileIconProps {
  file: Resource
  width?: string
}

export const FileIcon = ({ file, width }: FileIconProps) => {
  const w = width || "30px"
  if (file.kind === "Folder") {
    return <img alt="icon" width={w} src={folder} />
  }
  const fragments = file.name.split(".")
  const extension = (fragments.length > 0 &&
    fragments[fragments.length - 1]) as string
  const icon = ICONS_MAP[extension?.toLowerCase()] || DEFAULT_ICON
  return <img alt="icon" width={w} src={icon} />
}

import { FunctionComponent, useEffect, useState } from "react"
import { Placement } from "react-bootstrap/esm/types"
import { Link, useHistory } from "react-router-dom"
import { getGedAPI, Resource, SignedUrlInfo } from "../../utils/api"
import { downloadFile } from "../../utils/ged"
import useWindowDimensions from "../../utils/viewport"
import { FileIcon } from "../FileIcon/FileIcon"
import { ImageViewerTooltip } from "../ImageViewerTooltip/ImageViewerTooltip"
import "./GedResource.scss"

export interface GedResourceProps {
  resource: Resource
  projectId: string
  tooltipPreview?: boolean
  tooltipPlacement?: Placement
}

export const allowedImageType = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "PNG",
  "JPG",
  "JPEG",
  "GIF",
]

const GedResource: FunctionComponent<GedResourceProps> = ({
  resource,
  projectId,
  tooltipPreview,
  tooltipPlacement,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const isFile = resource.kind == "File"
  const paths = resource.name.split(".")
  const extension = isFile ? paths[paths.length - 1] : ""
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    setImageUrl("")
  }, [resource])

  useEffect(() => {
    if (
      tooltipPreview &&
      resource.kind == "File" &&
      allowedImageType.includes(extension) &&
      !isLoading &&
      imageUrl == ""
    ) {
      setIsLoading(true)
      getGedAPI()
        .getDownloadUrl(projectId, resource.path)
        .then((resp) => {
          setImageUrl((resp as SignedUrlInfo).url)
          setIsLoading(false)
        })
    }
  }, [
    projectId,
    isLoading,
    resource.kind,
    resource.name,
    resource.path,
    extension,
    imageUrl,
    tooltipPreview,
  ])

  const getTooltipContent = () => {
    return (
      <>
        <FileIcon file={resource} />
        <span className="ged-resource__name">{resource.name}</span>
      </>
    )
  }

  if (tooltipPreview && !allowedImageType.includes(extension)) {
    return (
      <div
        className="ged-resource ged-resource--file"
        onClick={() =>
          isFile
            ? downloadFile(projectId, resource.name, resource.path)
            : history.push(`/project/${projectId}/files` + resource.path)
        }
      >
        <span className="ged-ressource__icon">{getTooltipContent()}</span>
      </div>
    )
  }

  if (resource.kind == "File" && tooltipPreview) {
    return (
      <div
        className="ged-resource ged-resource--file"
        onClick={() => downloadFile(projectId, resource.name, resource.path)}
      >
        <span className="ged-ressource__icon">
          <ImageViewerTooltip
            fileName={resource.name}
            imageUrl={imageUrl}
            content={getTooltipContent()}
            loading={isLoading}
            width={width * 0.3}
            height={height * 0.35}
            placement={tooltipPlacement ? tooltipPlacement : "top"}
          />
        </span>
      </div>
    )
  }

  return (
    <div className="ged-resource ged-resource--folder position-relative">
      <span className="ged-ressource__icon">
        <FileIcon file={resource} />
      </span>
      <span className="ged-resource__name" title={resource.name}>
        <Link
          to={`/project/${projectId}/files${resource.path}`}
          className="stretched-link"
        >
          {resource.name}
        </Link>
      </span>
    </div>
  )
}

export default GedResource

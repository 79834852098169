import Dialog from "@mui/material/Dialog"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { FunctionComponent } from "react"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import { Report } from "../../types/report"
import { File, Folder, Resource } from "../../utils/api"
import {
  CREATE_FOLDER_ACTION,
  DELETE_FILE_ACTION,
  DELETE_FOLDER_ACTION,
  DOWNLOAD_FOLDER_ACTION,
  MOVE_FILE_ACTION,
  MOVE_FOLDER_ACTION,
  RENAME_FILE_ACTION,
  RENAME_FOLDER_ACTION,
  SHARE_FILE_ACTION,
  SHARE_FOLDER_ACTION,
  UPLOAD_FILE_ACTION,
  UPLOAD_FOLDER_ACTION,
} from "../GedOptions/commons"
import {
  CREATE_REPORT_INDICE_ACTION,
  DELETE_REPORT_INDICE_ACTION,
  DRAFT_REPORT_INDICE_ACTION,
  SHARE_REPORT_ACTION,
} from "../ReportOptions/commons"
import { DialogBoxCreate } from "./DialogBoxCreate"
import { DialogBoxDelete } from "./DialogBoxDelete"
import { DialogBoxDeleteIndice } from "./DialogBoxDeleteIndice"
import { DialogBoxDownload } from "./DialogBoxDownload"
import { DialogBoxDraft } from "./DialogBoxDraft"
import { DialogBoxMove } from "./DialogBoxMove"
import { DialogBoxNewIndice } from "./DialogBoxNewIndice"
import { DialogBoxPermissions } from "./DialogBoxPermissions"
import { DialogBoxRename } from "./DialogBoxRename"
import { DialogBoxUpload } from "./DialogBoxUpload"

export interface DialogBoxProps {
  projectId: string
  folder?: Folder
  report?: Report
  user: CognitoUserExt
  action?: string
  setDialogActionState:
    | ((
        action: string | undefined,
        dialogResource: Resource | undefined
      ) => void)
    | ((action: string | undefined, needUpdate: boolean | undefined) => void)
  dialogResource?: Resource | Report
  setFile?: (resource: Resource) => void
}

export const DialogBox: FunctionComponent<DialogBoxProps> = ({
  projectId,
  folder,
  user,
  action,
  setDialogActionState,
  dialogResource,
  setFile,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const handleClose = (_?: any, reason?: string) => {
    if (reason && reason == "backdropClick") return
    if (action == SHARE_REPORT_ACTION)
      (
        setDialogActionState as (
          action: string | undefined,
          needUpdate: boolean | undefined
        ) => void
      )(undefined, false)
    else if (
      action == CREATE_REPORT_INDICE_ACTION ||
      action == DRAFT_REPORT_INDICE_ACTION ||
      DELETE_REPORT_INDICE_ACTION
    )
      (
        setDialogActionState as (
          action: string | undefined,
          needUpdate: boolean | undefined
        ) => void
      )(undefined, true)
    setDialogActionState(undefined, undefined)
  }

  const getDialogContent = () => {
    switch (action) {
      case undefined:
        return <div />
      case CREATE_FOLDER_ACTION:
        return (
          <DialogBoxCreate
            projectId={projectId}
            dstFolder={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case MOVE_FOLDER_ACTION:
        return (
          <DialogBoxMove
            projectId={projectId}
            kind={"folder"}
            folder={folder as Folder}
            resource={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case MOVE_FILE_ACTION:
        return (
          <DialogBoxMove
            projectId={projectId}
            kind={"file"}
            folder={folder as Folder}
            resource={dialogResource as File}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case UPLOAD_FOLDER_ACTION:
        return (
          <DialogBoxUpload
            projectId={projectId}
            kind={"folder"}
            resource={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case UPLOAD_FILE_ACTION:
        return (
          <DialogBoxUpload
            projectId={projectId}
            kind={"file"}
            resource={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case DOWNLOAD_FOLDER_ACTION:
        return (
          <DialogBoxDownload
            projectId={projectId}
            folder={dialogResource as Folder}
            handleClose={handleClose}
          />
        )
      case RENAME_FOLDER_ACTION:
        return (
          <DialogBoxRename
            projectId={projectId}
            kind={"folder"}
            folder={folder as Folder}
            resource={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case RENAME_FILE_ACTION:
        return (
          <DialogBoxRename
            projectId={projectId}
            kind={"file"}
            folder={folder as Folder}
            resource={dialogResource as File}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case DELETE_FOLDER_ACTION:
        return (
          <DialogBoxDelete
            projectId={projectId}
            kind={"folder"}
            folder={folder as Folder}
            resource={dialogResource as Folder}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case DELETE_FILE_ACTION:
        return (
          <DialogBoxDelete
            projectId={projectId}
            kind={"file"}
            folder={folder as Folder}
            resource={dialogResource as File}
            handleClose={handleClose}
            setFile={setFile}
          />
        )
      case SHARE_FILE_ACTION:
        return (
          <DialogBoxPermissions
            projectId={projectId}
            currentUser={user}
            resource={dialogResource as File}
            handleClose={handleClose}
          />
        )
      case SHARE_FOLDER_ACTION:
        return (
          <DialogBoxPermissions
            projectId={projectId}
            currentUser={user}
            resource={dialogResource as Folder}
            handleClose={handleClose}
          />
        )
      case SHARE_REPORT_ACTION:
        return (
          <DialogBoxPermissions
            projectId={projectId}
            currentUser={user}
            resource={dialogResource as Report}
            handleClose={handleClose}
            isReports={true}
          />
        )
      case CREATE_REPORT_INDICE_ACTION:
        return (
          <DialogBoxNewIndice
            projectId={projectId}
            report={dialogResource as Report}
            handleClose={handleClose}
          />
        )
      case DRAFT_REPORT_INDICE_ACTION:
        return (
          <DialogBoxDraft
            projectId={projectId}
            report={dialogResource as Report}
            handleClose={handleClose}
          />
        )
      case DELETE_REPORT_INDICE_ACTION:
        return (
          <DialogBoxDeleteIndice
            projectId={projectId}
            report={dialogResource as Report}
            handleClose={handleClose}
          />
        )
    }
  }

  if (!dialogResource) {
    return <div />
  }

  return (
    <Dialog
      onClose={handleClose}
      open={action != undefined ? true : false}
      maxWidth={false}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      {getDialogContent()}
    </Dialog>
  )
}

import { FunctionComponent, ReactElement } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/types"
import Loader from "../Loader/Loader"
// import "./style.scss";

export interface ImageViewerTooltipProps {
  fileName: string
  imageUrl: string
  height?: number
  width?: number
  content?: ReactElement
  disabled?: boolean
  loading?: boolean
  placement?: Placement
}

export const ImageViewerTooltip: FunctionComponent<ImageViewerTooltipProps> = ({
  fileName,
  imageUrl,
  height,
  width,
  content,
  disabled,
  loading,
  placement,
}) => {
  if (disabled) return null

  if (loading) {
    return (
      <div>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement={placement ? placement : "top"}
          overlay={
            <Popover
              id="popover-basic"
              data-placement={placement ? placement : "top"}
            >
              <div
                style={{
                  width: typeof width === "undefined" ? "70px" : width + "px",
                  height:
                    typeof height === "undefined" ? "70px" : height + "px",
                  backgroundColor: "grey",
                  borderRadius: "20px",
                  padding: "20px",
                }}
              >
                <Loader />
              </div>
            </Popover>
          }
        >
          <span>{content ? content : fileName + " " + "Preview"}</span>
        </OverlayTrigger>
      </div>
    )
  }

  return (
    <div>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement={placement ? placement : "top"}
        overlay={
          <Popover
            id="popover-basic"
            data-placement={placement ? placement : "top"}
          >
            <div
              style={{
                width: typeof width === "undefined" ? "400px" : width + "px",
                height: typeof height === "undefined" ? "220px" : height + "px",
                backgroundColor: "grey",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <img
                src={imageUrl}
                alt={fileName}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Popover>
        }
      >
        <span>{content ? content : fileName + " " + "Preview"}</span>
      </OverlayTrigger>
    </div>
  )
}

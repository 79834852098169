/* global Autodesk */

const ID_ATTRIBUTE_NAME = "LcIFCProperty:IFCString"
const ID_DISPLAY_CATEGORY = "IFC"
const ID_DISPLAY_NAME = "GLOBALID"

function getAllDbIdsContainingProperty(
  viewer: Autodesk.Viewing.GuiViewer3D,
  property: string
): Promise<number[]> {
  return new Promise((resolve) => {
    //@ts-ignore
    viewer.model.findProperty(property).then((dbIds: any) => {
      resolve(dbIds)
    })
  })
}

export async function getIdMapping(
  viewer: Autodesk.Viewing.GuiViewer3D
): Promise<Record<string, number>> {
  const dbIds = await getAllDbIdsContainingProperty(viewer, ID_DISPLAY_NAME)
  return viewer.model
    .getPropertySetAsync(dbIds, {
      propFilter: [ID_ATTRIBUTE_NAME],
      ignoreHidden: true,
    })
    .then((set) => {
      //@ts-ignore
      const properties = set.map[
        `${ID_DISPLAY_CATEGORY}/${ID_DISPLAY_NAME}`
      ] as any[]
      return properties.reduce((acc, current) => {
        acc[current.displayValue] = current.dbId
        return acc
      }, {} as Record<string, number>)
    })
}

import { Component, ReactNode } from "react"

import Amplify from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"

import Layout from "./containers/Layout/Layout"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import routes from "./routes"
import { initI18n } from "./i18n"
import logo from "./assets/setec-logo-medium.png"
import "./chartjs"

Amplify.configure({
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  region: process.env.REACT_APP_REGION,
})

class App extends Component {
  constructor(props: any) {
    super(props)
    initI18n()
  }

  render(): ReactNode {
    return (
      <Authenticator
        loginMechanisms={["email"]}
        initialState={"signIn"}
        components={{
          Header: () => (
            <div style={{ height: "200px", padding: "45px" }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url('${logo}')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          ),
          SignUp: {
            Header: () => <></>,
            FormFields: () => <></>,
            Footer: () => <></>,
          },
        }}
      >
        {({ signOut, user }) => (
          <>
            <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
              <Layout signOut={signOut} user={user}>
                <Switch>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact !== false}
                      render={() => <route.component user={user} />}
                    />
                  ))}
                </Switch>
              </Layout>
            </BrowserRouter>
          </>
        )}
      </Authenticator>
    )
  }
}

export default App

export const CREATE_FOLDER_ACTION = "createFolder"
export const MOVE_FOLDER_ACTION = "moveFolder"
export const MOVE_FILE_ACTION = "moveFile"
export const UPLOAD_FOLDER_ACTION = "uploadFolder"
export const UPLOAD_FILE_ACTION = "uploadFile"
export const DOWNLOAD_FOLDER_ACTION = "downloadFolder"
export const DOWNLOAD_FILE_ACTION = "downloadFile"
export const RENAME_FOLDER_ACTION = "renameFolder"
export const RENAME_FILE_ACTION = "renameFile"
export const DELETE_FOLDER_ACTION = "deleteFolder"
export const DELETE_FILE_ACTION = "deleteFile"
export const SHARE_FOLDER_ACTION = "shareFolder"
export const SHARE_FILE_ACTION = "shareFile"

import LockIcon from "@mui/icons-material/Lock"
import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import numeral from "numeral"

import { FunctionComponent, useEffect, useState } from "react"
import { Alert, Table } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../components/Breadcrumb/Breadcrumb"
import { FileIcon } from "../../components/FileIcon/FileIcon"
import { GedMenuList } from "../../components/GedOptions/GedMenuList"
import { GedToolbar } from "../../components/GedOptions/GedToolbar"
import { HierarchicalSelectProps } from "../../components/HierarchicalSelect/HierarchicalSelect"
import Loader from "../../components/Loader/Loader"
import { CognitoUserExt } from "../../types/CognitoUserExt"
import {
  File,
  Folder,
  getGedAPI,
  Hierarchy,
  Project,
  Resource,
} from "../../utils/api"
import { downloadFile } from "../../utils/ged"
import { isAdmin } from "../../utils/user"
import GedTags, { Tags } from "./GedTags"

export interface GedProps {
  project: Project
  hierarchy?: Hierarchy
  user: CognitoUserExt
}

const GedView: FunctionComponent<GedProps> = ({ project, hierarchy, user }) => {
  const projectId = project.id
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [file, setFile] = useState<Resource>()
  const [dialogAction, setDialogAction] = useState<string | undefined>(
    undefined
  )
  const [dialogResource, setDialogResource] = useState<Resource>()

  const setDialogActionState = (
    action: string | undefined,
    dialogResourceState: Resource | undefined
  ) => {
    setDialogAction(action)
    setDialogResource(dialogResourceState)
  }

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    const filesPath = location.pathname.split("/").slice(4)
    getGedAPI()
      .getFiles(projectId, filesPath.join("/"), true)
      .then((file) => {
        setFile(file)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }, [projectId, location])

  const admin = isAdmin(user)

  if (isLoading) {
    return (
      <Ged project={project} user={user}>
        <Loader fullscreen />
      </Ged>
    )
  }

  if (error || file?.kind != "Folder" || !file.content) {
    return (
      <Ged project={project} user={user}>
        <div className="ged__content__info">
          <Alert variant="danger">Une erreur s'est produite</Alert>
        </div>
      </Ged>
    )
  }

  return (
    <Ged
      project={project}
      folder={file}
      user={user}
      path={file.path}
      dialogAction={dialogAction}
      setDialogActionState={setDialogActionState}
      dialogResource={dialogResource}
      setFile={setFile}
    >
      <GedFileList
        items={file.content}
        projectId={projectId}
        hierarchy={hierarchy}
        isAdmin={admin}
        setDialogActionState={setDialogActionState}
      />
    </Ged>
  )
}

export interface GedFileListProps {
  items: Array<Resource>
  projectId: string
  hierarchy?: Hierarchy
  isAdmin: boolean
  setDialogActionState?: (
    action: string | undefined,
    dialogResource: Resource | undefined
  ) => void
}

export const GedFileList: FunctionComponent<GedFileListProps> = ({
  items,
  projectId,
  hierarchy,
  isAdmin,
  setDialogActionState,
}) => {
  const hierarchicalSelect = toHierarchicalSelect(hierarchy)

  return (
    <>
      {items.length == 0 ? (
        <div className="ged__content__info">
          <Alert variant="secondary">Ce dossier est vide</Alert>
        </div>
      ) : null}
      <div className="ged__content__file_list">
        <Table>
          <thead>
            <tr>
              <th scope="col" style={{ width: "0px" }} />
              <th scope="col" />
              <th scope="col">Nom</th>
              <th className="d-none d-sm-table-cell" scope="col">
                Dernière modification
              </th>
              <th className="d-none d-sm-table-cell" scope="col">
                Nombre de fichiers
              </th>
              <th className="d-none d-sm-table-cell" scope="col">
                Taille
              </th>
              <th scope="col">Liens maquette</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {items.map((entity, key) => {
              switch (entity.kind) {
                case "Folder":
                  return (
                    <GedFolderRow
                      key={key}
                      folder={entity}
                      projectId={projectId}
                      hierarchicalSelect={hierarchicalSelect}
                      isAdmin={isAdmin}
                      setDialogActionState={setDialogActionState}
                    />
                  )
                case "File":
                  return (
                    <GedFileRow
                      projectId={projectId}
                      key={key}
                      file={entity}
                      hierarchicalSelect={hierarchicalSelect}
                      isAdmin={isAdmin}
                      setDialogActionState={setDialogActionState}
                    />
                  )
              }
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

interface GedFolderRowProps {
  folder: Folder
  projectId: string
  hierarchicalSelect?: HierarchicalSelectProps
  isAdmin: boolean
  dialogAction?: string
  setDialogActionState?: (
    action: string | undefined,
    dialogResource: Resource | undefined
  ) => void
}

export const GedFolderRow: FunctionComponent<GedFolderRowProps> = ({
  folder,
  projectId,
  hierarchicalSelect,
  setDialogActionState,
}) => {
  const [tags, onTagsChanged] = configureTags(folder, projectId)

  const getMoreVertOpts = () => {
    if (setDialogActionState && folder.permissions?.canView) {
      return (
        <td>
          <GedMenuList
            projectId={projectId}
            kind="folder"
            folder={folder}
            setDialogActionState={setDialogActionState}
          />
        </td>
      )
    }
    return (
      <td>
        <div />
      </td>
    )
  }

  return (
    <tr className={folder.permissions?.canView ? "folder" : "folder_disabled"}>
      <td className="icon">
        <Link to={`/project/${projectId}/files${folder.path}`}>
          <FileIcon file={folder} />
        </Link>
      </td>
      <td className="last-modified d-none d-sm-table-cell">
        {folder.isLock ? (
          <Tooltip
            title={"Cette resource est verouillée."}
            arrow
            placement="bottom"
          >
            <LockIcon />
          </Tooltip>
        ) : null}
      </td>
      <td className="name">
        <Link to={`/project/${projectId}/files${folder.path}`}>
          {folder.name}
        </Link>
      </td>
      <td className="last-modified d-none d-sm-table-cell">
        {moment(folder.lastModified).fromNow()}
      </td>
      <td className="size d-none d-sm-table-cell">{folder.childrenCount}</td>
      <td className="size d-none d-sm-table-cell" />
      <td className="tags d-none d-sm-table-cell">
        <GedTags
          tags={tags}
          hierarchicalSelect={hierarchicalSelect}
          onChanged={onTagsChanged}
          canEdit={folder.permissions?.canEdit}
        />
      </td>
      {getMoreVertOpts()}
    </tr>
  )
}

interface GedFileRowProps {
  projectId: string
  file: File
  hierarchicalSelect?: HierarchicalSelectProps
  isAdmin: boolean
  setDialogActionState?: (
    action: string | undefined,
    dialogResource: Resource | undefined
  ) => void
}

const GedFileRow: FunctionComponent<GedFileRowProps> = ({
  projectId,
  file,
  hierarchicalSelect,
  // isAdmin,
  setDialogActionState,
}) => {
  const [tags, onTagsChanged] = configureTags(file, projectId)

  const getMoreVertOpts = () => {
    if (setDialogActionState && file.permissions?.canView) {
      return (
        <td className="download">
          <GedMenuList
            projectId={projectId}
            kind="file"
            file={file}
            setDialogActionState={setDialogActionState}
          />
        </td>
      )
    }
    return (
      <td>
        <div />
      </td>
    )
  }

  const onFileClick = () => {
    if (file.permissions?.canDownload) {
      downloadFile(projectId, file.name, file.path)
    }
  }

  return (
    <tr className={file.permissions?.canView ? "folder" : "folder_disabled"}>
      <td className="icon">
        <span onClick={onFileClick}>
          <FileIcon file={file} />
        </span>
      </td>
      <td className="last-modified d-none d-sm-table-cell">
        {file.isLock ? (
          <Tooltip
            title={"Cette resource est verouillée."}
            arrow
            placement="bottom"
          >
            <LockIcon />
          </Tooltip>
        ) : null}
      </td>
      <td>
        <span onClick={onFileClick}>{file.name}</span>
      </td>
      <td className="last-modified d-none d-sm-table-cell">
        {moment(file.lastModified).fromNow()}
      </td>
      <td className="size d-none d-sm-table-cell" />
      <td className="size d-none d-sm-table-cell">
        {numeral(file.size).format("0.0 ib")}
      </td>
      <td className="tags d-none d-sm-table-cell">
        <GedTags
          onChanged={onTagsChanged}
          tags={tags}
          hierarchicalSelect={hierarchicalSelect}
          canEdit={file.permissions?.canEdit}
        />
      </td>
      {getMoreVertOpts()}
    </tr>
  )
}

const Ged: FunctionComponent<{
  project: Project
  user: CognitoUserExt
  folder?: Folder
  path?: string
  dialogAction?: string
  setDialogActionState?: (action: string, dialogResource: Resource) => void
  dialogResource?: Resource
  setFile?: (resource: Resource) => void
}> = ({
  children,
  path,
  project,
  user,
  folder,
  dialogAction,
  setDialogActionState,
  dialogResource,
  setFile,
}) => {
  const breadcrumb = createBreadcrumbItems(project.id, project.name, path)
  return (
    <div className="ged">
      <Breadcrumb items={breadcrumb} />
      <GedToolbar
        projectId={project.id}
        folder={folder as Folder}
        user={user}
        dialogAction={dialogAction}
        setDialogActionState={
          setDialogActionState as (
            action: string | undefined,
            dialogResource: Resource | undefined
          ) => void
        }
        dialogResource={dialogResource as Resource}
        setFile={setFile}
      />
      <div className="ged__content">{children}</div>
    </div>
  )
}

function toHierarchicalSelect(
  hierarchy?: Hierarchy
): HierarchicalSelectProps | undefined {
  if (!hierarchy) return undefined
  const hierarchical: HierarchicalSelectProps = {
    steps: [
      {
        getOptions: () => [
          { value: ".global", label: "Ouvrage complet" },
          ...hierarchy.sousOuvrages.map((so) => ({
            value: so.id,
            label: so.name,
          })),
        ],
        placeholder: "Sous-ouvrage",
      },
      {
        getOptions: (sousOuvrage) =>
          hierarchy.sousOuvrages
            .find((so) => so.id == sousOuvrage)
            ?.composants.map((c) => ({
              value: c.id,
              label: c.name,
            })) || [],
        placeholder: "Composant",
      },
      {
        getOptions: (sousOuvrage, composant) =>
          hierarchy.sousOuvrages
            .find((so) => so.id == sousOuvrage)
            ?.composants.find((c) => c.id == composant)
            ?.elements.map((e) => ({
              value: e.id,
              label: e.name,
            })) || [],
        placeholder: "Element",
      },
    ],
  }
  return hierarchical
}

function configureTags(
  entity: File | Folder,
  projectId: string
): [Tags, (newTags: Tags) => void] {
  const tags: Tags =
    entity.metadata?.modelRefs?.map((value) => {
      const label =
        value == ".global"
          ? "Ouvrage complet"
          : value.split("/").at(-1) || value
      return {
        value,
        label,
      }
    }) || []
  const onTagsChanged = (newTags: Tags) => {
    getGedAPI().putMetadata(
      projectId,
      entity.path,
      "modelRefs",
      newTags.map((t) => t.value)
    )
  }
  return [tags, onTagsChanged]
}

function createBreadcrumbItems(
  projectId: string,
  projectName: string,
  path?: string
): BreadcrumbItem[] {
  const slices = path?.split("/").filter((s) => s) || []
  let baseFilesUrl = `/project/${projectId}/files`
  return [
    { name: projectName, link: `/project/${projectId}/files` },
    ...slices.map((slice) => {
      baseFilesUrl += `/${slice}`
      return {
        name: slice,
        link: baseFilesUrl,
      }
    }),
  ]
}

export default GedView

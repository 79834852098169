import { FunctionComponent, useEffect, useReducer, useState } from "react"
import { Form } from "react-bootstrap"
import { LabeledValue } from "../../types/utils"

export type Option = LabeledValue<string>
type Options = Array<Option>
type OptionsMap = Record<number, Options>

export interface HierarchicalSelectStep {
  getOptions: (...previousValues: string[]) => Options
  placeholder: string
}

export interface HierarchicalSelectProps {
  steps: HierarchicalSelectStep[]
  onValueChanged?: (value: Option) => void
}

function reducer(
  optionsMap: OptionsMap,
  setAction: { index: number; options?: Options }
) {
  const newSet: OptionsMap = {}
  for (let i = 0; i < setAction.index; i++) {
    newSet[i] = optionsMap[i]
  }
  if (setAction.options) {
    newSet[setAction.index] = setAction.options
  }
  return newSet
}

const HierarchicalSelect: FunctionComponent<HierarchicalSelectProps> = ({
  steps,
  onValueChanged,
}) => {
  const [optionsMap, optionsMapDispatch] = useReducer(reducer, {
    0: steps[0].getOptions(),
  })
  const [value, setValue] = useState<Option>()
  const [selections, setSelections] = useState<string[]>([])

  useEffect(() => {
    if (onValueChanged && value) {
      onValueChanged(value)
    }
  }, [value, onValueChanged])

  const onOptionChanged = (index: number) => (value?: Option) => {
    setValue(value)
    let updatedSelections = selections.slice(0, index)
    if (value) {
      updatedSelections.push(value.value)
    }
    setSelections(updatedSelections)
    if (index + 1 < steps.length) {
      if (value) {
        optionsMapDispatch({
          index: index + 1,
          options: steps[index + 1].getOptions(...updatedSelections),
        })
      } else {
        optionsMapDispatch({
          index: index + 1,
          options: undefined,
        })
      }
    }
  }

  return (
    <Form>
      {steps.map((step, key) => {
        return (
          <HierarchicalSelectUnit
            key={key}
            placeholder={step.placeholder}
            options={optionsMap[key]}
            onOptionChanged={onOptionChanged(key)}
          />
        )
      })}
    </Form>
  )
}

const HierarchicalSelectUnit: FunctionComponent<{
  placeholder: string
  options?: Options
  onOptionChanged: (value?: Option) => void
}> = ({ options, onOptionChanged, placeholder }) => {
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    setValue("") // reset select
  }, [options])

  if (!options || !options.length) {
    return (
      <Form.Group className="mb-3">
        <Form.Select disabled>
          <option>-</option>
        </Form.Select>
      </Form.Group>
    )
  }
  return (
    <Form.Group className="mb-3">
      <Form.Select
        onChange={(e) => {
          const value = e.target.value
          onOptionChanged(options.find((o) => o.value == value))
          setValue(value)
        }}
        value={value}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default HierarchicalSelect

import { Button, TextField } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/system"
import { FunctionComponent, useState } from "react"
import { getGedAPI, Project } from "../../utils/api"
import Error from "../Error/Error"

export interface DocumentationLockerProps {
  project: Project
}

export const DocumentationLocker: FunctionComponent<
  DocumentationLockerProps
> = ({ project }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [lockedFilesInput, setLockedFilesInput] = useState<string>("")

  const strings = {
    getLocked: "Récupérer",
    label:
      "Liste des chemins vers les dossiers et fichiers à verrouiller, séparés d'un retour à la ligne.",
    lock: "Verrouiller",
    unlock: "Déverrouiller",
  }

  const styles = {
    main: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "0.5%",
    },
    flexBox: {
      display: "flex",
    },
    leftMarginButton: {
      marginLeft: "0.5%",
    },
  }

  const lockFiles = () => {
    setIsLoading(true)
    const paths = lockedFilesInput ? lockedFilesInput?.trim().split("\n") : []
    const paginatedPaths = []

    while (paths.length) {
      paginatedPaths.push(paths.splice(0, 50))
    }

    const promises = paginatedPaths.map((subArray) => {
      return getGedAPI().adminFilesLock(project.id, subArray)
    })

    Promise.all(promises)
      .then(() => {
        setLockedFilesInput("")
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
        setIsError(true)
      })
  }

  const unlockFiles = () => {
    setIsLoading(true)
    const paths = lockedFilesInput ? lockedFilesInput?.trim().split("\n") : []

    const paginatedPaths = []

    while (paths.length) {
      paginatedPaths.push(paths.splice(0, 50))
    }

    const promises = paginatedPaths.map((subArray) => {
      return getGedAPI().adminFilesUnlock(project.id, subArray)
    })

    Promise.all(promises)
      .then(() => {
        setLockedFilesInput("")
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
        setIsError(true)
      })
  }

  const onSet = () => {
    setIsLoading(true)
    getGedAPI()
      .getAdminFilesLock(project.id)
      .then((resp) => {
        setLockedFilesInput("\n" + resp.join("\n"))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsError(true)
        setIsLoading(false)
      })
  }

  const onChange = (event: any) => {
    setLockedFilesInput(event.target.value)
  }

  if (isError) return <Error />

  if (isLoading) return <CircularProgress />

  return (
    <Box sx={styles.main}>
      <TextField
        id="outlined-basic"
        label={strings.label}
        variant="outlined"
        multiline
        fullWidth
        maxRows={30}
        onChange={onChange}
        defaultValue={lockedFilesInput}
      />
      <hr />
      <Box sx={styles.flexBox}>
        <Button
          disabled={lockedFilesInput?.trim().length == 0}
          variant="outlined"
          onClick={lockFiles}
        >
          {strings.lock}
        </Button>
        <Button
          disabled={lockedFilesInput?.trim().length == 0}
          sx={styles.leftMarginButton}
          variant="outlined"
          onClick={unlockFiles}
        >
          {strings.unlock}
        </Button>
        <Button sx={styles.leftMarginButton} variant="outlined" onClick={onSet}>
          {strings.getLocked}
        </Button>
      </Box>
    </Box>
  )
}

import { API as AmplifyAPI, Auth } from "aws-amplify"
import { BaseReport, Report } from "../types/report"

const MAIN_API_ID = "main"
const GED_API_ID = "ged"

AmplifyAPI.configure({
  endpoints: [
    {
      name: MAIN_API_ID,
      endpoint: process.env.REACT_APP_URL_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }
      },
    },
    {
      name: GED_API_ID,
      endpoint: process.env.REACT_APP_GED_URL_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }
      },
    },
  ],
})

export interface Project {
  id: string
  name: string
  lat: number
  lng: number
  thumbnail: string
  modelUrn: string
  role?: Roles
  path?: string
}

export interface ModelObject {
  ProjectId: string
  ObjectId: string
  SousOuvrage: string
  Composant: string
  Element: string
  Title?: string
  Description?: string
  ElementObjectIds?: string[]
}

export interface BimToken {
  access_token: string
  expires_in: number
  token_type: string
  refresh_token?: string | undefined
}

export interface PostReportBody {
  title: string
  type: number
}

export interface PutReportBody {
  form: any
  indice: string
  type: number
  isSubmitted: boolean
}

export type PutReportResponse = Report

export type PostReportResponse = Report

export type GetReportResponse = Report

export type Resource = File | Folder

export interface SignedUrlInfo {
  url: string
  expiresIn: number
  expiresAt: string
}

export interface AsyncDownload {
  operationId: string
}

export type DownloadInfo = SignedUrlInfo | AsyncDownload
export interface UploadInfo extends SignedUrlInfo {}

export type MetadataKey = "modelRefs"
type MetadataMap = Partial<Record<MetadataKey, string[]>>

export interface resourcePermissions {
  role?: Roles
  canView?: boolean
  canEdit?: boolean
  canRemove?: boolean
  canDownload?: boolean
  canShare?: boolean
}

export interface File {
  id?: string
  kind: "File"
  name: string
  path: string
  size: number
  lastModified: string
  isLock?: boolean
  metadata?: MetadataMap
  permissions?: resourcePermissions
  title?: string
}

export interface Folder {
  id?: string
  kind: "Folder"
  name: string
  path: string
  content?: Array<Resource>
  isLock?: boolean
  childrenCount?: number
  lastModified: string
  metadata?: MetadataMap
  permissions?: resourcePermissions
  title?: string
}

export enum PermissionType {
  file = "File",
  folder = "Folder",
  report = "Report",
  project = "Project",
}

export enum Roles {
  owner = "owner",
  editor = "editor",
  viewer = "viewer",
  member = "member",
}

export interface Principal {
  id: string
  type: string
}

export interface Permission {
  id: string
  kind: PermissionType
  role: Roles
  principal: Principal
  resource?: Resource | BaseReport | Project
}

export interface SousOuvrage {
  name: string
  id: string
  composants: Composant[]
}

export interface Composant {
  name: string
  id: string
  elements: ElementWithinHierarchy[]
}

export interface ElementWithinHierarchy {
  name: string
  id: string
}

export interface Hierarchy {
  sousOuvrages: SousOuvrage[]
}

export interface ExtendedSousOuvrage extends SousOuvrage {
  composants: ExtendedComposant[]
  excerpt?: ReportExcerptResponse
}

export interface ExtendedComposant extends Composant {
  elements: ExtendedElementWithinHierarchy[]
  excerpt?: ReportExcerptResponse
}

export interface ExtendedElementWithinHierarchy extends ElementWithinHierarchy {
  excerpt?: ReportExcerptResponse
}

export interface ExtendedHierarchy {
  excerpt?: ReportExcerptResponse
  sousOuvrages: ExtendedSousOuvrage[]
}

export interface GetProjectUsersResponseUser {
  email: string
  id: string
}

export interface GetProjectUsersResponse {
  users: GetProjectUsersResponseUser[]
}

export type Reports = Report[]

export interface GetMyReportsResponse {
  reports: Reports
}

export interface GetReportsResponse {
  reports: Reports
}

interface ReportExcerptResponseCommentaire {
  titre?: string
  constatation?: string
  interpretation?: string
  localisation?: string
}

export interface ReportExcerptResponse {
  id: string
  title: string
  type: number
  assignedEmail: string
  startDate: string
  endDate: string
  noteIQOA?: number
  commentaireNoteIQOA?: string
  commentaires?: ReportExcerptResponseCommentaire[]
}

export interface GetModelReportsResponse {
  reports: ReportExcerptResponse[]
}

interface GetElementsResponse {
  elements: Array<GetElementsElementReponse>
}

export interface GetElementsElementReponse {
  name: string
  objectIds: string[]
}

export interface responsePayload {
  status: string
}

export interface FileUploadName {
  name: string
}

export interface UploadLink {
  name: string
  url: string
}

export interface UploadPayload {
  kind: string
  folder_name?: string
  files: FileUploadName[]
}

export interface UploadResponse {
  uploadLinks: UploadLink[]
}

export type ActionType = "export_folder"
export type ResourceType = "folder"

export interface OperationMetadata {
  createdAt: string
  endedAt?: string
  actionType: ActionType
  resourceType: ResourceType
  resourceId: string
  projectId: string
  triggerBy: string
}

export interface OperationError {
  code: number
  message: string
}

interface OperationBase {
  id: string
  done: boolean
  metadata: OperationMetadata
}

export interface PendingOperation extends OperationBase {
  done: false
}

interface TerminatedOperation extends OperationBase {
  done: true
}

export interface SuccessfulOperation extends TerminatedOperation {
  response: any
}

export interface FailedOperation extends TerminatedOperation {
  error: OperationError
}

export type Operation = PendingOperation | SuccessfulOperation | FailedOperation

export type Kind = "task" | "notification"
export type Name = "export_folder"

export interface Event {
  id: string
  createdAt: string
  endedAt?: string
  name: Name
  kind: Kind
  closed: boolean
  ref: string
  userId: string
  payload?: any
}

export interface GetMyEventsResponse {
  events: Event[]
}

export const getMainAPI = () => new MainAPI()

export const getGedAPI = () => new GedAPI()

export const getPresignedApi = () => new PreSignedAPI()

class MainAPI {
  getProjects(): Promise<Project[]> {
    return AmplifyAPI.get(MAIN_API_ID, "/projects", {}).then((body) => {
      if (!body || !Array.isArray(body)) {
        throw Error("Bad format for projects response body")
      }
      return body as Project[]
    })
  }

  getAdminProjects(): Promise<Project[]> {
    return AmplifyAPI.get(MAIN_API_ID, "/admin/projects", {}).then((body) => {
      if (!body || !Array.isArray(body)) {
        throw Error("Bad format for projects response body")
      }
      return body as Project[]
    })
  }

  getProject(projectId: string): Promise<Project> {
    return AmplifyAPI.get(MAIN_API_ID, `/project/${projectId}`, {}).then(
      (body) => {
        if (!body) {
          throw Error("Error: project respondy body is undefined")
        }
        return body as Project
      }
    )
  }

  getEvents(): Promise<GetMyEventsResponse> {
    return AmplifyAPI.get(MAIN_API_ID, `/users/me/events`, {}).then((body) => {
      if (!body) {
        throw Error("Error: events response body is undefined")
      }
      return body as GetMyEventsResponse
    })
  }

  closeEvent(eventId: string): Promise<{}> {
    const url = `/users/me/events/${eventId}/ack`
    return AmplifyAPI.post(MAIN_API_ID, url, {}).then((body) => {
      return body
    })
  }

  getBimToken(projectId: string): Promise<BimToken> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/bim/token`,
      {}
    ).then((body) => {
      if (!body?.access_token) {
        throw Error("Error: Bad format for bim token response body")
      }
      return body as BimToken
    })
  }

  getObject(projectId: string, objectId: string): Promise<ModelObject> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/object/${objectId}`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.ObjectId) {
        throw Error("Error: Bad format for GET object response body")
      }
      return body as ModelObject
    })
  }

  getHierarchy<T extends true | undefined = undefined>(
    projectId: string,
    extended?: T
  ): Promise<T extends true ? ExtendedHierarchy : Hierarchy> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/model/hierarchy${
        extended ? "?extended=true" : ""
      }`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.sousOuvrages) {
        throw Error("Error: Bad format for GET model hierarchy response body")
      }
      return body as Hierarchy | ExtendedHierarchy
    })
  }

  getElements(projectId: string): Promise<GetElementsResponse> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/model/elements`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.elements) {
        throw Error("Error: Bad format for GET elements response body")
      }
      return body as GetElementsResponse
    })
  }

  createReportIndice(
    projectId: string,
    reportId: string,
    body: PostReportBody
  ): Promise<Report> {
    return AmplifyAPI.post(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}`,
      {
        body: body,
      }
    ).then((response): PostReportResponse => {
      return response
    })
  }

  draftReport(projectId: string, reportId: string): Promise<Report> {
    return AmplifyAPI.put(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}/draft`,
      {}
    ).then((body) => {
      return body as Report
    })
  }

  deleteLastReportIndice(projectId: string, reportId: string): Promise<any> {
    return AmplifyAPI.del(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}/indices`,
      {}
    ).then(() => {
      return
    })
  }

  postReport(projectId: string, body: PostReportBody): Promise<Report> {
    return AmplifyAPI.post(MAIN_API_ID, `/project/${projectId}/report`, {
      body: body,
    }).then((response): PostReportResponse => {
      return response
    })
  }

  putReport(
    projectId: string,
    reportId: string,
    body: PutReportBody
  ): Promise<Report> {
    return AmplifyAPI.put(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}`,
      {
        body: body,
      }
    ).then((response): PutReportResponse => {
      return response
    })
  }

  getReport(projectId: string, reportId: string): Promise<GetReportResponse> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.id) {
        throw Error("Error: Bad format for GET report response body")
      }
      return body as GetReportResponse
    })
  }

  getReportIndices(
    projectId: string,
    reportId: string
  ): Promise<GetReportsResponse> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/report/${reportId}/indices`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.reports) {
        throw Error("Error: Bad format for GET report response body")
      }
      return body as GetReportsResponse
    })
  }

  getReports(projectId: string): Promise<GetReportsResponse> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/reports`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.reports) {
        throw Error("Error: Bad format for GET report response body")
      }
      return body as GetReportsResponse
    })
  }

  getProjectUsers(projectId: string): Promise<GetProjectUsersResponse> {
    return AmplifyAPI.get(MAIN_API_ID, `/project/${projectId}/users`, {}).then(
      (body) => {
        // partial data validation
        if (!body?.users) {
          throw Error("Error: Bad format for GET project users response body")
        }
        return body as GetProjectUsersResponse
      }
    )
  }

  // DEPRECATED : REPORTS ARE NOT BOUNDED TO USER ASSIGNED ANYMORE
  // getMyReports(): Promise<GetMyReportsResponse> {
  //   return AmplifyAPI.get(MAIN_API_ID, `/user/my-reports`, {}).then((body) => {
  //     // partial data validation
  //     if (!body?.reports) {
  //       throw Error("Error: Bad format for GET my reports response body")
  //     }
  //     return body as GetMyReportsResponse
  //   })
  // }

  getModelReports(
    projectId: string,
    modelId: string
  ): Promise<GetModelReportsResponse> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `/project/${projectId}/model/${modelId}/reports`,
      {}
    ).then((body) => {
      // partial data validation
      if (!body?.reports) {
        throw Error("Error: Bad format for GET my reports response body")
      }
      return body as GetModelReportsResponse
    })
  }

  getAllReportPermissions(projectId: string): Promise<Permission[]> {
    const url = `/project/${projectId}/reports/permissions`

    return AmplifyAPI.get(MAIN_API_ID, url, {}).then((body) => {
      if (!body) {
        throw Error("Bad format for get All report perms response body")
      }
      return body
    })
  }

  getReportPermissions(
    projectId: string,
    reportId: string
  ): Promise<Permission[]> {
    const url = `/project/${projectId}/reports/${reportId}/permissions`

    return AmplifyAPI.get(MAIN_API_ID, url, {}).then((body) => {
      if (!body) {
        throw Error("Bad format for get report perms response body")
      }
      return body
    })
  }

  createReportPermission(
    projectId: string,
    reportId: string,
    userId: string,
    role: string
  ): Promise<any> {
    const url = `/project/${projectId}/reports/${reportId}/permissions`
    const payload = {
      body: {
        role: role,
        principal: {
          id: userId,
          type: "user",
        },
      },
    }
    return AmplifyAPI.post(MAIN_API_ID, url, payload).then((body) => {
      return body
    })
  }

  updateReportPermission(
    projectId: string,
    reportId: string,
    permissionId: string,
    role: string
  ): Promise<any> {
    const url = `/project/${projectId}/reports/${reportId}/permissions/${permissionId}`
    const payload = {
      body: {
        role: role,
      },
    }

    return AmplifyAPI.put(MAIN_API_ID, url, payload).then((body) => {
      return body
    })
  }

  deleteReportPermission(
    projectId: string,
    reportId: string,
    permissionId: string
  ): Promise<any> {
    const url = `/project/${projectId}/reports/${reportId}/permissions/${permissionId}`

    return AmplifyAPI.del(MAIN_API_ID, url, {}).then((body) => {
      return body
    })
  }

  getAllProjectPermissions(projectId: string): Promise<Permission[]> {
    const url = `/project/${projectId}/permissions`

    return AmplifyAPI.get(MAIN_API_ID, url, {}).then((body) => {
      if (!body) {
        throw Error("Bad format for add permissions response body")
      }
      return body
    })
  }

  addUserInProject(
    projectId: string,
    userId: string,
    role: string
  ): Promise<any> {
    const url = `/project/${projectId}/permissions`
    const payload = {
      body: {
        role: role,
        principal: {
          id: userId,
          type: "user",
        },
      },
    }
    return AmplifyAPI.post(MAIN_API_ID, url, payload).then((body) => {
      return body
    })
  }

  deleteUserInProject(
    projectId: string,
    userId: string,
    role: string
  ): Promise<any> {
    const url = `/project/${projectId}/permissions/del`
    const payload = {
      body: {
        role: role,
        principal: {
          id: userId,
          type: "user",
        },
      },
    }
    return AmplifyAPI.post(MAIN_API_ID, url, payload).then((body) => {
      return body
    })
  }

  updateProjectPermissions(
    projectId: string,
    permissionId: string,
    role: string
  ): Promise<any> {
    const url = `/project/${projectId}/permissions/${permissionId}`
    const payload = {
      body: {
        role: role,
      },
    }

    return AmplifyAPI.put(MAIN_API_ID, url, payload).then((body) => {
      if (!body) {
        throw Error("Bad format for update permissions response body")
      }
      return body
    })
  }
}

class GedAPI {
  createFolder(
    projectId: string,
    parentPath: string,
    name: string
  ): Promise<string> {
    let url = `/project/${projectId}/files`
    const init = {
      body: {
        parent_path: parentPath,
        name: name,
      },
    }
    return AmplifyAPI.post(GED_API_ID, url, init).then((body) => {
      if (!body) {
        throw Error("Bad format for resource response body")
      }
      return body as string
    })
  }

  getAllGedPermissions(projectId: string): Promise<Permission[]> {
    const url = `/v1/projects/${projectId}/files/permissions`

    return AmplifyAPI.get(GED_API_ID, url, {}).then((body) => {
      if (!body) {
        throw Error("Bad format for gedAllGedPermissions body")
      }
      return body
    })
  }

  getGedResourcePermissions(
    projectId: string,
    resourceId: string
  ): Promise<Permission[]> {
    let url = `/v1/projects/${projectId}/files/${resourceId}/permissions`

    return AmplifyAPI.get(GED_API_ID, url, {}).then((body) => {
      if (!body.permissions) {
        throw Error("Bad format for permissions response body")
      }
      return body.permissions
    })
  }

  createGedResourcePermissions(
    projectId: string,
    resourceId: string,
    userId: string,
    role: string
  ): Promise<any> {
    const url = `/v1/projects/${projectId}/files/${resourceId}/permissions`
    const payload = {
      body: {
        role: role,
        principal: {
          id: userId,
          type: "user",
        },
      },
    }
    return AmplifyAPI.post(GED_API_ID, url, payload).then((body) => {
      if (!body) {
        throw Error("Bad format for permissions response body")
      }
      return body
    })
  }

  updateGedResourcePermissions(
    projectId: string,
    resourceId: string,
    permissionId: string,
    role: string
  ): Promise<any> {
    const url = `/v1/projects/${projectId}/files/${resourceId}/permissions/${permissionId}`
    const payload = {
      body: {
        role: role,
      },
    }

    return AmplifyAPI.put(GED_API_ID, url, payload).then((body) => {
      return body
    })
  }

  deleteGedResourcePermissions(
    projectId: string,
    resourceId: string,
    permissionId: string
  ): Promise<any> {
    const url = `/v1/projects/${projectId}/files/${resourceId}/permissions/${permissionId}`

    return AmplifyAPI.del(GED_API_ID, url, {}).then((body) => {
      return body
    })
  }

  adminFilesLock(projectId: string, paths: string[]): Promise<string> {
    const url = `/project/${projectId}/admin/files/lock`
    const payload = {
      body: {
        paths: paths,
      },
    }
    return AmplifyAPI.put(GED_API_ID, url, payload).then((resp) => {
      if (!resp || resp.status != "success") {
        throw Error("Bad response body for adminFilesLock")
      }
      return resp.status as string
    })
  }

  adminFilesUnlock(projectId: string, paths: string[]): Promise<string> {
    const url = `/project/${projectId}/admin/files/unlock`
    const payload = {
      body: {
        paths: paths,
      },
    }
    return AmplifyAPI.put(GED_API_ID, url, payload).then((resp) => {
      if (!resp || resp.status != "success") {
        throw Error("Bad response body for adminFilesUnlock")
      }
      return resp.status as string
    })
  }

  getAdminFilesLock(projectId: string): Promise<string[]> {
    const url = `/project/${projectId}/admin/files/lock`
    return AmplifyAPI.get(GED_API_ID, url, {}).then((resp) => {
      if (!resp) {
        throw Error("Bad response body for getAdminFilesLock")
      }
      return resp
    })
  }

  copyResource(
    projectId: string,
    resourcePath: string,
    destinationFolder: string
  ): Promise<string> {
    let url = `/project/${projectId}/copy/files`

    const payload = {
      body: {
        src: resourcePath,
        dstFolder: destinationFolder,
      },
    }

    return AmplifyAPI.post(GED_API_ID, url, payload).then((resp) => {
      return resp as string
    })
  }

  moveResource(
    projectId: string,
    resource: Resource,
    destination: Folder
  ): Promise<responsePayload> {
    let url = `/project/${projectId}/move/files/${resource.path}`
    const payload = {
      body: {
        destination_path: destination.path,
      },
    }
    return AmplifyAPI.post(GED_API_ID, url, payload).then((body) => {
      if (!body || !body.status) {
        throw Error("Bad format for resource response body")
      }
      return body as responsePayload
    })
  }

  getFiles(
    projectId: string,
    path?: string,
    includeChildrenCount?: boolean
  ): Promise<Resource> {
    let url = `/project/${projectId}/files`
    const init = {
      // queryStringParameters: {
      // include_children_count: includeChildrenCount ? true : false,
      // TODO: FIX THIS: we must use Query params instead of headers.
      // We are using headers due to an issue with query parameters
      // see https://github.com/SETEC-TPI/jumnum-ged/pull/21
      // },
      headers: {
        "x-ged-include-children-count": includeChildrenCount ? true : false,
      },
    }

    if (path) {
      url += "/" + path
    }

    return AmplifyAPI.get(GED_API_ID, url, init).then((body) => {
      if (!body || !body.kind) {
        throw Error("Bad format for resource response body")
      }
      return body as Resource
    })
  }

  deleteResource(
    projectId: string,
    resource?: Resource,
    path?: string
  ): Promise<responsePayload> {
    const url = path
      ? `/project/${projectId}/files/${path}`
      : `/project/${projectId}/files/${resource?.path}`
    return AmplifyAPI.del(GED_API_ID, url, {}).then((body) => {
      return body as responsePayload
    })
  }

  renameResource(
    projectId: string,
    resource: Resource,
    newName: string
  ): Promise<responsePayload> {
    const url = `/project/${projectId}/files/${resource.path}`
    const payload = {
      body: {
        name: newName,
      },
    }
    return AmplifyAPI.put(GED_API_ID, url, payload).then((body) => {
      if (!body || !body.status) throw Error("Bad format for response body")
      return body as responsePayload
    })
  }

  getDownloadUrl(projectId: string, path: string): Promise<DownloadInfo> {
    const url = `/project/${projectId}/url/get/${path}`
    return AmplifyAPI.get(GED_API_ID, url, {}).then((body) => {
      if (!body) {
        throw Error("Bad format for download url response body")
      }
      return body as DownloadInfo
    })
  }

  getUploadUrl(projectId: string, path: string): Promise<UploadInfo> {
    const url = `/project/${projectId}/url/put/${path}`
    return AmplifyAPI.get(GED_API_ID, url, {}).then((body) => {
      if (!body || !body.url) {
        throw Error("Bad format for file url response body")
      }
      return body as UploadInfo
    })
  }

  getUploadUrls(
    projectId: string,
    path: string,
    kind: string,
    files: FileList,
    folderName?: string
  ): Promise<UploadResponse> {
    const url = `/project/${projectId}/upload/files/${path}`
    const reqBody: UploadPayload = {
      kind: kind,
      folder_name: kind == "file" ? undefined : (folderName as string),
      files: [],
    }
    let filesArray: FileUploadName[] = []
    for (let i = 0; i < files.length; i++) {
      filesArray.push({
        name:
          kind == "file"
            ? (files?.item(i)?.name as string)
            : (files?.item(i)?.webkitRelativePath as string).substring(
                (folderName?.length as number) + 1
              ),
      })
    }
    reqBody.files = filesArray

    const payload = {
      body: reqBody,
    }

    return AmplifyAPI.post(GED_API_ID, url, payload).then((body) => {
      if (!body || !body.uploadLinks) {
        throw Error("Bad format for url response body")
      }
      return body as UploadResponse
    })
  }

  putMetadata(
    projectId: string,
    path: string,
    mdKey: MetadataKey,
    mdValue: string[]
  ): Promise<string[]> {
    let url = `/project/${projectId}/metadata${
      path.startsWith("/") ? "" : "/"
    }${path}`
    const payload = {
      body: {
        [mdKey]: mdValue,
      },
    }
    return AmplifyAPI.put(GED_API_ID, url, payload).then((body) => {
      const metadata = body?.metadata?.[mdKey] as string[]
      if (!metadata) {
        throw Error("Bad format for put meta data response body")
      }
      return metadata
    })
  }

  async searchByMetaData(
    projectId: string,
    mdKey: MetadataKey,
    mdValue: string | string[]
  ): Promise<Resource[]> {
    const values = Array.isArray(mdValue) ? mdValue : [mdValue]
    return Promise.all(
      values.map((value) => {
        let url = `/project/${projectId}/search/files`
        const payload = {
          body: {
            metadata: {
              [mdKey]: value,
            },
          },
        }
        return AmplifyAPI.post(GED_API_ID, url, payload).then((body) => {
          if (!body || !Array.isArray(body)) {
            throw Error("Bad format search by meta data response body")
          }
          return body as Resource[]
        })
      })
    ).then((entityLists) => ([] as Resource[]).concat(...entityLists))
  }
}

class PreSignedAPI {
  uploadFile(url: string, fileBlob: Blob) {
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": fileBlob.type,
      },
      body: fileBlob,
    })
  }
}

export function genUniqueId(): string {
  const dateStr = Date.now().toString(36) // convert num to base 36 and stringify

  const randomStr = Math.random().toString(36).substring(2, 8) // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`
}

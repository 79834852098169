import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { FunctionComponent } from "react"
import { Report } from "../../types/report"
import { Project } from "../../utils/api"
import { DELETE_REPORT_INDICE_ACTION, Disabled, isLastIndice } from "./commons"

export interface DeleteIndiceProps {
  project: Project
  report?: Report
  handleDialogOpen: (action: string, needUpdate: boolean) => void
}

export const DeleteIndice: FunctionComponent<DeleteIndiceProps> = ({
  report,
  handleDialogOpen,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const strings = {
    title: "Supprimer l'indice",
    unauthorized: "Vous n'avez pas les permissions nécessaire.",
    error: "Une erreur est survenue.",
    onlyLast: "Vous pouvez supprimer uniquement le dernier indice.",
    first: "Vous ne pouvez pas supprimer l'indice A.",
  }

  const on_click = () => {
    handleDialogOpen(DELETE_REPORT_INDICE_ACTION, false)
  }

  const mustDisabled = (): Disabled => {
    if (!report || !report.indicesReports)
      return { disabled: true, message: strings.error }
    if (report?.role != "owner")
      return { disabled: true, message: strings.unauthorized }
    // if (report.indice == "A")
    // 	return { disabled: true, message: strings.first }
    if (!isLastIndice(report, report.indicesReports))
      return { disabled: true, message: strings.onlyLast }

    return { disabled: false, message: "" }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ m: "0.3em" }}>
        <Tooltip
          disableHoverListener={!mustDisabled().disabled}
          title={mustDisabled().disabled ? mustDisabled().message : false}
          arrow
          placement="bottom"
        >
          <span>
            <Button
              startIcon={<DeleteIcon />}
              ref={anchorRef}
              variant="outlined"
              id="composition-button"
              aria-haspopup="true"
              onClick={on_click}
              disabled={mustDisabled().disabled}
            >
              {strings.title}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}

import { FunctionComponent, useEffect, useState } from "react"
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import Error from "../../components/Error/Error"
import Loader from "../../components/Loader/Loader"
import Sidebar, { SidebarProps } from "../../components/Sidebar/Sidebar"
import { ProjectSubroute, RouteProps } from "../../routes"
import { getMainAPI, Hierarchy, Project, Roles } from "../../utils/api"
import ModelViewer from "../ModelViewer/ModelViewer"

export interface ProjectProps extends RouteProps {
  subroutes: ProjectSubroute[]
}

interface ProjectParams {
  projectId: string
}

const ProjectView: FunctionComponent<ProjectProps> = ({ subroutes, user }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [project, setProject] = useState<Project>()
  const [hierarchy, setHierarchy] = useState<Hierarchy>()
  const { projectId } = useParams<ProjectParams>()
  let match = useRouteMatch()
  let location = useLocation()

  // Load data effect
  useEffect(() => {
    getMainAPI()
      .getProject(projectId)
      .then((project) => {
        setProject(project)
        setIsLoading(false)
      })
      .catch(() => setError(true))

    getMainAPI().getHierarchy(projectId).then(setHierarchy)
  }, [projectId])
  // const admin = isAdmin(user)

  let sideBar: SidebarProps
  if (!isLoading) {
    sideBar = {
      items: [
        ...subroutes
          .filter(
            (route) =>
              route.sidebar &&
              (!route.projectAdmin || project?.role == Roles.owner)
          )
          .map((route) => ({
            name: route.name || "?",
            link: `${match.url}${route.path}`,
            icon: route.icon || "help_outline",
            active:
              route.exact == false
                ? location.pathname.startsWith(`${match.url}${route.path}`)
                : `${match.url}${route.path}` == location.pathname,
          })),
        {
          name: "Retour aux ouvrages",
          link: "/home",
          icon: "home",
        },
      ],
    }
  } else sideBar = { items: [] }

  return (
    <div className="h-100 d-flex">
      <div className="h-100 flex-shrink-0">
        <Sidebar {...sideBar} />
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          borderTop: "solid #E9E8E8 1px",
          overflow: "auto",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            right: 0,
            left: 0,
          }}
        >
          {isLoading ? (
            <Loader fullscreen />
          ) : error || !project ? (
            <Error />
          ) : (
            <>
              <ModelViewer
                project={project}
                urn={project.modelUrn}
                locationPath={location.pathname}
              />
              <Switch>
                {subroutes.map((route, key) => (
                  <Route
                    key={key}
                    path={`${match.url}${route.path}`}
                    exact={route.exact !== false}
                    render={() => {
                      document.title = `${project.name}${
                        route.name ? " - " + route.name : ""
                      } - StructuralTwin`
                      return (
                        <route.component
                          project={project}
                          hierarchy={hierarchy}
                          user={user}
                        />
                      )
                    }}
                  />
                ))}
              </Switch>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectView

import {
  Component,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import Loader from "../../components/Loader/Loader"
import { getMainAPI, Project } from "../../utils/api"
import { computeOuvrageNote } from "../DashboardView/DashboardView"

export interface HomeProps {}

export interface HomeState {
  projects?: Project[]
  isLoading: boolean
  error: boolean
  activeProjectIndex: number
}

interface ProjectsProps {
  projects: Project[]
  setActiveProject: (arg0: number) => void
  clearActiveProject: () => void
}

export default class HomeView extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props)
    this.state = {
      isLoading: true,
      error: false,
      activeProjectIndex: -1,
    }
  }

  componentDidMount() {
    document.title = `Vos ouvrages - StructuralTwin`
    getMainAPI()
      .getProjects()
      .then((projects) => {
        this.preloadMapboxImages(projects)
        this.setState({
          isLoading: false,
          projects,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          error: true,
        })
      })
  }

  preloadMapboxImages(projects: Project[]) {
    //@ts-ignore
    window.preloadedPictures = projects.map((project, key) => {
      const img = new Image()
      img.src = generateMapboxUrl([{ ...project, key }])
      return img
    })
  }

  render(): ReactNode {
    const { error, isLoading, projects, activeProjectIndex } = this.state

    if (isLoading) {
      return <Loader fullscreen />
    }

    if (error || !projects) {
      return <div>Error</div>
    }

    const setActiveProject = (activeProjectIndex: number) =>
      this.setState({ activeProjectIndex })
    const clearActiveProject = () => this.setState({ activeProjectIndex: -1 })

    return (
      <Container className="home py-md-5 py-3">
        <Row>
          <p className="fs-2 fw-light d-sm-block d-none">Vos ouvrages</p>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <Projects
              projects={projects}
              setActiveProject={setActiveProject}
              clearActiveProject={clearActiveProject}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={9}>
            <div className="d-flex align-items-center">
              <ProjectsMap
                key={-1}
                index={-1}
                projects={projects}
                active={activeProjectIndex == -1}
              />
              {projects.map((name, index) => {
                const active = activeProjectIndex == index

                return (
                  <ProjectsMap
                    key={index}
                    index={index}
                    projects={projects}
                    active={active}
                  />
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Projects: FunctionComponent<ProjectsProps> = ({
  projects,
  setActiveProject,
  clearActiveProject,
}) => {
  return (
    <Container fluid className="p-0">
      <Row className="no-gutters">
        {projects.map((proj, key) => (
          <ProjectBox
            project={proj}
            key={key}
            projectKey={key}
            setActiveProject={setActiveProject}
            clearActiveProject={clearActiveProject}
          />
        ))}
      </Row>
    </Container>
  )
}

const ProjectBox: FunctionComponent<{
  project: Project
  projectKey: number
  setActiveProject: (n: number) => void
  clearActiveProject: () => void
}> = ({ project, projectKey, setActiveProject, clearActiveProject }) => {
  const [note, setNote] = useState<number>(0)

  useEffect(() => {
    getMainAPI()
      .getHierarchy(project.id, true)
      .then((extendedHierarchy) => {
        const note = computeOuvrageNote(extendedHierarchy)
        setNote(note as number)
      })
  })

  return (
    <Col key={projectKey} md={6} lg={12}>
      <Card
        className="project-card"
        onMouseEnter={() => setActiveProject(projectKey)}
        onMouseLeave={clearActiveProject}
      >
        <Card.Img
          variant="top"
          style={{
            height: "180px",
            backgroundImage: `url('${project.thumbnail}')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <BadgeIQOA note={note} />
        <Card.Body>
          <Card.Title>{project.name}</Card.Title>
          <Link to={`project/${project.id}/model`}>
            <Button
              variant="link"
              className="ms-auto mt-auto d-block stretched-link hidden"
            />
          </Link>
        </Card.Body>
      </Card>
    </Col>
  )
}

const ProjectsMap: FunctionComponent<{
  projects: Project[]
  index: number
  active: boolean
}> = ({ projects, index, active }) => {
  const mapUrl = generateMapboxUrl(
    index >= 0 ? [{ ...projects[index], key: index }] : projects
  )

  return (
    <Card className={`home__map ${active ? "active" : ""}`}>
      <Card.Img
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: `url('${mapUrl}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    </Card>
  )
}

function generateMapboxUrl(markers: Array<Project & { key?: number }>) {
  const markerFragment = markers
    .map((p, key) => `pin-s-${(p.key ?? key) + 1}+f43434(${p.lng},${p.lat})`)
    .join(",")

  const coordinates = [-5.9523, 40.1468, 11.1327, 51.9395].join(",")
  const width = 800
  const height = 800

  return (
    `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/${markerFragment}/` +
    `[${coordinates}]/${width}x${height}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
  )
}

import moment from "moment"
import { FunctionComponent } from "react"
import { Button, Card, Table } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import BadgeIQOA from "../../components/BadgeIQOA/BadgeIQOA"
import GedResource from "../../components/GedResource/GedResource"
import Icon from "../../components/Icon/Icon"
import Loader from "../../components/Loader/Loader"
import {
  Folder,
  GetModelReportsResponse,
  ModelObject,
  Project,
  ReportExcerptResponse,
  Resource
} from "../../utils/api"
import { REPORT_TYPES } from "../../utils/report"
import ModelDescription from "../ModelViewer/ModelDescription"

const REF_INSTRUMENTATION: Folder = {
  kind: "Folder",
  name: "3-02- Dossier de surveillance",
  path: "/3- Vie de l'ouvrage/3-02- Dossier de surveillance",
  lastModified: "0",
}

interface ModelViewerInfoProps {
  data?: ModelObject
  loading: boolean
  close: () => void
  isGlobalObject: boolean
  project: Project
  resources: Array<Resource>
  reports: GetModelReportsResponse
}

const ModelViewerInfo: FunctionComponent<ModelViewerInfoProps> = ({
  data,
  loading,
  isGlobalObject,
  close,
  project,
  resources,
  reports,
}) => {
  const content = loading ? (
    <Loader fullscreen />
  ) : !data && isGlobalObject ? (
    <>
      <Card.Title>{project.name}</Card.Title>
      <div className="sections">
        <div className="section">Aucune description pour cet ouvrage</div>
      </div>
    </>
  ) : !data ? (
    <>
      <Card.Title>Objet inconnu</Card.Title>
      <div className="sections">
        <div className="section">
          L'objet sélectionné n'est pas référencé. Veuillez en sélectionner un
          autre.
        </div>
      </div>
    </>
  ) : (
    <>
      <Card.Title>{data.Title}</Card.Title>
      {isGlobalObject ? null : (
        <Card.Subtitle>
          {data.SousOuvrage} / {data.Composant} / {data.Element}
        </Card.Subtitle>
      )}
      <div className="sections">
        <div className="section">
          <Card.Text>
            <ModelDescription description={data.Description} />
          </Card.Text>
        </div>
        {resources.length ? (
          <div className="section">
            <div className="section-title">Documentation</div>
            <div className="ged-resources">
              {resources.map((r, key) => {
                return (
                  <GedResource tooltipPreview={true} key={key} resource={r} projectId={project.id} />
                )
              })}
            </div>
          </div>
        ) : null}
        <div className="section">
          <div className="section-title">Inspections</div>
          <ModelViewerInfoInspections
            projectId={project.id}
            isLoading={loading}
            reports={{
              reports: reports.reports.filter((r) => r.type <= 4),
            }}
          />
        </div>
        <div className="section">
          <div className="section-title">Interventions</div>
          <ModelViewerInfoInspections
            projectId={project.id}
            isLoading={loading}
            reports={{
              reports: reports.reports.filter((r) => r.type > 4),
            }}
          />
        </div>
        <div className="section">
          <div className="section-title">Instrumentation</div>
          <div className="ged-resources">
            {[REF_INSTRUMENTATION].map((r, key) => {
              return (
                <GedResource key={key} resource={r} projectId={project.id} />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )

  return (
    <Card className={"model-viewer-info"}>
      <Card.Body>
        <Button className="close-button" variant="light" onClick={close}>
          <Icon icon="keyboard_double_arrow_left" />
        </Button>
        {content}
      </Card.Body>
    </Card>
  )
}

const ModelViewerInfoInspections: FunctionComponent<{
  projectId: string
  reports: GetModelReportsResponse
  isLoading: boolean
}> = ({ projectId, reports, isLoading }) => {
  if (isLoading) return <Loader />

  if (!reports)
    return (
      <span>
        Une erreur s'est produite lors de la récupération des rapports
      </span>
    )

  return (
    <div>
      <Table className="report-excerpts">
        <tbody>
          {reports.reports.map((report, key) => (
            <ReportExcerpt key={key} report={report} projectId={projectId} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const ReportExcerpt: FunctionComponent<{
  report: ReportExcerptResponse
  projectId: string
}> = ({ report, projectId }) => {
  return (
    <tr className="report-excerpt">
      <td className="title">
        <LinkContainer to={`/project/${projectId}/report/${report.id}`}>
          {<a>{REPORT_TYPES[report.type].shortName}</a>}
        </LinkContainer>
      </td>
      <td className="date">{moment(report.endDate).format("ll")}</td>
      {/* <td className="date">{moment(report.dateInspection).format("ll")}</td> */}
      <td className="note" title="Note IQOA">
        {report.noteIQOA ? <BadgeIQOA note={report.noteIQOA} /> : null}
      </td>
    </tr>
  )
}

export default ModelViewerInfo
